import axios from 'axios';
import { getAuthenticationUrl } from '../constants/webapi/authentication';
import * as urls from '../constants/webapi/authentication';
import { authenticationActions } from '../reducers/authentication';
import { toast } from 'react-toastify';
import { toastConf } from '../constants/utils/toastConfig';

export function CreateUser(idToken, data = {}) {
    return (dispatch) => {
        dispatch(authenticationActions.createUserRequest());
        return axios.post(urls.getUsersUrl(), { ...data, value: idToken })
            .then((response) => {
                dispatch(authenticationActions.createUserSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    window.location = getAuthenticationUrl();
                    return;
                }
                dispatch(authenticationActions.createUserFailure(error.response));
                throw error.statusText;
            });
    };
}

export function ActivateAccount(activation_token, idToken) {
    return (dispatch) => {
        dispatch(authenticationActions.activateAccountRequest());
        return axios.post(urls.getUserActivationUrl(), { ActivationToken: activation_token, TokenId: idToken })
            .then((response) => {
                dispatch(authenticationActions.activateAccountSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    window.location = getAuthenticationUrl(activation_token, true);
                    return;
                }
                dispatch(authenticationActions.activateAccountFailure());
                throw error.response.data;
            });
    };
}

const success = (response) => response;
const error = async (err) => {
    if (err.response.status === 403 ||
        err.response.data?.message.includes("TechnicalAccount")
    ) {
        let url = new URL(window.location);
        url.searchParams.set('q', 'technical');
        window.history.pushState({}, '', url);
        toast.error("Veuillez configurer un compte technique.", toastConf);
    }
    if (err.response.status === 401)
        window.location = getAuthenticationUrl();
    return Promise.reject(err);
};

axios.interceptors.response.use(success, error);
