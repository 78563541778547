import React from 'react';

// Components
// ==========================================
// Components: Views
import { Toolbar } from './toolbar';
import { Menu } from './menu';
import { View } from './view';

export const Files = ({ settings }) => {
	return (
		<section className="block flex-column flex-grow-1 scroll-auto gap-3" id="Files">
			<h3 className="sr-only visually-hidden">App: Files</h3>
			<Toolbar />
			<div className="view flex-fill flex-lg-row gap-3">
				<Menu />
				<View />
			</div>
		</section>
	);
};

