import React from 'react';
import { format } from 'date-fns';
import * as R from 'ramda';

import { STATUS_MAP } from '../../../../../constants/utils/global';


export const ModalHistory = ({ invoiceState, item, setItem }) => {

    return (
        <div className="modal fade" id="statusHistory" tabIndex={-1} aria-labelledby="statusHistoryLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content rounded-2 p-4">
                    <div className="modal-header pb-0">
                        <h1 className="modal-title fs-4 text-info fw-demi ls-n1" id="statusHistoryLabel">Historique des statuts</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                    </div>
                    <div className="modal-body">
                        <p className="o-77 mb-4 mt-n3 text-body">Veuillez trouver ci-dessous l'historique du statut de la facture :</p>
                        <div className="row gx-3 align-items-baseline">
                            <div className="col-2">
                                <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0 text-body">Code</p>
                                {!R.isNil(invoiceState.history) && !R.isEmpty(invoiceState.history) ?
                                    invoiceState.history.map(row => (
                                        <p key={"first-" + row.date} className="my-0 fw-bold text-body">{row.statusCode}</p>
                                    ))
                                    :
                                    <p className="my-0 o-22 ls-n1 text-body">---</p>
                                }
                            </div>
                            <div className="col">
                                <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0 text-body">Statut</p>
                                {!R.isNil(invoiceState.history) && !R.isEmpty(invoiceState.history) ?
                                    invoiceState.history.map(row => (
                                        <p key={"second-" + row.date} className="my-0 text-body">{STATUS_MAP[row.statusCode]}</p>
                                    ))
                                    :
                                    <p className="my-0 o-22 ls-n1 text-body">Inconnu</p>
                                }
                            </div>
                            <div className="col-auto text-end">
                                <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0 text-body">Date</p>
                                {!R.isNil(invoiceState.history) && !R.isEmpty(invoiceState.history) ?
                                    invoiceState.history.map(row => (
                                        <p key={"third-" + row.date} className="my-0 text-body">{format(new Date(row.date), 'dd-MM-yyyy, HH:mm:ss')}</p>
                                    ))
                                    :
                                    <p className="my-0 o-22 ls-n1 text-body">DD-MM-YYYY</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {/* <button type="button" className="btn text-muted o-66-hover fs-md fw-demi" data-bs-dismiss="modal">Annuler</button> */}
                        <button data-bs-dismiss="modal" type="button" className="btn text-info text-white-hover bg-info bg-opacity-10 bg-opacity-100-hover fs-md fw-demi px-4">
                            <span> Fermer </span>
                            {/* {(invoiceState.isLoading.includes(Loadings.UPDATING_INVOICE)) && <Spinner />} */}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

