

import React from 'react';
import { Header } from './header';
import { Body } from './body';
import { ModalHistory } from './modalHistory';
import { ModalStatus } from './modalStatus';


export const DetailItem = ({ invoiceState, companyState, selectedInvoice, setSelectedInvoice }) => {

    return (
        <React.Fragment>
            <div className="col col-lg-6 col-xl flex-column gap-2 h-calc h-lg-inherit">
                <Header invoiceState={invoiceState} item={selectedInvoice} setItem={setSelectedInvoice} />
                <Body invoiceState={invoiceState} item={selectedInvoice} setItem={setSelectedInvoice} />
            </div>
            <ModalHistory invoiceState={invoiceState} item={selectedInvoice} setItem={setSelectedInvoice} />
            <ModalStatus companyState={companyState} invoiceState={invoiceState} item={selectedInvoice} setItem={setSelectedInvoice} />
        </React.Fragment>
    );
};