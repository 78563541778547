import React, { useState } from 'react';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';


// Components
// ==========================================
// Components: Common
import * as Loadings from '../../../../../constants/loadings/connection';
import { DeleteConnection } from '../../../../../actions/connection-api';
import { Spinner } from '../../../../../_common/spinner';
import { useModalToggle } from '../../../../../hooks/useModalToggle';

export const MainConnecte = ({ connectionState, authenticationState }) => {
    const dispatch = useDispatch();
    const { companyId } = useParams();
    const [emailToggle, setEmailToggle] = useState(false);
    const { openRef } = useModalToggle('technicalForm');
    return (
        <React.Fragment>
            <div className="flex-column w-100 mb-4">
                <div className="container-fluid g-0 g-lg-3 container-copy container-xxl-screen">
                    <h5 className="ff-base fw-normal mt-xxl-3 mt-hd-4">Portal public de facturation</h5>
                    {/* <div className="container-lg-wide">
                        <p className="fs-aux o-44">Ce sont les applications que vous avez connectées à votre compte. Vous pouvez voir les informations auxquelles ces applications ont accès et révoquer l'accès.</p>
                    </div> */}
                    <section className="flex-column">
                        <h1 className="sr-only">Active connections</h1>
                        <ul className="list-group list-group-flush">
                            <li className="card list-group-item flex-column rounded-2 p-2 gap-2">
                                <div className="flex-row justify-content-between align-items-center gap-2">
                                    <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                                        <div className="avatar mb-0 flex-shrink-0 bg-body-tertiary rounded-2" style={{ backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundImage: "url('https://az859819.vo.msecnd.net/agents/logos/medium/ppf.png')" }}></div>
                                        <div className="block flex-fill align-items-center gap-xl-1 text-truncate">
                                            <div className="fs-root lh-title text-truncate">PPF</div>
                                            <div className="o-44 lh-title fs-md text-truncate">Portail Public de facturation</div>
                                        </div>
                                    </div>
                                    {/* TODO: Connections is not an array it is now a connection type */}
                                    {!R.isNil(connectionState.connections) && !R.isEmpty(connectionState.connections) && !R.isNil(connectionState.connections) ?
                                        // <div className="flex-row gap-2 ms-auto align-items-start">
                                        //     <button disabled={connectionState.isLoading.includes(Loadings.CREATING_CONNECTION)} onClick={() => { dispatch(DeleteConnection(authenticationState.user.id, connectionState.connections.find(c => c.connectionType === 'PPF')?.id)); }} type="button" className="btn fs-md fw-demi py-2 bg-danger bg-opacity-10 bg-opacity-100-hover text-danger text-white-hover">
                                        //         <span>  Supprimer </span>
                                        //         {(connectionState.isLoading.includes(Loadings.CREATING_CONNECTION)) && <Spinner />}

                                        //     </button>
                                        // </div>
                                        <div className="flex-row gap-2 ms-auto align-items-start">
                                            <button disabled={connectionState.isLoading.includes(Loadings.CREATING_CONNECTION)} type="button" className="btn fs-md fw-demi py-2 bg-info bg-opacity-10 bg-opacity-100-hover text-info text-white-hover" data-bs-toggle="modal" data-bs-target="#ajouterPPF" aria-controls="ajouterPPF">
                                                <span>  Modifier </span>
                                                {(connectionState.isLoading.includes(Loadings.CREATING_CONNECTION)) && <Spinner />}

                                            </button>
                                        </div>
                                        :
                                        <div className="flex-row gap-2 ms-auto align-items-start">
                                            <button type="button" ref={openRef} disabled={connectionState.isLoading.includes(Loadings.CREATING_CONNECTION)} className="btn fs-md fw-demi py-2 bg-success bg-opacity-10 bg-opacity-100-hover text-success text-white-hover" data-bs-toggle="modal" data-bs-target="#ajouterPPF" aria-controls="ajouterPPF">
                                                <span> Ajouter </span>
                                                {(connectionState.isLoading.includes(Loadings.CREATING_CONNECTION)) && <Spinner />}
                                            </button>
                                        </div>
                                    }
                                </div>
                                {/* {connectionState.isLoading.includes(Loadings.CREATING_CONNECTION) ?
                                    <div className="alert alert-warning border-none fs-aux p-2 m-0">
                                        En cours de connexion...
                                    </div>
                                    :
                                    connectionState.isLoading.includes(Loadings.DELETING_CONNECTION) ?
                                        <div className="alert alert-warning border-none fs-aux p-2 m-0">
                                            Suppression de la connexion...
                                        </div>
                                        :
                                        R.isNil(connectionState.connections) ?
                                            // !R.isNil(connectionState.connections) && !R.isEmpty(connectionState.connections) && !R.isNil(connectionState.connections.find(c => c.connectionType === 'PPF')) ?
                                            <div className="alert alert-success border-none fs-aux p-2 m-0">
                                                Connecté au PPF <Link to={`/c/${companyId}/inbox`} className="btn fs-inherit p-0 align-baseline fw-bold">Accéder aux factures fournisseur</Link>
                                            </div>
                                            :
                                            connectionState.isError ?
                                                <div className="alert alert-danger border-none fs-aux p-2 m-0">
                                                    Une erreur est survenue lors de la connexion au PPF <button className="btn fs-inherit p-0 align-baseline fw-bold">Réessayer</button>
                                                </div>
                                                :
                                                null

                                } */}
                                {/* {!R.isNil(connectionState.connections) && !R.isEmpty(connectionState.connections) && !R.isNil(connectionState.connections.find(c => c.connectionType === 'PPF')) &&
									<div className="alert alert-success border-none fs-aux p-2 m-0">
										Connected to PPF account! <Link to="/inbox" className="btn fs-inherit p-0 align-baseline fw-bold">Go to Inbox</Link>
									</div>
								}
								{state.isError &&
									<div className="alert alert-danger border-none fs-aux p-2 m-0">
										Error while trying to connect to PPF account! <button className="btn fs-inherit p-0 align-baseline fw-bold">Try again</button>
									</div>
								} */}
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
            <div className="flex-column w-100 mb-4">
                <div className="container-fluid g-0 g-lg-3 container-copy container-xxl-screen">
                    <h5 className="ff-base fw-normal mt-xxl-3 mt-hd-4">Réception email</h5>
                    {/* <div className="container-lg-wide">
                        <p className="fs-aux o-44">Ce sont les applications que vous avez connectées à votre compte. Vous pouvez voir les informations auxquelles ces applications ont accès et révoquer l'accès.</p>
                    </div> */}
                    <section className="flex-column">
                        <h1 className="sr-only">Active connections</h1>
                        <ul className="list-group list-group-flush">
                            {/* <li className="card list-group-item flex-column rounded-2 p-2 gap-2">
                                <div className="flex-row justify-content-between align-items-center gap-2">
                                    <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                                        <div className="avatar mb-0 flex-shrink-0 bg-body-tertiary rounded-2" style={{ backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundImage: "url('https://az859819.vo.msecnd.net/agents/logos/medium/sca.png')" }}></div>
                                        <div className="block flex-fill align-items-center gap-xl-1 text-truncate">
                                            <div className="fs-root lh-title text-truncate">CloudAgents</div>
                                            <div className="o-44 lh-title fs-md text-truncate">Collecte automatisée</div>
                                        </div>
                                    </div>
                                    <div className="flex-row gap-2 ms-auto align-items-start">
                                        <button type="button" className="btn fs-md fw-demi py-2 bg-info bg-opacity-10 bg-opacity-100-hover text-info text-white-hover">Préférences </button>
                                        <button type="button" className="btn fs-md fw-demi py-2 bg-success bg-opacity-10 bg-opacity-100-hover text-success text-white-hover">Ajouter</button>
                                        <button type="button" className="btn fs-md fw-demi py-2 bg-danger bg-opacity-10 bg-opacity-100-hover text-danger text-white-hover">Revoke</button>
                                    </div>
                                </div>
                            </li> */}
                            {/* {!emailToggle &&
                                <div className="align-middle fw-bold ff-aux fs-sm mb-2 mt-3 flex-row gap-2">
                                    <span className="o-33">Plus</span>
                                    <hr className="flex-fill my-auto border-body" />
                                </div>
                            } */}
                            <li className="card list-group-item flex-column rounded-2 p-2 gap-2">
                                <div className="flex-row justify-content-between align-items-center gap-2">
                                    <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                                        <div className="avatar mb-0 flex-shrink-0 bg-body-tertiary rounded-2 text-center">
                                            <i className="icns material-symbols-rounded align-middle ms-auto icn-expand m-auto fs-root text-body">email</i>
                                        </div>
                                        <div className="block flex-fill align-items-center gap-xl-1 text-truncate">
                                            <div className="fs-root lh-title text-truncate">Email</div>
                                            <div className="o-44 lh-title fs-md text-truncate">Reception par email</div>
                                        </div>
                                    </div>
                                    <div className="flex-row gap-2 ms-auto align-items-start">
                                        <button type="button" className={"btn fs-md fw-demi py-2 bg-opacity-10 bg-opacity-100-hover text-white-hover" + (emailToggle ? " bg-danger text-danger" : " bg-success text-success")} onClick={() => { setEmailToggle(!emailToggle); }}>
                                            {emailToggle ? "Désactiver" : "Activer"}
                                        </button>
                                    </div>
                                </div>
                                {emailToggle &&
                                    <div className="alert alert-gray border-none fs-aux p-2 m-0 flex-row justify-content-between">
                                        <span>ujefnf@email.com</span> <button className="btn fs-inherit p-0 align-baseline fw-bold ms-auto bg-opacity-75-hover bg-body px-2">copy</button>
                                    </div>
                                }
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
            <div className="flex-column w-100">
                <div className="container-fluid g-0 g-lg-3 container-copy container-xxl-screen">
                    <h5 className="ff-base fw-normal mt-hd-4">Plateforme de dématérialisation partenaire <span className="fs-sm fw-bold o-44 smallcaps">PDP</span></h5>
                    {/* <div className="container-lg-wide">
                        <p className="fs-aux o-44">These are the apps which you connected to your account. You can see the information these apps have access to and revoke access.</p>
                    </div> */}
                    <section className="flex-column">
                        <h1 className="sr-only">Active connections</h1>
                        <ul className="list-group list-group-flush">
                            <li className="card list-group-item flex-column rounded-2 p-2 gap-2">
                                <div className="flex-row justify-content-between align-items-center gap-2">
                                    <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                                        <div className="avatar mb-0 flex-shrink-0 bg-body-tertiary rounded-2" style={{ backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundImage: "url('https://az859819.vo.msecnd.net/agents/logos/medium/sybycegedim.png')" }}></div>
                                        <div className="block flex-fill align-items-center gap-xl-1 text-truncate">
                                            <div className="fs-root lh-title text-truncate">Sy</div>
                                            <div className="o-44 lh-title fs-md text-truncate">cegedim</div>
                                        </div>
                                    </div>
                                    <div className="flex-row gap-2 ms-auto align-items-start">
                                        <button type="button" className="btn fs-md fw-demi py-2 bg-success bg-opacity-10 bg-opacity-100-hover text-success text-white-hover">Ajouter</button>
                                    </div>
                                </div>
                                {/* <div className="alert alert-warning border-none fs-aux p-2 m-0">
                                    This account is having problems. Please <button className="btn fs-inherit p-0 align-baseline fw-bold">reauthorize</button>
                                </div> */}
                            </li>

                            <div className="align-middle fw-bold ff-aux fs-sm mb-2 mt-3 flex-row gap-2">
                                <span className="o-33">Plus</span>
                                <hr className="flex-fill my-auto border-body" />
                            </div>
                            <li className="card list-group-item flex-column rounded-2 p-2 gap-2">
                                <div className="flex-row justify-content-between align-items-center gap-2">
                                    <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                                        <div className="avatar mb-0 flex-shrink-0 bg-body-tertiary rounded-2" style={{ backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundImage: "url('https://az859819.vo.msecnd.net/agents/logos/medium/orchestradearteva.png')" }}></div>
                                        <div className="block flex-fill align-items-center gap-xl-1 text-truncate">
                                            <div className="fs-root lh-title text-truncate">Orchestrade</div>
                                            <div className="o-44 lh-title fs-md text-truncate">Arteva</div>
                                        </div>
                                    </div>
                                    <div className="flex-row gap-2 ms-auto align-items-start">
                                        <button type="button" className="btn fs-md fw-demi py-2 bg-success bg-opacity-10 bg-opacity-100-hover text-success text-white-hover">Ajouter</button>
                                    </div>
                                </div>
                                {/* <div className="alert alert-warning border-none fs-aux p-2 m-0">
                                    This account is having problems. Please <button className="btn fs-inherit p-0 align-baseline fw-bold">reauthorize</button>
                                </div> */}
                            </li>
                            <li className="card list-group-item flex-column rounded-2 p-2 gap-2">
                                <div className="flex-row justify-content-between align-items-center gap-2">
                                    <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                                        <div className="avatar mb-0 flex-shrink-0 bg-body-tertiary rounded-2" style={{ backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundImage: "url('https://az859819.vo.msecnd.net/agents/logos/medium/cegid.png')" }}></div>
                                        <div className="block flex-fill align-items-center gap-xl-1 text-truncate">
                                            <div className="fs-root lh-title text-truncate">PDP</div>
                                            <div className="o-44 lh-title fs-md text-truncate">Cegid</div>
                                        </div>
                                    </div>
                                    <div className="flex-row gap-2 ms-auto align-items-start">
                                        <button type="button" className="btn fs-md fw-demi py-2 bg-success bg-opacity-10 bg-opacity-100-hover text-success text-white-hover">Ajouter</button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </React.Fragment>
    );
};