import React, { useEffect, useRef } from 'react';
import jwtDecode from 'jwt-decode';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import * as R from 'ramda';

import * as Loadings from '../../constants/loadings/authentication';
import { Spinner } from '../../_common/spinner';
import { ReactComponent as Avatar } from '../../assets/images/placeholders/app/avatar.svg';
import { GetCompany, ResetCompany, UpdateFormMode } from '../../actions/company-api';
import { useDispatch } from 'react-redux';
import { ResetMember } from '../../actions/member-api';
import { ResetInvoices } from '../../actions/invoice-api';
import { ResetConnection } from '../../actions/connection-api';
import { INITAL_COMPANY_DATA } from '../../constants/utils/global';
import { useModalToggle } from '../../hooks/useModalToggle';

export const ModalAuth = ({ authenticationState, companyState }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { openRef } = useModalToggle('company');
    let id_token = sessionStorage.getItem("id_token");

    let logoutUrl = "https://accounts.securibox.eu/id/connect/endsession";
    let decodedToken = {
        given_name: "",
        family_name: "",
        email: ""
    };

    if (!R.isNil(id_token)) {
        decodedToken = jwtDecode(id_token);
        logoutUrl = "https://accounts.securibox.eu/id/connect/endsession?id_token_hint=" + id_token;
    }

    const getNewCompanyId = (id) => pathname.replace(pathname.split('/')[2], id);
    const areEqual = (a, b) => a.siren === b.siren;


    const resetApp = (companyId) => {
        dispatch(GetCompany(companyId));
        // dispatch(ResetCompany());
        // dispatch(ResetConnection());
        // dispatch(ResetInvoices());
        // dispatch(ResetMember());
    };



    return (
        <div className="modal fade" id="sbxAuth" tabIndex={-1} aria-labelledby="sbxAuthLabel" aria-hidden="true" data-bs-theme="light">
            <div className="modal-dialog modal-dialog-scrollable modal-dialog modal-dialog-scrollable-centered modal-sm">
                <div className="modal-content rounded-3 bg-body-tertiary p-2 text-body">
                    {/* <div className="modal-header pb-0">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeRef}></button>
                    </div> */}
                    <div className="block bg-body rounded-2">
                        <div className="modal-body">
                            <div className="flex-row gap-3 align-items-center">
                                <Avatar height={48} width={48} alt="avatar" className="img-fluid obj-fit align-middle avatar text-body" />
                                {/* <img src={Avatar} width="48" /> */}
                                <div className="flex-column my-auto text-truncate">
                                    <h6 className="fs-5 lh-title my-0 ff-aux my-auto">{decodedToken.given_name} {decodedToken.family_name}</h6>
                                    <p className="fs-aux my-0 o-66 text-truncate" title={decodedToken.email}>{decodedToken.email}</p>
                                </div>
                            </div>
                            {/* <div className="btn-group w-100 mt-3 mb-0" role="group" aria-label="Button group">
                                <button type="button" className="btn bg-common border-none bg-body-hover fs-md fw-demi">My account</button>
                                <button type="button" className="btn bg-common border-none bg-body-hover fs-md fw-demi no-event o-44">Facturation</button>
                            </div> */}
                        </div>
                        {/* <hr className="my-0 o-100 border-body-tertiary" /> */}
                        <div className="modal-body rounded-bottom-inherit p-1">
                            <div className="list-group overflow-y-auto" style={{ maxHeight: "16em" }}>
                                {!R.isNil(companyState.companies) && !R.isEmpty(companyState.companies) ?
                                    R.sortBy(R.prop('name'), R.uniqWith(areEqual, companyState.companies))
                                        .map(company => {
                                            const companyNames = company.name.split(' ');
                                            const firstInitial = company.name[0].charAt(0);
                                            let lastInitial = companyNames.length > 1 ? companyNames.at(-1)?.charAt(0) : '';
                                            return (
                                                <Link onClick={() => resetApp(company.id)} to={getNewCompanyId(company.id)} className={"list-group-item list-group-item-action bg-body-tertiary border-body-tertiary border-start-0 border-end-0" + (pathname.split('/')[2] === company.id ? " bg-opacity-10" : " bg-opacity-25-hover")} key={company.id}>
                                                    <div className="flex-row w-100 justify-content-between gap-3 align-items-center">
                                                        <div className="avatar-2 text-center fw-bold bg-gray bg-opacity-10 fs-sm rounded-2 text-uppercase">{firstInitial}{lastInitial}</div>
                                                        <div className="flex-column flex-fill text-truncate">
                                                            <h5 className="fs-6 ff-base mb-0">{company.name}</h5>
                                                            <p className="fs-md mb-0 o-50">{company.siren}</p>
                                                        </div>
                                                        <div className="link-gray">
                                                            <span className="icns material-symbols-rounded align-middle">chevron_right</span>
                                                        </div>
                                                    </div>
                                                    <small className="db fs-xs text-truncate o-66 sr-only visually-hidden mt-1">{company.id}</small>
                                                </Link>
                                            );
                                        })
                                    : ''
                                }
                            </div>
                            <button type="button" onClick={() => dispatch(UpdateFormMode(true))} className="btn o-66-hover flex-fill w-100 text-start" data-bs-target="#accountSociete" ref={openRef} data-bs-toggle="modal">
                                <span className="icns material-symbols-rounded align-middle">add</span>
                                <span className="align-middle mx-2 fs-md fw-medium o-66">Ajouter nouveau</span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn w-100 text-body-hover text-white bg-dark bg-opacity-100 bg-opacity-10-hover fs-md fw-demi px-4" onClick={(e) => {
                            e.preventDefault();
                            window.location = logoutUrl;
                            sessionStorage.removeItem("id_token");
                            sessionStorage.removeItem("access_token");
                        }}>
                            <span>  Se déconnecter </span>
                            {(authenticationState.isLoading.includes(Loadings.LOGGING_OUT)) && <Spinner />}
                        </button>
                        <button type="button" className="btn text-muted o-66-hover fs-md fw-demi w-100 p-0" data-bs-dismiss="modal">Fermer</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

