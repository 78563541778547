// import * as R from 'ramda';
import { EndPoints } from '../api-config';
export const SERVER_ADDR_V1 = EndPoints.backendHost;
export const SERVER_ADDR_V2 = "https://od-one-flow-stg.securibox.eu";
// export const SERVER_ADDR_V2 = "http://localhost:7099";


// export const getInvoicesUrl = (companyId) => `${SERVER_ADDR_V1}/api/company/${companyId}/invoices`;
// export const getInvoiceByIdUrl = (companyId, invoiceId) => `${SERVER_ADDR_V1}/api/company/${companyId}/invoices/${invoiceId}`;
// export const getInvoiceContentByIdUrl = (companyId, invoiceId) => `${SERVER_ADDR_V1}/api/company/${companyId}/invoices/${invoiceId}/content`;

// export const getInvoicesReceivablesUrl = (customerId, siren,) => `https://od-one-flow-stg.securibox.eu/api/v1/receivables?customerId=${customerId}&siren=${siren}`;
export const getInvoicesReceivablesUrl = (customerId, siren) => `${SERVER_ADDR_V2}/api/v1/receivables?customerId=${customerId}&siren=${siren}`;
export const getInvoicesPayablesUrl = (customerId, siren,) => `${SERVER_ADDR_V2}/api/v1/payables?customerId=${customerId}&siren=${siren}`;
export const getInvoiceByIdUrl = (customerId, siren, invoiceId) => `${SERVER_ADDR_V2}/api/v1/invoices/${invoiceId}?customerId=${customerId}&siren=${siren}`;
export const updateInvoiceByIdUrl = (customerId, siren, invoiceId) => `${SERVER_ADDR_V2}/api/v1/invoices/${invoiceId}/status?customerId=${customerId}&siren=${siren}`;
export const getInvoiceHistoryByIdUrl = (customerId, siren, invoiceId) => `${SERVER_ADDR_V2}/api/v1/invoices/${invoiceId}/status-history?customerId=${customerId}&siren=${siren}`;
export const getInvoiceContentByIdUrl = (customerId, siren, invoiceId) => `${SERVER_ADDR_V2}/api/v1/invoices/${invoiceId}/content?customerId=${customerId}&siren=${siren}`;

