import axios from 'axios';
import * as R from 'ramda';
import * as urls from '../constants/webapi/company';
import { companyActions } from '../reducers/company';


export const GetCompanies = () => {
    return async dispatch => {
        dispatch(companyActions.getCompaniesRequest());
        return axios.get(urls.getCompaniesUrl())
            .then((response) => {
                dispatch(companyActions.getCompaniesSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(companyActions.getCompaniesFailure(error.response.data));
            });
    };
};

export const CreateCompany = (company = {}) => {
    if (R.isEmpty(company)) {
        throw new Error(`Invalid company`);
    }
    return async dispatch => {
        dispatch(companyActions.createCompanyRequest());
        return axios.post(urls.getCompaniesUrl(), company)
            .then((response) => {
                dispatch(companyActions.createCompanySuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(companyActions.createCompanyFailure(error.response.data));
            });
    };
};

export const GetCompany = (companyId) => {
    if (R.isNil(companyId)) {
        throw new Error(`Invalid companyId: ${companyId}`);
    }
    return async dispatch => {
        dispatch(companyActions.getCompanyRequest());
        return axios.get(urls.getCompanyByIdUrl(companyId))
            .then((response) => {
                dispatch(companyActions.getCompanySuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(companyActions.getCompanyFailure(error.response.data));
            });
    };
};

export const GetCompanyBySiren = (siren) => {
    return async dispatch => {
        dispatch(companyActions.getCompanyRequest());
        return axios.get(urls.getCompanyBySirenUrl(siren))
            .then((response) => {
                dispatch(companyActions.getCompanySuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(companyActions.getCompanyFailure(error.response.data));
            });
    };
};

export const UpdateCompany = (company = {}) => {
    if (R.isEmpty(company)) {
        throw new Error(`Invalid company`);
    }
    return async dispatch => {
        dispatch(companyActions.updateCompanyRequest());
        return axios.put(urls.getCompanyByIdUrl(company.id), company)
            .then((response) => {
                dispatch(companyActions.updateCompanySuccess(response.data));
            })
            .catch((error) => {
                dispatch(companyActions.updateCompanyFailure(error.response.data));
            });
    };
};

export const DeleteCompany = (companyId) => {
    if (R.isNil(companyId)) {
        throw new Error(`Invalid companyId: ${companyId}`);
    }
    return async dispatch => {
        dispatch(companyActions.deleteCompanyRequest());
        return axios.delete(urls.getCompanyByIdUrl(companyId))
            .then((response) => {
                dispatch(companyActions.deleteCompanySuccess(response.data));
            })
            .catch((error) => {
                dispatch(companyActions.deleteCompanyFailure(error.response.data));
            });
    };
};

export const ResetCompany = () => {
    return async dispatch => {
        dispatch(companyActions.resetCompany());
    };
};

export const UpdateFormMode = (mode) => {
    return async dispatch => {
        dispatch(companyActions.updateFormMode(mode));
    };
};
