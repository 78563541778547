import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';


import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './reducers';
import reportWebVitals from './reportWebVitals';


// Assets
// ==========================================
// Assets: Libraries
import 'bootstrap/dist/js/bootstrap.bundle';
import 'react-toastify/dist/ReactToastify.css';

// Assets: Global
import './assets/stylesheets/materialsymbols.scss';
import './assets/stylesheets/bootstrap.scss';


// Components
// ==========================================
// Components: Common
import { Preloader } from './_common/preloader';
import { NoMatch } from './_common/no-match';

// Components: Views
import { App } from './app';
import { URLParams, urlQueries } from './constants/utils/urlParams';
import { Authentication } from './_common/hocs/authentication';

const container = document.getElementById('root');
const root = createRoot(container);
URLParams.register(urlQueries);

root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <ToastContainer />
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<App />} />
                <Route path="404" element={<NoMatch redirect="/" />} />
                <Route path="*" element={<Navigate to="/404" />} />
                {/* <Route path="/" element={<Navigate replace to="/app" />} /> */}
            </Routes>
        </BrowserRouter>
    </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
