import React from 'react';

export const Loading = () => {
    return (
        <div className="loading loading-squares text-primary m-auto" role="status">
            <div className="spinner spinner-loading"></div>
            <div className="spinner spinner-loading"></div>
            <div className="spinner spinner-loading"></div>
            <span className="visually-hidden">Loading...</span>
        </div>
    );
};
