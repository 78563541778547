import axios from 'axios';
import * as R from 'ramda';
import * as urls from '../constants/webapi/connection';
import { connectionActions } from '../reducers/connection';


export const GetConnections = (customerId, companyId) => {
    if (R.isNil(companyId)) {
        throw new Error(`Invalid companyId: ${companyId}`);
    }
    return async dispatch => {
        dispatch(connectionActions.getConnectionsRequest());
        return axios.get(urls.getConnectionsUrl(customerId, companyId))
            .then((response) => {
                dispatch(connectionActions.getConnectionsSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(connectionActions.getConnectionsFailure(error.response.data));
            });
    };
};

export const GetPpfConnections = (customerId, companyId) => {
    if (R.isNil(companyId)) {
        throw new Error(`Invalid companyId: ${companyId}`);
    }
    return async dispatch => {
        dispatch(connectionActions.getConnectionsRequest());
        return axios.get(urls.getConnectionsToPpfUrl(customerId, companyId))
            .then((response) => {
                if (R.isNil(response.data)) {
                    dispatch(connectionActions.getConnectionsSuccessPpf(null));
                } else {
                    dispatch(connectionActions.getConnectionsSuccessPpf({ ...response.data, connectionType: "PPF" }));
                }
                return response.data;
            })
            .catch((error) => {
                dispatch(connectionActions.getConnectionsFailure(error.response.data));
            });
    };
};


// export const createConnection = (companyId, data) => {
//     if (R.isNil(companyId)) {
//         throw new Error(`Invalid companyId: ${companyId}`);
//     }
//     return async dispatch => {
//         dispatch(connectionActions.createConnectionRequest());
//         return axios.post(urls.getConnectionsUrl(companyId), data)
//             .then((response) => {
//                 dispatch(connectionActions.createConnectionSuccess(response.data));
//                 return response.data;
//             })
//             .catch((error) => {
//                 dispatch(connectionActions.createConnectionFailure(error.response.data));
//             });
//     };
// };

export const createConnection = (customerId, companyId, data) => {
    if (R.isNil(companyId)) {
        throw new Error(`Invalid companyId: ${companyId}`);
    }
    if (R.isNil(customerId)) {
        throw new Error(`Invalid customerId: ${customerId}`);
    }
    return async dispatch => {
        dispatch(connectionActions.createConnectionRequest());
        return axios.put(urls.getConnectionsToPpfUrl(customerId, companyId), data)
            .then((response) => {
                dispatch(connectionActions.createConnectionSuccess({ ...response.data, connectionType: "PPF" }));
                return response.data;
            })
            .catch((error) => {
                dispatch(connectionActions.createConnectionFailure(error.response.data));
            });
    };
};



export const DeleteConnection = (companyId, connectionId) => {
    if (R.isNil(companyId) || R.isNil(connectionId)) {
        throw new Error(`Invalid companyId: ${companyId} or connectionId:${connectionId}`);
    }
    return async dispatch => {
        dispatch(connectionActions.deleteConnectionRequest());
        return axios.delete(urls.getConnectionByIdUrl(companyId, connectionId))
            .then((response) => {
                dispatch(connectionActions.deleteConnectionSuccess(connectionId));
            })
            .catch((error) => {
                dispatch(connectionActions.deleteConnectionFailure(error.response.data));
            });
    };
};

export const ResetConnection = () => {
    return async dispatch => {
        dispatch(connectionActions.resetConnection());
    };
};