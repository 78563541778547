import axios from 'axios';
import * as R from 'ramda';
import * as urls from '../constants/webapi/member';
import { memberActions } from '../reducers/member';


export const GetMembers = (companyId) => {
    if (R.isNil(companyId)) {
        throw new Error(`Invalid companyId: ${companyId}`);
    }
    return async dispatch => {
        dispatch(memberActions.getMembersRequest());
        return axios.get(urls.getMembersUrl(companyId))
            .then((response) => {
                dispatch(memberActions.getMembersSuccess(response.data));
            })
            .catch((error) => {
                dispatch(memberActions.getMembersFailure(error.response.data));
            });
    };
};


export const CreateMember = (member = {}) => {
    if (R.isEmpty(member)) {
        throw new Error(`Invalid member`);
    }
    return async dispatch => {
        dispatch(memberActions.createMemberRequest());
        return axios.post(urls.getMembersUrl(member.companyId), member)
            .then((response) => {
                dispatch(memberActions.createMemberSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(memberActions.createMemberFailure(error.response.data));
                throw error;
            });
    };
};


export const GetMember = (companyId, memberId) => {
    if (R.isNil(companyId) || R.isNil(memberId)) {
        throw new Error(`Invalid companyId: ${companyId} or memberId: ${memberId}`);
    }
    return async dispatch => {
        dispatch(memberActions.getMemberrequest());
        return axios.get(urls.getMemberByIdUrl(companyId, memberId))
            .then((response) => {
                dispatch(memberActions.getMemberSuccess(response.data));
            })
            .catch((error) => {
                dispatch(memberActions.getMemberFailure(error.response.data));
            });
    };
};


export const UpdateMember = (member = {}) => {
    if (R.isEmpty(member)) {
        throw new Error(`Invalid member`);
    }
    return async dispatch => {
        dispatch(memberActions.updateMemberRequest());
        return axios.put(urls.getMemberByIdUrl(member.companyId, member.id), member)
            .then((response) => {
                dispatch(memberActions.updateMemberSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(memberActions.updateMemberFailure(error.response.data));
                throw error;
            });
    };
};


export const DeleteMember = (companyId, memberId) => {
    if (R.isNil(companyId) || R.isNil(memberId)) {
        throw new Error(`Invalid companyId: ${companyId} or memberId: ${memberId}`);
    }
    return async dispatch => {
        dispatch(memberActions.deleteMemberRequest());
        return new Promise((resolve, reject) => {
            axios.delete(urls.getMemberByIdUrl(companyId, memberId))
                .then((response) => {
                    dispatch(memberActions.deleteMemberSuccess({ id: memberId }));
                    resolve();
                })
                .catch((error) => {
                    dispatch(memberActions.deleteMemberFailure(error.response.data));
                    reject();
                });
        });
    };
};

export const ResetMember = () => {
    return async dispatch => {
        dispatch(memberActions.resetMember());
    };

};