import { createSlice } from '@reduxjs/toolkit';
import * as Loadings from '../constants/loadings/authentication';

const initState = {
    isLoading: [],
    account: undefined,
    user: undefined,
    userPermissions: [],
    isDeleting: false,
    error: undefined
};

const AuthenticationReducer = createSlice({
    name: 'authentication',
    initialState: { ...initState },
    reducers: {
        createUserRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_USER)) {
                state.isLoading.push(Loadings.CREATING_USER);
            }
        },
        createUserSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_USER);
            state.account = payload;
            state.user = payload;
        },
        createUserFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_USER);
        },

        activateAccountRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.ACTIVATING_ACCOUNT)) {
                state.isLoading.push(Loadings.ACTIVATING_ACCOUNT);
            }
        },
        activateAccountSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.ACTIVATING_ACCOUNT);
            state.account = payload.data;
        },
        activateAccountFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.ACTIVATING_ACCOUNT);
        },
    }
});

export const authenticationActions = AuthenticationReducer.actions;
export default AuthenticationReducer.reducer;