import axios from 'axios';
import * as R from 'ramda';
import * as urls from '../constants/webapi/invoice';
import { invoiceActions } from '../reducers/invoice';
import { INVOICE_TYPE } from '../constants/utils/global';


// export const GetInvoices = (companyId) => {
//     return async dispatch => {
//         dispatch(invoiceActions.getInvoicesRequest());
//         return axios.get(urls.getInvoicesUrl(companyId))
//             .then((response) => {
//                 dispatch(invoiceActions.getInvoicesSuccess(response.data));
//             })
//             .catch((error) => {
//                 dispatch(invoiceActions.getInvoicesFailure(error.response.data));
//             });
//     };
// };

export const GetReceivablesInvoices = (customerId, siren) => {
    return async dispatch => {
        dispatch(invoiceActions.getInvoicesRequest({ type: INVOICE_TYPE.receivables }));
        return axios.get(urls.getInvoicesReceivablesUrl(customerId, siren))
            .then((response) => {
                dispatch(invoiceActions.getInvoicesSuccess({ ...response.data, type: INVOICE_TYPE.receivables }));
            })
            .catch((error) => {
                dispatch(invoiceActions.getInvoicesFailure(error.response));
            });
    };
};

export const GetPayablesInvoices = (customerId, siren) => {
    return async dispatch => {
        dispatch(invoiceActions.getInvoicesRequest({ type: INVOICE_TYPE.payables }));
        return axios.get(urls.getInvoicesPayablesUrl(customerId, siren))
            .then((response) => {
                dispatch(invoiceActions.getInvoicesSuccess({ ...response.data, type: INVOICE_TYPE.payables }));
            })
            .catch((error) => {
                dispatch(invoiceActions.getInvoicesFailure(error.response));
            });
    };
};


export const GetInvoice = (customerId, siren, invoiceId) => {
    if (R.isNil(siren) || R.isNil(invoiceId)) {
        throw new Error(`Invalid siren: ${siren} or invoiceId: ${invoiceId}`);
    }
    return async dispatch => {
        dispatch(invoiceActions.getInvoiceRequest());
        return axios.get(urls.getInvoiceByIdUrl(customerId, siren, invoiceId))
            .then((response) => {
                dispatch(invoiceActions.getInvoiceSuccess(response.data));
            })
            .catch((error) => {
                dispatch(invoiceActions.getInvoiceFailure(error.response.data));
            });
    };
};

export const GetInvoiceHistory = (customerId, siren, invoiceId) => {
    if (R.isNil(siren) || R.isNil(invoiceId)) {
        throw new Error(`Invalid siren: ${siren} or invoiceId: ${invoiceId}`);
    }
    return async dispatch => {
        dispatch(invoiceActions.getInvoiceHistoryRequest());
        return axios.get(urls.getInvoiceHistoryByIdUrl(customerId, siren, invoiceId))
            .then((response) => {
                dispatch(invoiceActions.getInvoiceHistorySuccess(response.data));
            })
            .catch((error) => {
                dispatch(invoiceActions.getInvoiceHistoryFailure(error.response.data));
            });
    };
};

export const UpdateInvoiceStatus = (customerId, siren, invoiceId, status) => {
    if (R.isNil(siren) || R.isNil(invoiceId)) {
        throw new Error(`Invalid siren: ${siren} or invoiceId: ${invoiceId}`);
    }
    return async dispatch => {
        dispatch(invoiceActions.updateInvoiceHistoryRequest());
        return axios.post(urls.updateInvoiceByIdUrl(customerId, siren, invoiceId), status)
            .then((response) => {
                dispatch(invoiceActions.updateInvoiceHistorySuccess({ ...response.data, invoiceId }));
            })
            .catch((error) => {
                dispatch(invoiceActions.updateInvoiceHistoryFailure(error.response.data));
            });
    };
};


export const UpdateInvoice = (type, customerId, siren, invoiceId, invoice = {}) => {
    if (R.isEmpty(invoice)) {
        throw new Error(`Invalid invoice`);
    }
    return async dispatch => {
        dispatch(invoiceActions.updateInvoiceRequest());
        return axios.post(urls.updateInvoiceByIdUrl(customerId, siren, invoiceId), invoice)
            .then((response) => {
                dispatch(invoiceActions.updateInvoiceSuccess({ ...invoice, id: invoiceId, type: type }));
                return response.data;
            })
            .catch((error) => {
                dispatch(invoiceActions.updateInvoiceFailure(error.response.data));
                throw error;
            });
    };
};

export const UploadReceivablesInvoice = (customerId, siren, content) => {
    if (R.isEmpty(customerId) || R.isEmpty(siren)) {
        throw new Error(`Invalid customerId ${customerId} or siren ${siren}`);
    }
    return async dispatch => {
        dispatch(invoiceActions.uploadInvoiceRequest());
        return axios.post(urls.getInvoicesReceivablesUrl(customerId, siren), content)
            .then((response) => {
                dispatch(invoiceActions.uploadInvoiceSuccess({ ...response.data, type: INVOICE_TYPE.receivables }));
                return response.data;
            })
            .catch((error) => {
                dispatch(invoiceActions.uploadInvoiceFailure(error.response.data));
                throw error;
            });
    };
};


export const ResetInvoice = () => {
    return async dispatch => {
        dispatch(invoiceActions.resetInvoiceSuccess());

    };
};

export const GetInvoiceContent = (customerId, siren, invoiceId) => {
    if (R.isNil(customerId) || R.isNil(siren) || R.isNil(invoiceId))
        throw new Error(`Invalid siren or invoiceId`);
    return axios.get(urls.getInvoiceContentByIdUrl(customerId, siren, invoiceId), { responseType: "arraybuffer" });
};

export const ResetInvoices = () => {
    return async dispatch => {
        dispatch(invoiceActions.resetInvoice());
    };
};