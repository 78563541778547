import React from 'react';
import { Link, useParams } from 'react-router-dom';

export const Empty = () => {
    const { companyId } = useParams();
    return (
        <section className="view flex-grow-1 justify-content-center scroll-sm-auto show">
            <div className="container-fluid col-lg-9 col-xxl-7 col-hd-5">
                <span className="icns material-symbols-rounded o-66" style={{fontSize: '8rem'}}>border_clear</span>
                {/* <h3 className="display-1 fw-bold text-secondary lh-1 ff-base">Looks like <br className="d-none d-xxl-block" /> it's empty.</h3> */}
                <h3 className="display-1 fw-bold text-accent lh-1 ff-base ls-n3 text-opacity-75">Pas de factures client.</h3>
                <div className="fs-5 o-88 mb-4 mw-hd-85">
					Désolé, il n'y a pas de factures disponibles et nous n'arrivons pas à trouver ce que vous cherchez.
                </div>
                <div className="fs-lg o-88 mb-4">
                    {/* There are no invoices available for now. */}
                    {/* <Link to="/settings/connectes" className="btn p-0 border-0 link-primary ms-xl-5" type="submit"><span className="text-body align-middle">Get Started</span><i className="icns material-symbols-rounded ms-3 align-middle">arrow_forward</i></Link> */}
                    Révisez vos filtres.
					{/* Il n'y a pas de factures disponibles. */}
                    <Link to={`/c/${companyId}/accountReceivables`} className="btn p-0 border-0 link-primary ms-xl-5" ><span className="text-body align-middle">Actualiser</span><i className="icns material-symbols-rounded ms-2 align-middle">refresh</i></Link>
                </div>

            </div>
        </section>
    );
};