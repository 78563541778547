import axios from 'axios';
import * as R from 'ramda';
import * as urls from '../constants/webapi/routingRules';
import { routingRulesActions } from '../reducers/routingRules';


export const GetRoutingRules = (customerId, companyId) => {
    if (R.isNil(companyId)) {
        throw new Error(`Invalid companyId: ${companyId}`);
    }
    return async dispatch => {
        dispatch(routingRulesActions.getRoutingRulesRequest());
        return axios.get(urls.getRoutingRulesUrl(customerId, companyId))
            .then((response) => {
                dispatch(routingRulesActions.getRoutingRulesSuccess(response.data));
                return response.data;
            })
            .catch((error) => {
                dispatch(routingRulesActions.getRoutingRulesFailure(error.response.data));
            });
    };
};