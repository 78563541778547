import React from 'react';
import { Link } from "react-router-dom";

export const Empty = () => {
    return (
        <section className="view flex-grow-1 justify-content-center scroll-sm-auto show">
            <div className="container-fluid col-md-6 col-lg-8 col-xxl-5">
                <span className="icns material-symbols-rounded display-1 text-muted">border_clear</span>
                <h3 className="display-1 fw-bold text-success lh-1 ff-base">Looks like <br /> it's empty.</h3>
                <div className="lead o-88 mb-4">
                    We can't seem to find what you're looking for. Files work with all your configured agents.
                </div>
				<div className="lead o-88">
					<span className="me-2 align-middle my-2">There are no Files available at the moment.</span>
				</div>
				<Link to="/files" className="btn p-0 border-0 link-success" type="submit"><span className="text-body align-middle">Get Started</span><i className="icns material-symbols-rounded ms-3 align-middle">arrow_forward</i></Link>
				{/* <button className="btn btn-success" type="submit">Get Started</button> */}
            </div>
        </section>
    );
}