

import React from 'react';
import { Routes, Route, useLocation, useParams } from "react-router-dom";
import { Preloader } from '../../../../../../_common/preloader';
import { NoMatch } from "../../../../../../_common/no-match";
import { Donnes } from './donnes';

export const Body = ({ invoiceState, item, setItem, doc, setDoc }) => {
    const { companyId } = useParams();
    const location = useLocation();

    return (
        <section className="offcanvas-body flex-column flex-grow-1 h-100 scroll-auto px-lg-0 py-0" id="Detail">

            <Routes>
                <Route index element={
                    <React.Suspense fallback={<Preloader />}>
                        <Donnes invoiceState={invoiceState} item={item} setItem={setItem} location={location} doc={doc} setDoc={setDoc} />
                    </React.Suspense>
                } />
                {/* <Route path="settings" element={
                    <React.Suspense fallback={<Preloader />}>
                        <Settings />
                    </React.Suspense>
                } /> */}
                <Route path="*" element={
                    <React.Suspense fallback={<Preloader />}>
                        <NoMatch redirect={`/${companyId}/inbox`} dismiss="offcanvas" />
                    </React.Suspense>
                } />
            </Routes>
        </section>
    );
};