export const dataInvoice = {
    id: null,
    name: "",
    type: "",
    paymentMethod: "",
    source: "",
    emissionDate: "",
    paymentDate: "",
    dueDate: "",
    status: "",
    currency: "",
    totalAmount: 0,
    vatTable: [
        {
            rate: 2.1,
            baseAmount: 99925494.0,
            amountVat: 8.31,
        },
    ],
    supplier: {
        logo: "",
        name: "",
        vatNumber: "",
        siren: "",
        siret: "",
        iban: "",
    },
    customer: {
        name: "",
        vatNumber: "",
        siren: "",
        siret: "",
        iban: "",
    },
    indications: {
        vatTablesTotalAmountConsistency: true,
        sirenVatNumberConsistency: true,
        sirenSiretConsistency: true,
    },
    productLines: []
};

export const dataNewInvoice = {
    "id": 0,
    "invoiceIdentifier": "",
    "emissionDate": null,
    "currencyCode": "",
    "dueDate": null,
    "invoiceLines": [],
    "vatTable": [],
    "billingPeriod": null,
    "seller": {
        "legalName": null,
        "commercialName": "",
        "siren": "",
        "vatNumber": "",
        "siret": "",
        "email": null,
        "contact": null,
        "address": {
            "line1": "",
            "line2": null,
            "line3": null,
            "city": "",
            "postalCode": "",
            "country": ""
        }
    },
    "buyer": {
        "legalName": null,
        "commercialName": "",
        "siren": "",
        "vatNumber": "",
        "siret": "",
        "email": null,
        "contact": null,
        "address": {
            "line1": "",
            "line2": null,
            "line3": null,
            "city": "",
            "postalCode": "",
            "country": ""
        }
    },
    "sumOfNetTotalAmountLines": 0,
    "sumOfDiscounts": 0,
    "sumOfFees": 0,
    "totalAmountVatExcluded": 0,
    "totalAmountVat": 0,
    "totalAmountVatIncluded": 0,
    "paidAmount": 0,
    "dueAmount": 0,
    "statusHistory": [{ statusCode: '200', date: "0001-01-01T00:00:00" }]
};
// export const dataNewInvoice = {
// 	id: "",
// 	type: "facture",
// 	status: "Deposee",
// 	emissionDate: "",
// 	dueDate: "",
// 	paymentDate: "",
// 	supplier: {
// 		name: "EDF-SA",
// 		siren: "552081317",
// 		vatNumber: "FR03552081317",
// 		mode: "debit"
// 	},
// 	customer: {
// 		name: "EDF-SA",
// 		siren: "552081317",
// 		vatNumber: "FR03552081313"
// 	},
// 	currency: "€",
// 	totalAmount: "",
// 	vatAmount: "",
// 	TTCAmount: "",
// 	dueAmount: "",
// 	productLines: [
// 		{
// 			id: "1223",
// 			description: "Hello",
// 			quantity: "",
// 			unitPrice: "",
// 			vatPercentage: "",
// 			totalAmount: "",
// 			type: "",
// 		}
// 	],
// 	vatTable: [
// 		{
// 			rate: 5.5,
// 			baseAmount: 0.97,
// 			amountVat: 17.59
// 		},
// 		{
// 			rate: 20,
// 			baseAmount: 49.36,
// 			amountVat: 9.87
// 		}
// 	]
// };

export const dataInvoices = [
    {
        id: "7eba6e68-2e9d-4d06-968f-e4b807951059",
        name: "2384661.pdf",
        type: "invoice",
        paymentMethod: "LCR",
        source: 0,
        emissionDate: "2023-03-01T03:02:05.950Z",
        paymentDate: "2023-03-18T06:30:28.034Z",
        dueDate: "2023-03-16T06:34:55.386Z",
        status: "Paiement transmis",
        currency: "EUR",
        totalAmount: 99925502.31,
        vatTable: [
            {
                rate: 2.1,
                baseAmount: 99925494.0,
                amountVat: 8.31,
            },
        ],
        supplier: {
            logo: "amazon",
            name: "Amazon Supplie jbrev ghevedbjebfekjbflefbjefhbr ghevedbjebfekjbflefbjefhbr",
            vatNumber: "FR74500103710",
            siren: "500103710",
            siret: "50010371000076",
            iban: "",
        },
        customer: {
            name: "Phie saint jacques",
            vatNumber: "FR15494517253",
            siren: "494517253",
            siret: "49451725300014",
            iban: "FR7630027172210002045550202",
        },
        indications: {
            vatTablesTotalAmountConsistency: true,
            sirenVatNumberConsistency: true,
            sirenSiretConsistency: true,
        },
        productLines: [
            {
                "idNumber": "405-6827358-2769119",
                "description": "ASIN: B0B8P6JC3N",
                "rate": "23%",
                "baseAmount": "4.95",
                "totalAmount": "6.09",
                "date": "2023-03-18T06:30:28.034Z",
            },
        ]
    },
    {
        id: 2384662,
        name: "2384662.pdf",
        type: "invoice",
        paymentMethod: "LCR",
        source: 0,
        emissionDate: "2021-07-14T00:00:00",
        dueDate: "2023-11-30T00:00:00",
        paymentDate: "2023-11-30T00:00:00",
        status: "Paiement transmis",
        currency: "EUR",
        totalAmount: 9549.95,
        vatTable: [
            {
                rate: 2.1,
                baseAmount: 9541.64,
                amountVat: 8.31,
            },
        ],
        supplier: {
            logo: "edf",
            name: "EDF - Electricite De France",
            vatNumber: "FR03552081317",
            siren: "552081317",
            siret: "55208131766522",
            iban: "",
        },
        customer: {
            name: "Phie saint jacques",
            vatNumber: "FR15494517253",
            siren: "494517253",
            siret: "49451725300014",
            iban: "FR7630027172210002045550202",
        },
        indications: {
            vatTablesTotalAmountConsistency: true,
            sirenVatNumberConsistency: true,
            sirenSiretConsistency: true,
        },
        productLines: []
    },
    {
        id: "801ff5d3-ff5b-4a11-bd1f-efa5fee9b6a7",
        name: "toys_fantastic.png",
        paymentMethod: "Débit",
        status: "Suspendue",
        source: 4,
        emissionDate: "2023-05-08T06:34:55.386Z",
        dueDate: "2023-03-20T03:02:05.950Z",
        paymentDate: "2023-03-18T06:30:28.034Z",
        totalAmount: "73.26",
        currency: "EUR",
        vatTable: [
            {
                rate: 0,
                baseAmount: "73.26",
                amountVat: 46,
            },
        ],
        supplier: {
            name: "Apple",
            logo: "applefr",
            vatNumber: "FR48941585118",
            siren: "",
            siret: "54295933102367",
            iban: "FR720861401266LP6IO7O6W1Y70",
        },
        customer: {
            name: "Dwayne Auer",
            vatNumber: "FR35320486738",
            siren: "542174790",
            siret: "54217479000014",
            iban: "FR628209319958TL2OQ30Y6IY40",
        },
        indications: {
            vatTablesTotalAmountConsistency: false,
            sirenVatNumberConsistency: true,
            sirenSiretConsistency: false,
        },
        productLines: [
            {
                "idNumber": "",
                "description": "",
                "rate": "",
                "amountVat": "",
                "baseAmount": "",
                "totalAmount": "",
                "date": "",
            },
            {
                "idNumber": "",
                "description": "",
                "rate": "",
                "amountVat": "",
                "baseAmount": "",
                "totalAmount": "",
                "date": "",
            },
        ]
    },
    {
        id: "7eba6e68-2e9d-4d06-968f-e4b807951059",
        name: "solid.gif",
        paymentMethod: "LCR",
        source: 0,
        emissionDate: "2023-03-24T16:15:52.936Z",
        dueDate: "2023-08-28T15:44:03.673Z",
        paymentDate: "2023-08-16T10:33:10.551Z",
        status: "Suspendue",
        totalAmount: "57.95",
        currency: "EUR",
        vatTable: [
            {
                rate: 0,
                baseAmount: "57.94",
                amountVat: 99,
            },
        ],
        supplier: {
            name: "Free Mobile",
            logo: "freemobile",
            vatNumber: "FR27487260342",
            siren: "",
            siret: "54212342431045",
            iban: "FR757320481601UYH56LGXXXH58",
        },
        customer: {
            name: "Margie Cassin",
            vatNumber: "FR50377986859",
            siren: "542826581",
            siret: "54217479000014",
            iban: "FR3248180213351PO5FDCIHSA97",
        },
        indications: {
            vatTablesTotalAmountConsistency: true,
            sirenVatNumberConsistency: true,
            sirenSiretConsistency: true,
        },
    },
    {
        id: "53c6f3fa-9ec7-4e8a-be92-b46be73de2d9",
        name: "navigating_encryption.mp2",
        paymentMethod: "Prélèvement",
        type: 1,
        emissionDate: "2023-11-14T16:57:00.245Z",
        status: "Pris en charge",
        dueDate: "2023-07-02T19:04:55.654Z",
        paymentDate: "2023-11-17T13:18:13.203Z",
        totalAmount: "32.73",
        currency: "EUR",
        source: 3,
        vatTable: [
            {
                rate: "80",
                baseAmount: "30.73",
                amountVat: "2",
            },
        ],
        supplier: {
            name: "SNCF Ter Grand-Est",
            logo: "sncftergrandest",
            vatNumber: "FR50201534454",
            siren: "",
            siret: "54240739069700",
            iban: "FR5855354744150JACMXQ7NQG48",
        },
        customer: {
            name: "Ben Gerhold",
            vatNumber: "FR13903364838",
            siren: "542256315",
            siret: "54225631500014",
            iban: "FR416143631745K015SI9591346",
        },
        indications: {
            vatTablesTotalAmountConsistency: true,
            sirenVatNumberConsistency: true,
            sirenSiretConsistency: true,
        },
    },
    {
        id: "d5a0af1e-8b80-442e-9a77-55e8091aa27e",
        name: "pizza.mp4v",
        paymentMethod: "Chèque",
        status: "Pris en charge",
        source: 4,
        emissionDate: "2023-08-17T11:43:45.513Z",
        dueDate: "2023-12-26T00:22:07.885Z",
        paymentDate: "2023-03-11T14:51:50.857Z",
        totalAmount: "867.37",
        currency: "EUR",
        vatTable: [
            {
                rate: 80,
                baseAmount: "66.37",
                amountVat: 81
            },
        ],
        supplier: {
            name: "Total Energies",
            logo: "directenergie",
            vatNumber: "FR44614434726",
            siren: "",
            siret: "54257296651060",
            iban: "FR103586601712RQDL2UUW0F758",
        },
        customer: {
            name: "Jeanette Beahan",
            vatNumber: "FR25604735122",
            siren: "542749022",
            siret: "",
            iban: "FR1035929938771ZERIKIH6Q335",
        },
        indications: {
            vatTablesTotalAmountConsistency: true,
            sirenVatNumberConsistency: true,
            sirenSiretConsistency: false,
        },
    },
    {
        id: "5c936b50-eb04-4e9d-96e7-c494e7d735d8",
        name: "borders.jpe",
        paymentMethod: "Chèque",
        source: 4,
        emissionDate: "2023-11-08T02:15:24.218Z",
        dueDate: "2023-04-12T04:54:03.250Z",
        paymentDate: "2023-10-02T12:43:50.222Z",
        totalAmount: "125.37",
        currency: "EUR",
        vatTable: [
            {
                rate: 0,
                baseAmount: "87.37",
                amountVat: 38,
            },
        ],
        supplier: {
            name: "Tout Sur Mon Eau: SUEZ",
            logo: "lyonnaisedeseaux",
            vatNumber: "FR35312680345",
            siren: "",
            siret: "54252882098980",
            iban: "FR7673110325015XX9NH0S8Z321",
        },
        customer: {
            name: "Leon Hansen",
            vatNumber: "FR31124463670",
            siren: "542476470",
            siret: "",
            iban: "FR66808244894779VKPUJ5IQC17",
        },
        indications: {
            vatTablesTotalAmountConsistency: true,
            sirenVatNumberConsistency: false,
            sirenSiretConsistency: false,
        },
    },
    {
        id: "ae074392-9502-4c38-8bac-965046a0ffbc",
        name: "handcrafted_sausages_arkansas.htm",
        paymentMethod: "Money",
        source: 2,
        status: "Pris en charge",
        emissionDate: "2023-03-01T10:38:58.216Z",
        dueDate: "2023-02-14T15:03:42.325Z",
        paymentDate: "2023-02-15T04:44:02.922Z",
        totalAmount: "130.45",
        currency: "EUR",
        vatTable: [
            {
                rate: 80,
                baseAmount: "42.45",
                amountVat: 88,
            },
        ],
        supplier: {
            name: "Bouygues Telecom Entreprises",
            logo: "bouyguestelecomentreprises",
            vatNumber: "FR90945618495",
            siren: "",
            siret: "54277215828228",
            iban: "FR2840282936709U1BFTGH4W792",
        },
        customer: {
            name: "Sergio Johnson",
            vatNumber: "FR14970859114",
            siren: "542015124",
            siret: "",
            iban: "FR910820919409WP1G7SFUS4M86",
        },
        indications: {
            vatTablesTotalAmountConsistency: true,
            sirenVatNumberConsistency: false,
            sirenSiretConsistency: false,
        },
    },
    {
        id: "dddbce2e-4ad2-4148-86ae-fa62a20fa36c",
        name: "indexing_card.mp3",
        paymentMethod: "Prélèvement",
        source: 1,
        status: "Pris en charge",
        emissionDate: "2023-05-14T08:11:55.735Z",
        dueDate: "2023-04-04T13:47:06.310Z",
        paymentDate: "2023-11-08T12:54:28.148Z",
        totalAmount: "15.93",
        currency: "EUR",
        vatTable: [
            {
                rate: 40,
                baseAmount: "2.93",
                amountVat: 13,
            },
        ],
        supplier: {
            name: "SNCF Ter Grand-Est",
            logo: "sncftergrandest",
            vatNumber: "FR00668000838",
            siren: "",
            siret: "54256372268827",
            iban: "FR139631372721I2BSWGRO0IY75",
        },
        customer: {
            name: "Marie Sauer",
            vatNumber: "FR70705785163",
            siren: "542057868",
            siret: "",
            iban: "FR419700239179NPQPC8ZQ3A081",
        },
        indications: {
            vatTablesTotalAmountConsistency: false,
            sirenVatNumberConsistency: true,
            sirenSiretConsistency: true,
        },
    },
    {
        id: "01f96791-ae93-4638-9cf2-e04d8b737c80",
        name: "cotton_implemented_licensed.m2a",
        paymentMethod: "Crédit",
        emissionDate: "2023-09-15T22:24:44.768Z",
        dueDate: "2023-01-17T05:31:58.244Z",
        status: "Pris en charge",
        paymentDate: "2023-04-23T22:19:52.745Z",
        totalAmount: "79.65",
        currency: "EUR",
        vatTable: [
            {
                rate: 80,
                baseAmount: "3.65",
                amountVat: 76,
            },
        ],
        supplier: {
            name: "AXA Assurances",
            logo: "axaassurances",
            vatNumber: "FR91925164497",
            siren: "",
            siret: "54289903149955",
            iban: "FR03853578385670EPVV6C8BJ78",
        },
        customer: {
            name: "Terrance Toy",
            vatNumber: "FR23748115531",
            siren: "542449668",
            siret: "",
            iban: "FR3839362970471P6YLOO77IE40",
        },
        indications: {
            vatTablesTotalAmountConsistency: true,
            sirenVatNumberConsistency: false,
            sirenSiretConsistency: true,
        },
    },
    {
        id: "2f101ba5-0d55-4bb4-8681-3ddf78b3e3b0",
        name: "plastic.wav",
        paymentMethod: "Crédit",
        emissionDate: "2023-01-17T03:29:45.710Z",
        dueDate: "2023-07-24T20:10:56.364Z",
        paymentDate: "2023-08-28T05:51:21.410Z",
        totalAmount: "123.11",
        currency: "EUR",
        vatTable: [
            {
                rate: 40,
                baseAmount: "90.11",
                amountVat: 33,
            },
        ],
        supplier: {
            name: "Covance",
            logo: "",
            vatNumber: "FR51251766810",
            siren: "",
            siret: "54285440628455",
            iban: "FR1770105288449AYA5AZCLEL19",
        },
        customer: {
            name: "Kelli Schamberger",
            vatNumber: "FR19324839422",
            siren: "542414999",
            siret: "",
            iban: "FR285737490014CXGWGDUVYHQ43",
        },
        indications: {
            vatTablesTotalAmountConsistency: false,
            sirenVatNumberConsistency: false,
            sirenSiretConsistency: true,
        },
    },
    {
        id: "cb4d05ee-4800-4f75-8164-cb27ad12e054",
        name: "global_smtp.mpg4",
        paymentMethod: "Money",
        emissionDate: "2023-10-31T20:21:20.780Z",
        dueDate: "2023-06-09T23:28:31.343Z",
        paymentDate: "2023-11-20T03:39:01.948Z",
        totalAmount: "102.71",
        currency: "EUR",
        vatTable: [
            {
                rate: 0,
                baseAmount: "85.71",
                amountVat: 17,
            },
        ],
        supplier: {
            name: "Apple",
            logo: "applefr",
            vatNumber: "FR07788935259",
            siren: "",
            siret: "54299643890677",
            iban: "FR680035982308VI52OUJOB9615",
        },
        customer: {
            name: "Taylor Mertz",
            vatNumber: "FR33978993638",
            siren: "542619332",
            siret: "",
            iban: "FR315897040220QPHR1Q8ND8N26",
        },
        indications: {
            vatTablesTotalAmountConsistency: false,
            sirenVatNumberConsistency: false,
            sirenSiretConsistency: true,
        },
    },
    {
        id: "7210c281-c876-43e9-ad66-d7835c53484e",
        name: "homogeneous_hierarchy.mpga",
        paymentMethod: "Prélèvement",
        source: 1,
        emissionDate: "2023-03-03T18:02:22.262Z",
        dueDate: "2023-04-14T11:14:47.509Z",
        paymentDate: "2023-09-29T04:11:17.802Z",
        totalAmount: "75.64",
        currency: "EUR",
        vatTable: [
            {
                rate: 40,
                baseAmount: "13.64",
                amountVat: 62,
            },
        ],
        supplier: {
            name: "Engie",
            logo: "gdf",
            vatNumber: "FR58882356420",
            siren: "",
            siret: "54227038303012",
            iban: "FR0916819383109BVH5DGADUA01",
        },
        customer: {
            name: "Pete Hills",
            vatNumber: "FR47379283643",
            siren: "542492851",
            siret: "",
            iban: "FR2684633286276GRZZITGCED18",
        },
        indications: {
            vatTablesTotalAmountConsistency: true,
            sirenVatNumberConsistency: false,
            sirenSiretConsistency: true,
        },
    },
    {
        id: "e5969c3a-8103-40c9-8edf-531f0aed1caf",
        name: "auxiliary.mpg",
        status: "Pris en charge",
        paymentMethod: "Débit",
        emissionDate: "2023-07-30T04:07:17.114Z",
        dueDate: "2023-03-15T06:37:19.077Z",
        paymentDate: "2023-04-11T03:00:12.586Z",
        totalAmount: "75.14",
        currency: "EUR",
        vatTable: [
            {
                rate: 0,
                baseAmount: "60.99",
                amountVat: 49,
            },
        ],
        supplier: {
            name: "H.B. Fuller",
            logo: "",
            vatNumber: "FR55424432247",
            siren: "",
            siret: "54224405386763",
            iban: "FR393726833467652WAOP2T4X78",
        },
        customer: {
            name: "Leigh Kuvalis",
            vatNumber: "FR06755886130",
            siren: "542848239",
            siret: "",
            iban: "FR1234452091531SMTS0SDTSL36",
        },
        indications: {
            vatTablesTotalAmountConsistency: false,
            sirenVatNumberConsistency: false,
            sirenSiretConsistency: true,
        },
    },
    {
        id: "2e48501f-1c8f-45c5-b79f-1a6bb692499b",
        name: "software.mpg4",
        paymentMethod: "LCR",
        source: 0,
        status: "Pris en charge",
        emissionDate: "2023-10-30T07:31:47.455Z",
        dueDate: "2023-03-29T09:02:39.353Z",
        paymentDate: "2023-12-20T14:12:03.084Z",
        totalAmount: "14.43",
        currency: "EUR",
        vatTable: [
            {
                rate: 40,
                baseAmount: "2.43",
                amountVat: 12,
            },
        ],
        supplier: {
            name: "Spirit AeroSystems Holdings",
            logo: "",
            vatNumber: "FR41254079817",
            siren: "",
            siret: "54290241155246",
            iban: "FR818352358306KX7WKT8XMZ405",
        },
        customer: {
            name: "Jesse Cruickshank",
            vatNumber: "FR23379245402",
            siren: "542144911",
            siret: "",
            iban: "FR397770912122B07JA88Z39077",
        },
        indications: {
            vatTablesTotalAmountConsistency: true,
            sirenVatNumberConsistency: false,
            sirenSiretConsistency: true,
        },
    },
    {
        id: "47dd9e3a-d096-4962-9360-05d4835d5659",
        name: "e_markets_buckinghamshire.mp4v",
        paymentMethod: "Prélèvement",
        source: 1,
        emissionDate: "2023-07-01T07:49:41.865Z",
        dueDate: "2023-12-13T02:51:07.621Z",
        paymentDate: "2023-06-25T08:42:59.962Z",
        totalAmount: "55.23",
        currency: "EUR",
        vatTable: [
            {
                rate: 40,
                baseAmount: "6.23",
                amountVat: 49,
            },
        ],
        supplier: {
            name: "Engie",
            logo: "gdf",
            vatNumber: "FR11219207432",
            siren: "",
            siret: "54234668288363",
            iban: "FR020463059263E7IVEP4GHZ043",
        },
        customer: {
            name: "Sherman Schaefer",
            vatNumber: "FR40952822442",
            siren: "542219080",
            siret: "",
            iban: "FR182610163294MJ1Q329B8B554",
        },
        indications: {
            vatTablesTotalAmountConsistency: false,
            sirenVatNumberConsistency: true,
            sirenSiretConsistency: true,
        },
    },
    {
        id: "34023c18-ca31-4ac7-8d6b-93a15cbbfa75",
        name: "place_exe_web.jpg",
        paymentMethod: "Chèque",
        emissionDate: "2023-10-17T14:50:33.678Z",
        dueDate: "2023-03-11T01:11:45.674Z",
        paymentDate: "2023-09-03T14:14:01.480Z",
        totalAmount: "129.82",
        currency: "EUR",
        vatTable: [
            {
                rate: 80,
                baseAmount: "49.82",
                amountVat: 80,
            },
        ],
        supplier: {
            name: "Brown-Forman",
            logo: "",
            vatNumber: "FR65165192923",
            siren: "",
            siret: "54299547032083",
            iban: "FR468673775423MTGKO26NPPM91",
        },
        customer: {
            name: "Lewis Mann",
            vatNumber: "FR22065281465",
            siren: "542418840",
            siret: "",
            iban: "FR148781995091BGNA8T23GDZ20",
        },
        indications: {
            vatTablesTotalAmountConsistency: true,
            sirenVatNumberConsistency: false,
            sirenSiretConsistency: true,
        },
    },
    {
        id: "2a162de3-e03b-4396-9157-3aa52f4c0b39",
        name: "synergies.pdf",
        paymentMethod: "Débit",
        emissionDate: "2023-03-17T02:06:55.852Z",
        dueDate: "2023-07-22T00:21:16.840Z",
        paymentDate: "2023-04-15T03:08:02.609Z",
        totalAmount: "173.11",
        currency: "EUR",
        vatTable: [
            {
                rate: 120,
                baseAmount: "87.11",
                amountVat: 86,
            },
        ],
        supplier: {
            name: "AXA Assurances",
            logo: "axaassurances",
            vatNumber: "FR72286124431",
            siren: "",
            siret: "54295498689202",
            iban: "FR440788223750HTBVBK1E2VJ41",
        },
        customer: {
            name: "Jana Howell",
            vatNumber: "FR94812751363",
            siren: "542392440",
            siret: "",
            iban: "FR461670305371FK60J5755OD96",
        },
        indications: {
            vatTablesTotalAmountConsistency: true,
            sirenVatNumberConsistency: false,
            sirenSiretConsistency: true,
        },
    },
    {
        id: "6bf74796-be03-4df7-bdee-dd828d0a7642",
        name: "movies.png",
        paymentMethod: "Chèque",
        emissionDate: "2023-09-07T05:44:22.604Z",
        dueDate: "2023-04-10T21:31:14.280Z",
        paymentDate: "2023-12-14T20:48:46.050Z",
        totalAmount: "115.36",
        currency: "EUR",
        vatTable: [
            {
                rate: 120,
                baseAmount: "93.36",
                amountVat: 22,
            },
        ],
        supplier: {
            name: "Cincinnati Financial",
            logo: "",
            vatNumber: "FR93734288073",
            siren: "",
            siret: "54244174350627",
            iban: "FR839206068851QF0T1BDNN2Q53",
        },
        customer: {
            name: "Simon Wintheiser",
            vatNumber: "FR02468866252",
            siren: "542389703",
            siret: "",
            iban: "FR883730289577WYPA2XYQHUQ89",
        },
        indications: {
            vatTablesTotalAmountConsistency: false,
            sirenVatNumberConsistency: true,
            sirenSiretConsistency: true,
        },
    },
    {
        id: "64c4612c-3c68-486a-808f-331dc38e0380",
        name: "generation.png",
        paymentMethod: "LCR",
        source: 0,
        emissionDate: "2023-01-15T05:47:55.577Z",
        dueDate: "2023-08-27T09:18:43.757Z",
        paymentDate: "2023-09-28T03:22:47.937Z",
        totalAmount: "64.96",
        currency: "EUR",
        vatTable: [
            {
                rate: 40,
                baseAmount: "1.96",
                amountVat: 63,
            },
        ],
        supplier: {
            name: "Targa Resources",
            logo: "",
            vatNumber: "FR47894605765",
            siren: "",
            siret: "54243392915992",
            iban: "FR838996987587KP4IHPLG3K270",
        },
        customer: {
            name: "Meghan Beer",
            vatNumber: "FR37670982429",
            siren: "542950894",
            siret: "",
            iban: "FR733064388598EVICRCKL7YH51",
        },
        indications: {
            vatTablesTotalAmountConsistency: true,
            sirenVatNumberConsistency: false,
            sirenSiretConsistency: false,
        },
    },
    {
        id: 2441444,
        number: "EM444333",
        emissionDate: "2021-03-02T00:00:00",
        providerName: "GEDIMAT - FAREL CLAVEL",
        providerId: "FGEDIMAT",
        dueDate: "2023-01-01T00:00:00",
        totalAmount: 13.25,
        vatTable: [
            {
                rate: 11.04,
                baseAmount: "11.04",
                amountVat: 2.21,
            },
        ],
        gedId: null,
        type: "PDP",
        status: "Suspendue",
        paymentDate: "2023-01-01T00:00:00",
        history: null,
    },
    {
        id: 2542706,
        number: "2021 005894",
        emissionDate: "2021-04-30T00:00:00",
        providerName: "MIDI MIROITERIE",
        providerId: "FMIDIMIROITER",
        dueDate: "2023-03-01T00:00:00",
        totalAmount: 1279.5,
        vatAmount: 213.25,
        totalAmountVatExcluded: 1066.25,
        gedId: null,
        type: "PPF",
        status: "Suspendue",
        paymentDate: "2023-03-01T00:00:00",
        history: null,
    },
    {
        id: 2616283,
        number: "",
        emissionDate: "2021-07-31T00:00:00",
        providerName: "CARBURANTS",
        providerId: "FCARBURANTS",
        dueDate: "0001-01-01T00:00:00",
        totalAmount: 83.47,
        vatAmount: 13.91,
        totalAmountVatExcluded: 69.56,
        gedId: null,
        type: "Collecte",
        status: "Litige - approuvée",
        paymentDate: "0001-01-01T00:00:00",
        history: null,
    },
    {
        id: "165620",
        name: "165620.pdf",
        type: "invoice",
        paymentMethod: "LCR",
        source: 0,
        emissionDate: "2021-10-01T00:00:00",
        dueDate: "2021-11-30T00:00:00",
        paymentDate: "2023-01-01T00:00:00",
        currency: "EUR",
        totalAmount: 403.89,
        vatTable: [
            {
                "rate": 120,
                "baseAmount": 396.33,
                "amountVat": 7.56
            },
        ],
        supplier: {
            name: "CHIESI S.A.",
            vatNumber: "FR74542062922",
            siren: "542062922",
            siret: "54206292200076",
            iban: "",
        },
        customer: {
            name: "Phie saint jacques",
            vatNumber: "FR15494517253",
            siren: "494517253",
            siret: "49451725300014",
            iban: "FR7630027172210002045550202",
        },
        indications: {
            vatTablesTotalAmountConsistency: true,
            sirenVatNumberConsistency: true,
            sirenSiretConsistency: true,
        },
    },
    {
        id: 2808120,
        number: "2210704815",
        emissionDate: "2021-07-16T00:00:00",
        providerName: "ATLANTE M",
        providerId: "FATLANTEM",
        dueDate: "",
        totalAmount: 3339.55,
        vatAmount: 556.59,
        type: "email",
        totalAmountVatExcluded: 2782.96,
        gedId: null,
        status: "Suspendue",
        paymentDate: "0001-01-01T00:00:00",
        history: null,
    },
    {
        id: 2808106,
        number: "461C0005588953",
        emissionDate: "2021-07-31T00:00:00",
        providerName: "CBC",
        providerId: "FCBC",
        dueDate: "0001-01-01T00:00:00",
        totalAmount: 2461.12,
        vatAmount: 410.19,
        totalAmountVatExcluded: 2050.93,
        gedId: null,
        status: "Suspendue",
        paymentDate: "0001-01-01T00:00:00",
        history: null,
    },
    {
        id: 2808111,
        number: "410025",
        emissionDate: "2021-07-31T00:00:00",
        providerName: "TRENOIS DECAMPS",
        providerId: "FTRENOIS",
        dueDate: "0001-01-01T00:00:00",
        totalAmount: 1990.64,
        vatAmount: 331.77,
        totalAmountVatExcluded: 1658.87,
        gedId: null,
        status: "Suspendue",
        paymentDate: "0001-01-01T00:00:00",
        history: null,
    },
    {
        id: 2808201,
        number: "T13559019",
        emissionDate: "2021-07-31T00:00:00",
        providerName: "FOUSSIER",
        providerId: "FFOUSSIER",
        dueDate: "0001-01-01T00:00:00",
        totalAmount: 189.13,
        vatAmount: 31.52,
        totalAmountVatExcluded: 157.61,
        gedId: null,
        status: "Suspendue",
        paymentDate: "0001-01-01T00:00:00",
        history: null,
    },
    {
        id: 2807247,
        number: "461C0005632139",
        emissionDate: "2021-08-31T00:00:00",
        providerName: "CBC",
        providerId: "FCBC",
        dueDate: "0001-01-01T00:00:00",
        totalAmount: 2351.16,
        vatAmount: 391.86,
        totalAmountVatExcluded: 1959.3,
        gedId: null,
        status: "Suspendue",
        paymentDate: "0001-01-01T00:00:00",
        history: null,
    },
    {
        id: 2807298,
        number: "",
        emissionDate: "2021-08-31T00:00:00",
        supplier: {
            name: "Engie",
            logo: "gdf",
            vatNumber: "FR41254079817",
            siren: "",
            siret: "54290241155246",
            iban: "FR818352358306KX7WKT8XMZ405",
        },
        dueDate: "0001-01-01T00:00:00",
        totalAmount: 119.66,
        vatAmount: 19.94,
        totalAmountVatExcluded: 99.72,
        gedId: null,
        status: "Suspendue",
        paymentDate: "0001-01-01T00:00:00",
        history: null,
    },
];

export const members = [
    {
        id: 1,
        firstName: "Andre",
        lastName: "Hadley",
        email: "andre.hadley@company.com",
        verified: true,
        active: true,
        role: "Read-only",
    },
    {
        id: 2,
        firstName: "Anna",
        lastName: "Bridges",
        email: "anna.bridges@company.com",
        verified: false,
        active: false,
        role: "Adminstrator"
    },
    {
        id: 3,
        firstName: "Bianca",
        lastName: "Sutton",
        email: "bianca.sutton@company.com",
        verified: true,
        active: false,
        role: "Administrator"
    },
    {
        id: 4,
        firstName: "Brian",
        lastName: "Dawson",
        email: "brian.dawson@company.com",
        verified: true,
        active: true,
        role: "Administrator"
    },
    {
        id: 5,
        firstName: "Bruno",
        lastName: "Sutton",
        email: "bruno.sutton@company.com",
        verified: true,
        role: "Read-only",
    }
];

export const dataRoutingRules = [
    {
        "id": "00cb83bf-372a-480b-b1b1-84ff12f17307",
        "recipientSiren": 5480546,
        "recipientCompanyName": "SOCIETE DE DISTRIBUTION DE PAPIER",
        "recipientEntityType": "Assujetti",
        "recipientSiret": [
            "00548054600018",
            "00548054600059"
        ],
        "recipientRoutingCode": [
            "INVOIC:D:64A",
            "INVOIC:D:94B"
        ],
        "recipientRoutingCodeName": [
            "Routage ODETTE 64A",
            "Routage ODETTE 94B"
        ],
        "recipientRoutingCodeType": [
            "ODETTE"
        ],
        "status": "Actif",
        "flagDestinationPdp": "PFP",
        "recipientPdpIdentifier": null,
        "recipientPdpName": null,
        "recipientPdpContact": null,
        "recipientStartDate": "2023-04-11T11:14:53.2793866",
        "recipientEndDate": null
    },
    {
        "id": "c1142993-d94e-495f-b230-72e8ebde3880",
        "recipientSiren": 5480546,
        "recipientCompanyName": "SOCIETE DE DISTRIBUTION DE PAPIER",
        "recipientEntityType": "Assujetti",
        "recipientSiret": [
            "00548054600067"
        ],
        "recipientRoutingCode": [
            "INVOIC:D:66A",
            "INVOIC:D:25B"
        ],
        "recipientRoutingCodeName": [
            "Routage ODETTE 66A",
            "Routage ODETTE 25B"
        ],
        "recipientRoutingCodeType": [
            "ODETTE"
        ],
        "status": "Actif",
        "flagDestinationPdp": "PDP",
        "recipientPdpIdentifier": 1,
        "recipientPdpName": "PDP 1",
        "recipientPdpContact": "email@pdp1.com",
        "recipientStartDate": "2023-03-31T11:15:45.0263383",
        "recipientEndDate": null
    }
];