import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import * as Loadings from '../constants/loadings/company';

const initState = {
    isLoading: [],
    company: undefined,
    companies: undefined,
    isFormEmpty: false
};

const CompanyReducer = createSlice({
    name: 'company',
    initialState: { ...initState },
    reducers: {
        //#region GET COMPANIES
        getCompaniesRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_COMPANIES)) {
                state.isLoading.push(Loadings.FETCHING_COMPANIES);
            }
        },
        getCompaniesSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COMPANIES);
            state.companies = payload;
        },
        getCompaniesFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COMPANIES);
        },
        //#endregion

        //#region GET COMPANY
        getCompanyRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_COMPANY)) {
                state.isLoading.push(Loadings.FETCHING_COMPANY);
            }
        },
        getCompanySuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COMPANY);
            state.company = payload;
        },
        getCompanyFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COMPANY);
        },

        getCompanyBySirenRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_COMPANY)) {
                state.isLoading.push(Loadings.FETCHING_COMPANY);
            }
        },
        getCompanyBySirenSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COMPANY);
            state.company = payload;
        },
        getCompanyBySirenFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_COMPANY);
        },
        //#endregion

        //#region CREATE COMPANY
        createCompanyRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_COMPANY)) {
                state.isLoading.push(Loadings.CREATING_COMPANY);
            }
        },
        createCompanySuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_COMPANY);
            if (R.isNil(state.companies)) {
                state.companies = [];
            }
            state.companies.push(payload);
            state.company = payload;
        },
        createCompanyFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_COMPANY);
        },
        //#endregion

        //#region UPDATE COMPANY
        updateCompanyRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_COMPANY)) {
                state.isLoading.push(Loadings.UPDATING_COMPANY);
            }
        },
        updateCompanySuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_COMPANY);
            state.companies = state.companies.map(company => {
                if (company.id === payload.id) return payload;
                return company;
            });
        },
        updateCompanyFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_COMPANY);
        },
        //#endregion

        //#region DELETE COMPANY
        deleteCompanyRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_COMPANY)) {
                state.isLoading.push(Loadings.DELETING_COMPANY);
            }
        },
        deleteCompanySuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_COMPANY);
            state.companies = state.companies.filter(company => company.id !== payload.id);
        },
        deleteCompanyFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_COMPANY);
        },
        //#endregion
        resetCompany(state, { payload }) {
            state.company = undefined;
            state.companies = undefined;
            state.isLoading = [];
        },
        updateFormMode(state, { payload }) {
            state.isFormEmpty = payload;
        },
    }
});

export const companyActions = CompanyReducer.actions;
export default CompanyReducer.reducer;