import { Link } from "react-router-dom";

export const NoMatch = ({ redirect, dismiss }) => {
    const dismissModal = (e) => {
        e.target.setAttribute("data-bs-dismiss", dismiss);
        e.target.click();
        e.target.removeAttribute("data-bs-dismiss");
    };

    return (
        <main className="card flex-grow-1 px-4 py-5 text-center bg-none border-0" role="main">
            <div className="container m-auto">
                <small className="smallcaps fw-bold ff-aux">Error 404</small>
                <h2 className="display-1 fw-bold mb-3 mt-n2 ff-aux text-warning">Page not found!</h2>
                <p className="lead mb-2">Sorry, the page you were looking for does not exist or is not available.</p>
                <p>The site configured at this address does not contain the requested file.</p>
                <p className="mb-6">If this is your site, make sure that the filename case matches the URL. <br />
                    For root URLs (like http://example.com/) you must provide an index.html file.</p>
                <Link className="btn btn-warning" to={redirect} onClick={dismissModal} >Go back</Link>
            </div>
        </main>
    );
};
