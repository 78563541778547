import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
// import { Tooltip } from 'bootstrap';
import * as R from 'ramda';
import axios from 'axios';
import * as qs from 'query-string';

// Components
// ==========================================
// Components: Utils
import "./../assets/stylesheets/app.scss";
import { URLParams } from '../constants/utils/urlParams';
import { getAuthenticationUrl } from '../constants/webapi/authentication';

// Components: Common
import { NoMatch } from './../_common/no-match';
import { Preloader } from './../_common/preloader';

import { Topbar } from './_common/topbar';
import { Navbar } from './_common/navbar';
import { Notifications } from './_common/notifications';
import { ModalAuth } from './_common/modalAuth';
import { ModalAccount } from './_common/modalAccount';

// Components: Views
import { Inbox } from './inbox';
import { Files } from './files';
import { Settings } from './settings';
import { CreateUser } from '../actions/authentication-api';
import * as LoadingsAuth from '../constants/loadings/authentication';
import * as LoadingsCompany from '../constants/loadings/company';
import { Loading } from '../_common/loading';
import { GetConnections, GetPpfConnections } from '../actions/connection-api';
import { GetCompanies, GetCompany, GetCompanyBySiren } from '../actions/company-api';
import { GetRoutingRules } from '../actions/routingRules-api';
import { Receivables } from './receivables';
import ModalHelp from './_common/modalHelp';
import { useTheme } from '../hooks/useTheme';
import { storageService } from '../constants/utils/storage';
import { extractDataFromState } from '../constants/utils/extractDataFromState';
import { Main } from './main';


export const App = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { theme, setTheme } = useTheme();
    const QUERIES = URLParams.queries();
    const authenticationState = useSelector(state => state.authentication);
    const connectionState = useSelector(state => state.connection);
    const invoiceState = useSelector(state => state.invoice);
    const companyState = useSelector(state => state.company);
    const memberState = useSelector(state => state.member);
    const routingRulesState = useSelector(state => state.routingrules);

    useEffect(() => {
        async function init() {
            let query = qs.parse(window.location.hash);
            let state = QUERIES.state || query.state;
            const { idToken, accessToken } = storageService();
            axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;

            if (!R.isNil(state)) {
                const data = extractDataFromState(state);
                await dispatch(CreateUser(idToken, data));
                var companies = await dispatch(GetCompanies());
                if (!state.includes(',')) {
                    let company = await dispatch(GetCompanyBySiren(state));
                    navigate(`c/${company.id}/settings/connectes?q=technical`);
                } else {
                    navigate(`c/${companies[0].id}/inbox`);
                }

            } else
                if (authenticationState.user === undefined) {
                    if (R.isNil(idToken)) {
                        const siren = QUERIES.siren || undefined;
                        return window.location = getAuthenticationUrl(siren);
                    }

                    await dispatch(CreateUser(idToken));
                    let companies = await dispatch(GetCompanies());

                    if (pathname !== '/') {
                        navigate(pathname + URLParams.querySearchParams());
                    } else if (R.isEmpty(companies)) {
                        navigate(`/c/new/inbox?q=company`);
                    } else {
                        navigate(`c/${companies[0].id}/inbox`);
                    }
                }
        }
        init();
        // eslint-disable-next-line
    }, []);


    if (authenticationState.isLoading.includes(LoadingsAuth.CREATING_USER) ||
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANIES)
    ) {
        return <Loading />;
    }
    if (R.isNil(authenticationState.user)) {
        return null;
    };
    if (R.isNil(companyState.companies)) {
        return null;
    };


    return (
        <Routes>
            <Route path="/c/:companyId/*" element={
                <React.Suspense fallback={<Preloader />}>
                    <Main theme={theme} setTheme={setTheme} routingRulesState={routingRulesState} memberState={memberState} invoiceState={invoiceState} authenticationState={authenticationState} companyState={companyState} connectionState={connectionState} />
                </React.Suspense>
            } />
            <Route path="*" element={<NoMatch redirect={`/c/${!R.isNil(companyState.companies[0]) ? companyState.companies[0].id : 'new'}/inbox${URLParams.querySearchParams()}`} />} />
            <Route path="/" element={<Navigate replace to={`/c/${!R.isNil(companyState.companies[0]) ? companyState.companies[0].id : 'new'}/inbox${URLParams.querySearchParams()}`} />} />
        </Routes>
    );
};