export const downloadPDF = (content, documentId) => {
    const blob = new Blob([content], { type: "octet/stream" });
    const url = window.URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.style = "display: none";
    link.download = `facture_${documentId}.pdf`;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
};