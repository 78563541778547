import { EndPoints } from '../api-config';
import * as R from 'ramda';
let SERVER_ADDR_V1 = EndPoints.backendHost;
export const SERVER_ADDR_V2 = 'http://localhost:7189';


export const getAuthenticationUrl = (state, showSignUp) => {
    var nonce = "";
    var possible = "0123456789";
    for (var i = 0; i < 12; i++) {
        nonce += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    if (EndPoints.environment === "production") {
        const hostname = window && window.location && window.location.hostname;
        return "https://accounts.securibox.eu/id/connect/authorize?client_id=0C39F110DB364E03BF94A156A7A08975&redirect_uri=" + encodeURI(window.location.protocol + "//" + hostname) + "&response_mode=fragment&response_type=token+id_token&scope=" + encodeURIComponent("openid api:od-one od-one-flow/api:invoices:read od-one-flow/api:invoices:write od-one-flow/api:webhooks:write") + "&nonce=" + nonce + (state !== undefined && state !== '' ? ('&state=' + state) : '') + (!R.isNil(showSignUp) ? '&acr_values=signup:' : '');
    } else {
        return "https://accounts.securibox.eu/id/connect/authorize?client_id=0C39F110DB364E03BF94A156A7A08975&redirect_uri=http%3A%2F%2Flocalhost:3000&response_mode=fragment&response_type=token+id_token&scope=" + encodeURIComponent("openid api:od-one od-one-flow/api:invoices:read od-one-flow/api:invoices:write od-one-flow/api:webhooks:write") + "&nonce=" + nonce + (state !== undefined && state !== '' ? ('&state=' + state) : '') + (!R.isNil(showSignUp) ? '&acr_values=signup:' : '');
        // return "https://accounts.securibox.eu/id/connect/authorize?client_id=67212cdc867f4d40b3b672678dd44a95&redirect_uri=http%3A%2F%2Flocalhost:3000&response_mode=fragment&response_type=token+id_token&scope=openid+api:pxstudio&nonce=" + nonce + (state !== undefined && state !== '' ? ('&state=' + state) : '') + (!R.isNil(showSignUp) ? '&acr_values=signup:' : '');
    }
};

export const getUsersUrl = () => `${SERVER_ADDR_V1}/api/users`;
export const getUserActivationUrl = () => `${SERVER_ADDR_V1}/api/users/activation`;