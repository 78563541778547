import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import * as Loadings from '../constants/loadings/connection';


const initState = {
    isLoading: [],
    connections: undefined,
    connection: undefined,
    isSuccess: false
};

const ConnectionReducer = createSlice({
    name: 'connection',
    initialState: { ...initState },
    reducers: {
        //#region GET CONNECTIONS
        getConnectionsRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_CONNECTIONS)) {
                state.isLoading.push(Loadings.FETCHING_CONNECTIONS);
            }
        },
        getConnectionsSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_CONNECTIONS);
            state.connections = payload;

        },
        getConnectionsSuccessPpf(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_CONNECTIONS);
            if (Array.isArray(state.connections)) {
                state.connections = [...state.connections, payload];
            } else {
                state.connections = [payload];
            }
            if (R.isNil(payload)) {
                state.connections = undefined;
            }
        },
        getConnectionsFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_CONNECTIONS);
        },
        //#endregion

        //#region GET CONNECTION
        getConnectionRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_CONNECTION)) {
                state.isLoading.push(Loadings.FETCHING_CONNECTION);
            }
        },
        getConnectionSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_CONNECTION);

        },
        getConnectionFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_CONNECTION);
        },
        //#endregion

        //#region CREATE CONNECTION
        createConnectionRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_CONNECTION)) {
                state.isLoading.push(Loadings.CREATING_CONNECTION);
            }
        },
        createConnectionSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_CONNECTION);
            if (R.isNil(state.connections) || R.isEmpty(state.connections)) {
                state.connections = [];
            }
            state.connections.push(payload);
            state.isSuccess = true;

        },
        createConnectionFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_CONNECTION);
        },

        //#endregion
        //#region DELETE CONNECTION
        deleteConnectionRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_CONNECTION)) {
                state.isLoading.push(Loadings.DELETING_CONNECTION);
            }
        },
        deleteConnectionSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_CONNECTION);
            if (!R.isNil(state.connections) && !R.isEmpty(state.connections)) {
                state.connections = state.connections.filter(c => c.id !== payload);
            }

        },
        deleteConnectionFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_CONNECTION);
        },
        //#endregion

        resetConnection(state, { payload }) {
            state.connection = undefined;
            state.connections = undefined;
            state.connection = [];
        },
    }
});

export const connectionActions = ConnectionReducer.actions;
export default ConnectionReducer.reducer;