import React from 'react';
import { NavLink } from 'react-router-dom';

export const Menu = () => {
    return (
        <nav className="navbar flex-shrink-0 col-12 col-sm-auto col-lg-3 col-xl-2 p-0 d-none d-sm-block h-lg-inherit scroll-lg-auto pe-2 mb-3 mt-lg-2 mb-lg-0">
            <h4 className="sr-only visually-hidden">settings: menu</h4>
            <div className="flex-row flex-lg-column flex-fit w-100 sticky-top z-2 gap-2">
                <button className="btn btn-warning me-auto d-none d-sm-block d-lg-none mb-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter">
                    <span className="icns material-symbols-rounded align-middle">
                        discover_tune
                    </span>
                    <span className="align-middle mx-2 lh-lg">Menu</span>
                </button>
            </div>
            <ul className="navbar-nav filter-nav flex-sm-column mb-auto w-100 collapse show-lg mt-0" id="collapseFilter" style={{ "--sbx-filter-link-color": "var(--sbx-warning)" }}>
                <li className="nav-item mb-3">
                    <button className="btn border-0 flex-row align-items-center w-100 nav-link lh-1 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#SourceOff" aria-expanded="false" aria-controls="Source">
                        <span className="align-middle fw-bold ff-aux fs-sm o-33">Account</span>
                        <span className="icns material-symbols-rounded align-middle ms-auto icn-expand">
                            expand_less
                        </span>
                    </button>
                    <ul className="navbar-nav flex-sm-column mb-auto w-100 mb-4 collapse multi-collapse mt-2 show" id="Source">
                        {/* <hr /> */}
                        <li className="nav-item">
                            <NavLink end to="overview" className="nav-link">
                                <span className="icns material-symbols-outlined align-middle">
                                    playlist_add_check
                                </span>
                                <span className="align-middle mx-2">Société</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink end to="notifications" className="nav-link">
                                <span className="icns material-symbols-outlined align-middle">
                                    notifications
                                </span>
                                <span className="align-middle mx-2">Notifications</span>
                            </NavLink>
                        </li>
                    </ul>
                </li>
                {/* <hr className="my-4 col-4 mx-auto"/> */}
                <li className="nav-item mb-3">
                    <button className="btn border-0 flex-row align-items-center w-100 nav-link lh-1 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#PreferencesOff" aria-expanded="false" aria-controls="Preferences">
                        <span className="align-middle fw-bold ff-aux fs-sm o-33">Préférences</span>
                        <span className="icns material-symbols-rounded align-middle ms-auto icn-expand">
                            expand_less
                        </span>
                    </button>
                    <ul className="navbar-nav flex-sm-column mb-auto w-100 mb-4 collapse multi-collapse mt-2 show" id="Preferences">
                        {/* <li className="nav-item">
							<NavLink end to="redirection" className="nav-link">
								<span className="icns material-symbols-rounded align-middle">memory</span>
								<span className="align-middle mx-2">Redirection</span>
							</NavLink>
						</li> */}
                        <li className="nav-item">
                            <NavLink to="connectes" className="nav-link">
                                <span className="icns material-symbols-outlined align-middle">extension</span>
                                <span className="align-middle mx-2">Sources</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink end to="members" className="nav-link">
                                <span className="icns material-symbols-outlined align-middle">schema</span>
                                <span className="align-middle mx-2">Membres</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink end to="rules" className="nav-link">
                                <span className="icns material-symbols-outlined align-middle">memory</span>
                                <span className="align-middle mx-2">Cycle de vie</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink end to="directory" className="nav-link">
                                <span className="icns material-symbols-outlined align-middle">summarize</span>
                                <span className="align-middle mx-2">Annuaire</span>
                            </NavLink>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
}

