import React, { useEffect, useRef } from 'react';
import * as R from 'ramda';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/images/brand/securibox-odOne.svg';
import { useModalToggle } from '../../hooks/useModalToggle';

export const Topbar = ({ connectionState }) => {
    const { openRef } = useModalToggle('technical');

    return (
        <nav className="navbar navbar-expand mb-0 py-0">
            <h1 className="visually-hidden sr-only">OD-One: Header</h1>
            <div className="container-fluid">
                <Link className="navbar-brand py-0 me-auto" to={`/`}>
                    <Logo width="144" alt="odOne" className="img-fluid me-2 align-middle text-inherit" />
                    <span className="align-middle sr-only visually-hidden">Business</span>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto">
                        {/* <li className="nav-item">
							<button type="button" className="btn text-bg-sbx">
								<span className="align-middle mx-2 d-none d-sm-inline-block fs-aux fw-medium">Synchroniser</span>
								<i className="icns material-symbols-outlined align-middle" aria-hidden="true">refresh</i>
							</button>
						</li> */}
                        <li className="nav-item">
                            {/* <button ref={openRef} disabled={!R.isEmpty(connectionState.connections)} type="button" className={"btn border-none p-0 link-accent p-xs" + (!R.isEmpty(connectionState.connections) ? " bg-none" : " bg-body-tertiary-hover")} data-bs-toggle="modal" data-bs-target="#helpGuide" aria-expanded="false" aria-controls="helpGuide"> */}
                            <button ref={openRef} type="button" className={"btn border-none p-0 link-accent p-xs bg-body-tertiary-hover"} data-bs-toggle="modal" data-bs-target="#helpGuide" aria-expanded="false" aria-controls="helpGuide">
                                <i className={"avatar rounded-2 icns material-symbols-outlined align-middle" + (!R.isEmpty(connectionState.connections) ? " bg-none" : " bg-stage")} title="lightbulb" aria-hidden="true">lightbulb</i>
                                <span className="align-middle mx-2 d-none d-md-inline-block text-body fs-aux fw-medium">Besoin d'aide?</span>
                            </button>
                        </li>
                        {/* <li className="nav-item" data="notify">
                            <button type="button" className="btn border-none bg-body-tertiary-hover link-gray p-xs" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNotifications" aria-controls="offcanvasNotifications">
                                <i className="avatar bg-stage rounded-2 icns material-symbols-outlined align-middle" title="notifications" aria-hidden="true">notifications</i>
                                <span className="text-body align-middle mx-2 d-none d-md-inline-block fs-aux fw-medium">Notifications</span>
                            </button>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav >
    );
};
