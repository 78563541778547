import React from 'react';
import { Link } from 'react-router-dom';

export const ToolbarFilter = () => {
	return (
		<section className="flex-column align-items-stretch flex-shrink-0 col-12 col-sm-auto">
			<h4 className="sr-only visually-hidden">Filter</h4>
			<div className="container-fluid">
				<button type="button" className="btn border-0 p-0 text-start dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
					<span className="align-middle lh-1 my-auto me-3 small text-muted">Voir:</span><br />
					<span className="align-middle my-auto me-3">Tous les fichiers</span>
				</button>
				<ul className="dropdown-menu border-0 shadow p-2" aria-labelledby="dropdownMenuButton1">
					<li><Link className="dropdown-item rounded-1" end="/files" to="/files">Tous les fichiers</Link></li>
					<li><Link className="dropdown-item rounded-1" to="charge">Documents Chargés</Link></li>
					<li><Link className="dropdown-item rounded-1" to="sync">Documents Synchros</Link></li>
				</ul>
			</div>
		</section>
	)
}