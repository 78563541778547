

export const INVOICE_TYPE = {
    receivables: "receivables",
    payables: "payables",
};
export const SOURCE_TYPE = {
    0: "PPF",
    1: "PDP",
    2: "Manual",
    3: "Collecte",
    4: "Email",
    undefined: "Indéfini",
};

export const SOURCE_TYPE_MAP = {
    "ppf": 0,
    "pdp": 1,
    "manual": 2,
    "collecte": 3,
    "email": 4,
    "undefined": 5,
};

// export const STATUS_MAP = {
//     deposee: "Deposée",
//     suspendue: "Suspendue",
//     communication: "Prise en charge",
//     approuveePartiel: "Approuvée partiellement",
//     approuvee: "Approuvée",
//     litige: "En Litige",
//     refusee: "Refusée",
//     transmis: "Paiement transmis",
// };
export const PAYMENT_TYPE_MAP = {
    30: 'Virement Bancaire',
    48: 'Carte Bancaire',
    49: 'Prélèvement',
};

export const STATUS_MAP = {
    200: 'Déposée',
    201: 'Emise par la plateforme',
    202: 'Reçue de la plateforme',
    203: 'Mise à disposition',
    204: 'Prise en charge',
    205: 'Approuvée',
    206: 'Approuvée partiellement',
    207: 'En litige',
    208: 'Suspendue',
    209: 'Complétée',
    210: 'Refusée',
    211: 'Paiement transmis',
    212: 'Encaissée',
    213: 'Rejetée',
    214: 'Visée'
};

export const SOURCE_COLOR = {
    0: "var(--sbx-info-rgb)",
    1: "var(--sbx-success-rgb)",
    2: "var(--sbx-secondary-rgb)",
    3: "var(--sbx-warning-rgb)",
    4: "255, 102, 138",
    undefined: "var(--sbx-dark-rgb)",
};

export const FIELD_NAV_TYPE = {
    0: "Número",
    1: "Fournisseur",
    2: "Date",
    3: "Date d' échéance",
    4: "Total HT",
    5: "TVA",
    6: "Total",
    7: "État",
};


export const ORDER_BY = {
    UNDEFINED: 'UNDEFINED',
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING'
};

export const SORT_BY = {
    NUMBER: 'NUMBER',
    NAME: 'NAME',
    DATE: 'DATE'
};

export const FORMAT_AMOUNT = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2
});

export const FORMAT_RATE = new Intl.NumberFormat("fr-FR", {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export const ROLES = {
    administrator: 'administrator',
    readonly: 'readonly'
};

export const MEMBER_STATUS = {
    pending: 'pending',
    disabled: 'disabled',
    verified: 'verified'
};

export const INITAL_COMPANY_DATA = {
    id: null,
    name: '',
    vatNumber: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressPostalCode: '',
    addressCity: '',
    addressCountry: 'France',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { SOURCE_TYPE, SOURCE_COLOR, ORDER_BY, SORT_BY, FORMAT_AMOUNT, FORMAT_RATE };