import React, { Component, useEffect, useState } from 'react';
import * as R from 'ramda';
import { connect, useDispatch } from 'react-redux';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import samplePDF from '../../../../../assets/images/docs/Name-Free_Facture_2023-01-01_1165978436_63D1BE5350F57B8889703DEA88D2D7E7C987A4C315F56ED6DD3CD1E107081B76.pdf';
import { useParams } from 'react-router-dom';
import { getInvoiceContentByIdUrl } from '../../../../../constants/webapi/invoice';
import { GetInvoiceContent } from '../../../../../actions/invoice-api';
import { downloadPDF } from '../../../../../constants/utils/downloadPDF';
import { toast } from 'react-toastify';
import { toastConf } from '../../../../../constants/utils/toastConfig';
import { Spinner } from '../../../../../_common/spinner';



pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PdfViewer = ({ documentId, customerId, siren }) => {
    const [numPages, setNumPages] = useState(0);
    const [documentUrl, setDocumentUrl] = useState({});
    const { companyId } = useParams();
    const [invoice, setInvoice] = useState({ isLoading: false, content: null, id: null });

    useEffect(() => {
        if (R.isNil(documentId) && documentId === 'new') return;
        const url = {
            url: getInvoiceContentByIdUrl(customerId, siren, documentId),
            httpHeaders: { "Authorization": "Bearer " + sessionStorage.getItem("access_token") },
        };
        setDocumentUrl(url);
        // eslint-disable-next-line
    }, [documentId]);

    const downloadDoc = async () => {
        setInvoice(content => ({ ...content, isLoading: true, id: documentId }));
        const isSameDocument = () => !R.isNil(invoice.id) && (invoice.id === documentId);
        try {
            let content;
            if (isSameDocument()) {
                content = invoice.content;
            } else {
                content = await GetInvoiceContent(customerId, siren, documentId);
            }
            downloadPDF(content.data, documentId);
            setInvoice({ content: content, isLoading: false, id: documentId });
        } catch (error) {
            toast.error("Tentative échouée, réessayez !", toastConf);
        } finally {
            setInvoice(invoice => ({ ...invoice, isLoading: false }));
        }
    };

    console.log(documentUrl);
    return (
        <React.Fragment>
            <div className="flex-row justify-content-between w-100 gap-3">
                <p className="text-muted my-auto ms-1 text-truncate fs-aux">{"facture_" + documentId + ".pdf"}</p>
                <div className="flex-row gap-1 ms-auto">
                    <button onClick={downloadDoc} disabled={invoice.isLoading} type="button" className="btn border-none bg-stage link-info fw-bold p-1 flex-row align-items-center gap-2">
                        <span className="d-none d-md-inline-block align-middle mx-2 fs-sm d-none d-lg-inherit">Télécharger</span>
                        <i className="icns material-symbols-outlined align-middle bg-info bg-opacity-10 p-2 p-lg-1 border-none rounded-2">download</i>

                    </button>
                    {/* <button type="button" className="btn border-none bg-stage link-secondary fw-bold p-1 flex-row align-items-center gap-2" data-bs-toggle="modal" data-bs-target="#updateInvoiceX" aria-controls="updateInvoiceX">
					<span className="d-none d-md-inline-block align-middle mx-2 fs-sm d-none d-lg-inherit">Imprimer</span>
					<i className="icns material-symbols-outlined align-middle bg-secondary bg-opacity-10 p-2 p-lg-1 border-none rounded-2">print</i>
				</button> */}
                </div>
            </div>
            <div className="flex-column w-100 flex-fill m-auto d-none d-lg-inherit mt-3 overflow-auto rounded-2" >
                <Document id="viewer" error="Erreur lors du chargement du fichier PDF." loading={"Chargement..."} className="parse-pdfViewer" file={documentUrl} onLoadSuccess={({ numPages }) => setNumPages(numPages)} >
                    {Array.apply(null, Array(numPages))
                        .map((x, i) => i + 1)
                        .map(page => <Page pageNumber={page}
                            loading={"Chargement..."}
                            key={"pdfviewer_page_" + page}
                        />)
                    }
                </Document>
            </div>
        </React.Fragment>
    );
};


export default React.memo(PdfViewer);