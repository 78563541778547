import { FORMAT_AMOUNT, FORMAT_RATE } from "./global";

export const invoiceLinesColumns = [
    {
        name: 'Identifier',
        // selector: row => row.date ? format(new Date(row.date), 'dd-MM-yyyy') : 'DD-MM-YYYY',
        selector: row => row.identifier || 'Undefined',
        sortable: true,
    },
    {
        name: 'Name',
        selector: row => row.name || 'Undefined',
        sortable: true,
    },
    // {
    //     name: 'Code Type',
    //     selector: row => row.vatCodeType || 'N/A',
    //     sortable: true,
    // },
    {
        name: 'Quantity',
        selector: row => row.quantity || 'Undefined',
        sortable: true,
    },
    {
        name: 'Unit Price',
        selector: row => FORMAT_AMOUNT.format(row.netPrice || 0),
        sortable: true,
        right: true,
        // style: { background: "var(--sbx-body-bg)", },
    },
    {
        name: 'TVA',
        selector: row => FORMAT_RATE.format((row.vatRate / 100).toFixed(1) || 0),
        sortable: true,
        right: true,
    },
    {
        name: 'Total Amount',
        selector: row => FORMAT_AMOUNT.format(row.grossPrice || 0),
        sortable: true,
        right: true,
        // style: { background: "var(--sbx-body-bg)", },
    },
];

export const TableStyles = {
    table: {
        style: {
            color: "var(--sbx-body-color)",
            backgroundColor: "none",
            display: "flex",
        },
    },
    head: {
        style: {
            color: "var(--sbx-body-color)",
            backgroundColor: "none",
        },
    },
    headRow: {
        style: {
            // border: 'none',
            minHeight: "3rem", // override the row height
            color: "inherit",
            borderBottomColor: "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-bg-opacity))",
            backgroundColor: "rgba(var(--sbx-body-bg-rgb), var(--sbx-bg-opacity))",
            '&:last-of-type': {
                borderTopLeftRadius: "var(--sbx-border-radius-sm)",
                borderTopRightRadius: "var(--sbx-border-radius-sm)",
            },
        },
    },
    headCells: {
        style: {
            fontSize: "inherit",
            paddingLeft: "8px", // override the cell padding for head cells
            paddingRight: "8px",
        },
    },
    cells: {
        style: {
            paddingLeft: ".5rem", // override the cell padding for data cells
            paddingRight: ".5rem",
        },
    },
    rows: {
        style: {
            fontSize: "0.875rem", // override the font size
            // minHeight: "72px", // override the row height
            color: "var(--sbx-body-color)",
            backgroundColor: "rgba(var(--sbx-body-bg-rgb), var(--sbx-bg-opacity))",
            "&:not(:last-of-type)": {
                borderBottomColor: "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-bg-opacity))",
            },
            // '&:first-of-type': {
            // 	borderTopLeftRadius: "var(--sbx-border-radius-sm)",
            // 	borderTopRightRadius: "var(--sbx-border-radius-sm)",
            // },
            "&:last-of-type": {
                borderBottomLeftRadius: "var(--sbx-border-radius-sm)",
                borderBottomRightRadius: "var(--sbx-border-radius-sm)",
            },
        },
        denseStyle: {
            minHeight: "2rem",
        },
        highlightOnHoverStyle: {
            backgroundColor: "rgba(var(--sbx-secondary-bg-rgb), var(--sbx-bg-opacity))",
            borderBottomColor: "rgba(var(--sbx-secondary-bg-rgb), var(--sbx-bg-opacity))",
            borderRadius: "var(--sbx-card-border-radius)",
            outline: ".0625rem solid rgba(var(--sbx-secondary-bg-rgb), var(--sbx-bg-opacity))",
        },
    },
    pagination: {
        style: {
            color: "var(--sbx-body-color)",
            backgroundColor: "none",
            border: "none",
            borderRadius: "var(--sbx-card-border-radius)",
            minHeight: "2rem"
        },
        pageButtonsStyle: {
            fill: "var(--sbx-body-color)",
            color: "var(--sbx-body-color)",
            backgroundColor: "inherit",
            "&:disabled": {
                fill: "rgba(var(--sbx-body-color-rgb), .18)",
                color: "rgba(var(--sbx-body-color-rgb), .18)",
            },
            "&:hover:not(:disabled)": {
                backgroundColor: "rgba(var(--sbx-secondary-bg-rgb), .18)",
            },
            "&:focus": {
                backgroundColor: "rgba(var(--sbx-secondary-bg-rgb), var(--sbx-bg-opacity))",
            },
        },
    },
    progress: {
        style: {
            color: "var(--sbx-body-color)",
            backgroundColor: "var(--sbx-body-bg)",
        },
    },
};