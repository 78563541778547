import React from 'react';
import * as R from 'ramda';
import { NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as Avatar } from '../../assets/images/placeholders/app/avatar.svg';
import { themeOptions } from '../../constants/utils/themeUtils';

export const Navbar = ({ connectionState, companyState, theme, setTheme }) => {
    let { pathname } = useLocation();
    const toggleTheme = theme === themeOptions.LIGHT ? themeOptions.DARK : themeOptions.LIGHT;
    let companyId = 'new';

    if ((!R.isNil(companyState.companies) && !R.isEmpty(companyState.companies)) || !R.isNil(companyState.company)) {
        companyId = companyState.company?.id || companyState.companies[0].id;
    }

    const switchTheme = () => {
        const storageTheme = localStorage.getItem("theme");
        const mapper = {
            'dark': () => localStorage.setItem("theme", themeOptions.LIGHT),
            'light': () => localStorage.setItem("theme", themeOptions.DARK),
        };
        mapper[storageTheme]();
        setTheme(toggleTheme);
    };


    return (

        <nav className="navbar card rounded-2 border-0 flex-row flex-sm-column flex-shrink-0 p-1 order-last order-sm-first">
            <h4 className="sr-only visually-hidden">App: navigation</h4>
            <ul className="nav nav-pills nav-flush flex-sm-column mb-auto" style={{ "--sbx-nav-link-disabled-color": "var(--sbx-gray-200)", "--sbx-nav-link-color": "var(--sbx-gray-400)" }}>
                {/* <li className="nav-item">
                    <NavLink to="/dashboard" className="nav-link lh-1 p-3 disabled">
                        <span className="icns material-symbols-rounded align-middle">
                            track_changes
                        </span>
                        <span className="visually-hidden align-middle mx-2">Dashboard</span>
                    </NavLink>
                </li> */}
                <li className="nav-item" style={{ "--sbx-nav-pills-link-active-bg": "var(--sbx-secondary)", "--sbx-nav-link-hover-color": "var(--sbx-secondary)" }}>
                    <NavLink to={`/c/${companyId}/inbox`} type="button" className={"nav-link lh-1 p-3" + (pathname.includes("inbox") ? " active" : "")}>
                        <span className="icns material-symbols-outlined align-middle">
                            {/* checklist */}
                            {/* auto_fix */}
                            {/* account_balance_wallet */}
                            {/* magic_exchange */}
                            {/* format_image_left */}
                            {/* rule */}
                            {/* event_note */}
                            {/* layers */}
                            {/* read_more */}
                            format_image_left
                            {/* upcoming */}
                        </span>
                        <span className="visually-hidden align-middle mx-2">Account Payables</span>
                        {connectionState.isSuccess &&
                            <div className="spinner spinner-blink text-primary" role="status" data-bs-toggle="tooltip" data-bs-placement="top" title="You have new invoices!">
                                <span className="visually-hidden">Alert</span>
                            </div>}

                    </NavLink>
                </li>
                <li className="nav-item" style={{ "--sbx-nav-pills-link-active-bg": "rgba(var(--sbx-accent-rgb), .8", "--sbx-nav-link-hover-color": "var(--sbx-accent)" }}>
                    <NavLink to={`/c/${companyId}/accountReceivables`} className="nav-link lh-1 p-3">
                        <span className="icns material-symbols-outlined align-middle">
                            {/* payments */}
                            {/* checklist */}
                            {/* export_notes */}
                            {/* assignment */}
                            {/* grading */}
                            {/* point_of_sale */}
                            cast
                        </span>
                        <span className="visually-hidden align-middle mx-2">Account Receivables</span>
                    </NavLink>
                </li>
                {/* <li className="nav-item" style={{ "--sbx-nav-pills-link-active-bg": "var(--sbx-info)","--sbx-nav-link-hover-color": "var(--sbx-info)"}}>
                        <NavLink to="agents/myagents" type="button" className={"nav-link lh-1 p-3" + (pathname.includes("agents") ? " active" : "")}>
                            <span className="icns material-symbols-rounded align-middle">
                                link
                            </span>
                            <span className="visually-hidden align-middle mx-2">Agents</span>
                        </NavLink>
                    </li> */}
                {/* <li className="nav-item" style={{ "--sbx-nav-pills-link-active-bg": "var(--sbx-success)", "--sbx-nav-link-hover-color": "var(--sbx-success)" }}>
                        <NavLink to={`/${companyId }/files`} className="nav-link lh-1 p-3">
                            <span className="icns material-symbols-outlined align-middle">
                                inventory_2
                            </span>
                            <span className="visually-hidden align-middle mx-2">Folder</span>
                        </NavLink>
                    </li> */}
            </ul>
            <ul className="nav nav-flush flex-sm-column mt-sm-auto gap-sm-1" style={{ "--sbx-nav-link-disabled-color": "var(--sbx-gray-200)", "--sbx-nav-link-color": "var(--sbx-gray-400)" }}>
                <li className="nav-item order-sm-last position-relative">
                    <button className="btn bg-body-tertiary link-success z-1 fs-md lh-1 p-0 ms-n2 mb-n2 rounded-pill mx-auto align-items-center position-absolute bottom-0 start-0 border border-solid border-body shadow-outline border-3" type="button" onClick={switchTheme}>
                        <span className="sr-only">theme</span>
                        <i className="icons material-symbols-rounded fs-inherit avatar-1">{toggleTheme}_mode</i>
                    </button>
                    <button className="btn nav-link lh-1 p-2 mx-auto align-items-center border-none" type="button" data-bs-toggle="modal" data-bs-target="#sbxAuth" aria-controls="sbxAuth">
                        <Avatar height={32} width={32} alt="avatar" className="img-fluid obj-fit align-middle text-body o-88-hover" />
                        {/* <span className="icns material-symbols-rounded align-middle">
                                account_circle
                            </span> */}
                        <span className="visually-hidden align-middle mx-2">Account</span>
                    </button>
                </li>
                <li className="nav-item nav-pills flex-row align-items-center">
                    <NavLink to={`/c/${companyId}/settings`} className="nav-link lh-1 p-3 d-none d-sm-flex" style={{ "--sbx-nav-pills-link-active-bg": "var(--sbx-warning)", "--sbx-nav-link-hover-color": "var(--sbx-warning)" }}>
                        <span className="icns material-symbols-rounded align-middle">
                            tune
                        </span>
                        <span className="visually-hidden align-middle mx-2">Settings</span>
                    </NavLink>
                    <button className="nav-link no-sm-event d-sm-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbarExtended" aria-controls="offcanvasNavbarExtended">
                        <span className="icns material-symbols-rounded align-middle">
                            menu
                        </span>
                        <span className="visually-hidden align-middle mx-2">Menu</span>
                    </button>
                </li>
            </ul>
            <div className="offcanvas offcanvas-end shadow-lg" data-bs-scroll="true" tabIndex={-1} id="offcanvasNavbarExtended" aria-labelledby="offcanvasNavbarExtendedLabel">
                <div className="offcanvas-header bg-stage">
                    <h5 className="offcanvas-title" id="offcanvasNavbarExtendedLabel">Menu</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body list-unstyled">
                    <li><NavLink className="dropdown-item d-sm-none fs-lg mb-2" to={`/c/${companyId}/settings`}>Settings</NavLink></li>
                    <ul className="navbar-nav filter-nav flex-sm-column mb-auto w-100" id="collapseMenu" style={{ "--sbx-filter-link-color": "var(--sbx-warning)", "--sbx-filter-link-bg": "rgba(var(--sbx-warning-rgb), .2)" }}>
                        <li className="nav-item mb-3">
                            <button className="btn border-0 flex-row align-items-center w-100 nav-link lh-1 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#SourceOff" aria-expanded="false" aria-controls="Source">
                                <span className="align-middle fw-bold ff-aux fs-sm o-33">Account</span>
                                <span className="icns material-symbols-rounded align-middle ms-auto icn-expand">
                                    expand_less
                                </span>
                            </button>
                            <ul className="navbar-nav flex-sm-column mb-auto w-100 mb-4 collapse multi-collapse mt-2 show" id="Source">
                                {/* <hr /> */}
                                <li className="nav-item">
                                    <NavLink end to={`/c/${companyId}/settings/overview`} className="nav-link">
                                        <span className="icns material-symbols-outlined align-middle">
                                            playlist_add_check
                                        </span>
                                        <span className="align-middle mx-2">Overview</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink end to={`/c/${companyId}/settings/notifications`} className="nav-link">
                                        <span className="icns material-symbols-outlined align-middle">
                                            notifications
                                        </span>
                                        <span className="align-middle mx-2">Notifications</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        {/* <hr className="my-4 col-4 mx-auto"/> */}
                        <li className="nav-item mb-3">
                            <button className="btn border-0 flex-row align-items-center w-100 nav-link lh-1 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#PreferencesOff" aria-expanded="false" aria-controls="Preferences">
                                <span className="align-middle fw-bold ff-aux fs-sm o-33">Préférences</span>
                                <span className="icns material-symbols-rounded align-middle ms-auto icn-expand">
                                    expand_less
                                </span>
                            </button>
                            <ul className="navbar-nav flex-sm-column mb-auto w-100 mb-4 collapse multi-collapse mt-2 show" id="Preferences">
                                <li className="nav-item">
                                    <NavLink end to={`/c/${companyId}/settings/connectes`} className="nav-link">
                                        <span className="icns material-symbols-outlined align-middle">extension</span>
                                        <span className="align-middle mx-2">Connectés</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink end to={`/c/${companyId}/settings/members`} className="nav-link">
                                        <span className="icns material-symbols-outlined align-middle">schema</span>
                                        <span className="align-middle mx-2">Members</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink end to={`/c/${companyId}/settings/rules`} className="nav-link">
                                        <span className="icns material-symbols-rounded align-middle">memory</span>
                                        <span className="align-middle mx-2">Cycle de vie</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

