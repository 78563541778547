import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

// Components
// ==========================================
// Components: Common
import { members } from '../../../../constants/utils/data';
import { ModalMember } from '../../_common/modalMember';
import { ModalDelete } from '../../_common/modalDelete';

// Components: View
import { Empty } from '../empty';
import { MainMembers } from './main';
import { GetMembers } from '../../../../actions/member-api';
import * as Loadings from '../../../../constants/loadings/member';
import { Loading } from '../../../../_common/loading';


export const Members = ({ memberState, authenticationState, companyId }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetMembers(companyId));
        // eslint-disable-next-line 
    }, [companyId]);

    if (memberState.isLoading.includes(Loadings.FETCHING_MEMBERS)) {
        return <Loading />;
    }


    return <MainMembers memberState={memberState} authenticationState={authenticationState} companyId={companyId} />;
};