import React from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";

import DataTable from 'react-data-table-component';
import { format } from 'date-fns';
import * as R from 'ramda';
import { URLParams } from '../../../../../../constants/utils/urlParams';
import { FORMAT_AMOUNT, FORMAT_RATE, PAYMENT_TYPE_MAP, STATUS_MAP } from "../../../../../../constants/utils/global";

// Components: Views
import { NoMatch } from "../../../../../../_common/no-match";
import { TableStyles } from '../../../../../../constants/utils/table';

export const Donnes = ({ invoiceState, item, setItem, doc, setDoc }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { companyId } = useParams();

    const invoiceLinesData = item.invoiceLines || [];
    //#region INVOICE COLUMNS 
    const invoiceLinesColumns = [
        {
            name: 'Identifier',
            selector: row => row.id || 'Undefined',
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.objectIdentifier || 'Undefined',
            sortable: true,
        },
        {
            name: 'Quantity',
            selector: row => row.quantity || 'Undefined',
            sortable: true,
        },
        {
            name: 'Unit Price',
            selector: row => FORMAT_AMOUNT.format(row.priceDetail.netPrice || 0),
            sortable: true,
            right: true,
        },
        {
            name: 'TVA',
            selector: row => FORMAT_RATE.format((row.vatInformation.vatRate / 100).toFixed(1) || 0),
            sortable: true,
            right: true,
        },
        {
            name: 'Total Amount',
            selector: row => FORMAT_AMOUNT.format(row.netAmount || 0),
            sortable: true,
            right: true,
        },
    ];
    //#endregion


    return (
        R.isNil(item) || R.isEmpty(item) ?
            <div className="offcanvas-body p-0 flex-lg-row gap-3">
                <NoMatch redirect={`/${companyId}/inbox`} dismiss="offcanvas" />
            </div>
            :
            <React.Fragment>
                <div className="flex-column gap-2 mt-sm">
                    <div className="card">
                        <div className="card-header flex-row justify-content-between gap-3 py-3 px-4 border-body-secondary">
                            <span className="material-symbols-outlined link-info">badge</span>
                            <h5 className="ff-base fs-5 my-0 ls-n1 text-body me-auto">Identification de la facture</h5>
                        </div>
                        <div className="card-body p-4 flex-column gap-4">
                            <div className="col flex-column gap-2">
                                <h6 className="ff-base fs-sm ls-2 fw-bold text-uppercase mt-0 mb-0">Fournisseur</h6>
                                <div className="row g-3 align-items-baseline">
                                    <div className="col">
                                        <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">Nom</p>
                                        {item.seller?.legalName ?
                                            <p className="my-0">{item?.seller?.legalName}</p>
                                            :
                                            <p className="my-0 o-22">Inconnu</p>
                                        }
                                    </div>
                                    {/* </div>
								<div className="row g-3 align-items-baseline"> */}
                                    <div className="col">
                                        <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">SIREN</p>
                                        {item.seller?.sirenNumber ?
                                            <p className="my-0">{item.seller.sirenNumber}</p>
                                            :
                                            <p className="my-0 o-22 ls-n1">- - - - - - - - -</p>
                                        }
                                    </div>
                                    <div className="col-12 col-xxl">
                                        <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">TVA Number</p>
                                        {item.seller?.vatIdentifier ?
                                            <p className="my-0">{item.seller.vatIdentifier}</p>
                                            :
                                            <p className="my-0 o-22 ls-n1">FR - - - - - - - - - - -</p>
                                        }
                                    </div>
                                    {/* </div>
								<div className="row g-3 align-items-baseline"> */}
                                    {/* <div className="col">
										<p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">Mode</p>
										{item.supplier?.mode ?
											<p className="my-0">{item.supplier.mode}</p>
											:
											<p className="my-0 o-22 ls-n1">N/A</p>
										}
									</div> */}
                                </div>
                            </div>
                            {/* <hr className="border-body-secondary o-50" /> */}
                            <div className="col flex-column gap-2 mt-2">
                                <h6 className="ff-base fs-sm ls-2 fw-bold text-uppercase mt-0 mb-0">Client</h6>
                                <div className="row g-3 align-items-baseline">
                                    <div className="col">
                                        <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">Nom</p>
                                        {item.buyer?.legalName ?
                                            <p className="my-0">{item.buyer.legalName}</p>
                                            :
                                            <p className="my-0 o-22">Inconnu</p>
                                        }
                                    </div>
                                    <div className="col">
                                        <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">SIREN</p>
                                        {item.buyer?.sirenNumber ?
                                            <p className="my-0">{item.buyer.sirenNumber}</p>
                                            :
                                            <p className="my-0 o-22 ls-n1">- - - - - - - - -</p>
                                        }
                                    </div>
                                    <div className="col-12 col-xxl">
                                        <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">TVA Number</p>
                                        {item.buyer?.vatIdentifier ?
                                            <p className="my-0">{item.buyer.vatIdentifier}</p>
                                            :
                                            <p className="my-0 o-22 ls-n1">FR - - - - - - - - - - -</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card flex-column gap-2">
                        <div className="card-header flex-row justify-content-between align-items-center gap-3 py-3 px-4 border-body-secondary">
                            <span className="material-symbols-outlined link-success">calculate</span>
                            <h5 className="ff-base fs-5 my-0 ls-n1 text-body me-auto">Données de la facture</h5>
                            <button className="btn border-none p-0 o-44 o-100-hover" type="button" data-bs-toggle="modal" data-bs-target="#statusHistory" aria-controls="statusHistory">
                                <span className="d-none d-md-inline-block align-middle mx-2">Historique des statuts</span>
                                <i className="icns material-symbols-outlined align-middle fs.lg">history</i>
                            </button>
                        </div>
                        <div className="card-body p-4 flex-column gap-3 mt-n2">
                            <div className="row g-3 align-items-baseline">
                                <div className="col-4">
                                    <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">Facture nº</p>
                                    {item.invoiceNumber ?
                                        <p className="my-0">{item.invoiceNumber}</p>
                                        :
                                        <p className="my-0 o-22 ls-n1">####</p>
                                    }
                                </div>
                                <div className="col">
                                    <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">Mode de paiement</p>
                                    {item.paymentIntruction ?
                                        <p className="my-0">{PAYMENT_TYPE_MAP[item.paymentIntruction.paymentTypeCode]}</p>
                                        :
                                        <p className="my-0 o-22 ls-n1">Inconnu</p>
                                    }
                                </div>
                            </div>
                            <div className="row g-3  align-items-baseline">
                                <div className="col">
                                    <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">Date d'Paiement</p>
                                    {item.paymentDate && item.paymentDate !== "0001-01-01T00:00:00" ?
                                        <p className="my-0">{format(new Date(item.paymentDate), 'dd-MM-yyyy')}</p>
                                        :
                                        <p className="my-0 o-22 ls-n1">DD-MM-YYYY</p>
                                    }
                                </div>
                                <div className="col">
                                    <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0"><span className="d-none d-lg-inline-block">Date d’</span>Émission</p>
                                    {item.emissionDate ?
                                        <p className="my-0">{format(new Date(item.emissionDate), 'dd-MM-yyyy')}</p>
                                        :
                                        <p className="my-0 o-22 ls-n1">DD-MM-YYYY</p>
                                    }
                                </div>
                                <div className="col">
                                    <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0"><span className="d-none d-lg-inline-block">Date d’</span>échéance</p>
                                    {item.invoiceDueDate ?
                                        <p className="my-0">{format(new Date(item.invoiceDueDate), 'dd-MM-yyyy')}</p>
                                        :
                                        <p className="my-0 o-22 ls-n1">DD-MM-YYYY</p>
                                    }
                                </div>
                            </div>
                            <div className="row g-3 align-items-baseline">
                                <div className="col-4">
                                    <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">Statut</p>
                                    {!R.isNil(invoiceState.history) && !R.isEmpty(invoiceState.history) ?
                                        <p className="my-0">{STATUS_MAP[invoiceState?.history[0]?.statusCode]}</p>
                                        :
                                        <p className="my-0 o-22 ls-n1">Inconnu</p>
                                    }
                                </div>
                                <div className="col">
                                    <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">Date de modification de l'historique des statuts</p>
                                    {!R.isNil(invoiceState.history) && !R.isEmpty(invoiceState.history) && invoiceState.history[0]?.date !== "0001-01-01T00:00:00" ?
                                        <p className="my-0">{format(new Date(invoiceState.history[0]?.date), 'dd-MM-yyyy, HH:mm:ss')}</p>
                                        :
                                        <p className="my-0 o-22 ls-n1">DD-MM-YYYY</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card bg-body-tertiary bg-opacity-75 flex-column gap-2 m-1 mt-n1">
                            <div className="card-header flex-row justify-content-between gap-3 py-3 px-4 border-body-secondary">
                                {/* <span className="material-symbols-outlined link-success">calculate</span>
								<h5 className="ff-base fs-5 my-0 ls-n1 text-body me-auto">Lignes de facturation</h5> */}
                                <h5 className="ff-base fs-sm ls-2 fw-bold text-uppercase mt-0 mb-0">Lignes de facturation</h5>
                            </div>
                            <div className="card-body flex-column gap-3 mt-n2">
                                <DataTable columns={invoiceLinesColumns} data={invoiceLinesData} defaultSortFieldID={1} selectableRows fixedHeader customStyles={TableStyles} highlightOnHoverpointerOnHover noDataComponent={<span className="p-3 fs-aux">Il n'y a pas de lignes de facturation!</span>} />
                            </div>
                        </div>
                        <div className="card-body p-4 flex-column gap-3 mt-n2">
                            <div className="row g-3 align-items-baseline">
                                <div className="col">
                                    <div className="row row-cols-1 row-cols-lg-4 gx-lg-3 align-items-baseline">
                                        <div className="col">
                                            <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">Taux TVA</p>
                                            {item.taxDistributions ?
                                                item.taxDistributions.map((row, i) => (
                                                    <p key={"taux-tva-" + i} className="my-0">{FORMAT_RATE.format((row.vatPercentage / 100).toFixed(1) || 0)}</p>
                                                ))
                                                :
                                                <p className="my-0 o-22 ls-n1">%%</p>
                                            }
                                        </div>
                                        <div className="col">
                                            <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">Prix HT</p>
                                            {item.taxDistributions ?
                                                item.taxDistributions.map((row, i) => (
                                                    <p key={"prix-ht-" + i} className="my-0">{FORMAT_AMOUNT.format((row.taxBase) || 0)}</p>
                                                ))
                                                :
                                                <p className="my-0 o-22 ls-n1">Inconnu</p>
                                            }
                                        </div>
                                        <div className="col">
                                            <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">TVA</p>
                                            {item.taxDistributions ?
                                                item.taxDistributions.map((row, i) => (
                                                    <p key={"tva-" + i} className="my-0">{FORMAT_AMOUNT.format(row.vatAmountPerVatType || 0)}</p>
                                                ))
                                                :
                                                <p className="my-0 o-22 ls-n1">Inconnu</p>
                                            }
                                        </div>
                                        <div className="col">
                                            <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">Montant total</p>
                                            {item.taxDistributions ?
                                                item.taxDistributions.map((row, i) => (
                                                    <p key={"mt-" + i} className="my-0">{FORMAT_AMOUNT.format(row.taxBase + row.vatAmountPerVatType || 0)}</p>
                                                ))
                                                :
                                                <p className="my-0 o-22 ls-n1">Inconnu</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="row row-cols-1 row-cols-lg-2 gx-lg-3 align-items-baseline">
                                        <div className="col">
                                            <p className="smallcaps ff-aux fw-medium fs-md m-0">Montant Total HT</p>
                                        </div>
                                        <div className="col text-end">
                                            <p className="my-0">{FORMAT_AMOUNT.format(item?.total?.netAmountsSum || 0)}</p>
                                        </div>
                                        <div className="col">
                                            <p className="smallcaps ff-aux fw-medium fs-md m-0">Remise HT</p>
                                        </div>
                                        <div className="col text-end">
                                            <p className="my-0">{FORMAT_AMOUNT.format(item.discountsSum || 0)}</p>
                                        </div>
                                        <div className="col">
                                            <p className="smallcaps ff-aux fw-medium fs-md m-0">Total Net HT</p>
                                        </div>
                                        <div className="col text-end">
                                            <p className="my-0">{FORMAT_AMOUNT.format(item?.total?.totalAmountWithoutVat || 0)}</p>
                                        </div>
                                        <div className="col">
                                            <p className="smallcaps ff-aux fw-medium fs-md m-0">Total TVA</p>
                                        </div>
                                        <div className="col text-end">
                                            <p className="my-0">{FORMAT_AMOUNT.format(item?.total?.totalVatAmount || 0)}</p>
                                            {/* {item.totalAmountVat ?
                                                <p className="my-0">{FORMAT_AMOUNT.format(item.totalAmountVat || 0)}</p>
                                                :
                                                <p className="my-0 o-22 ls-n1">Inconnu</p>
                                            } */}
                                        </div>
                                    </div>
                                    <hr className="my-2 o-100 border-2" />
                                    <div className="row row-cols-1 row-cols-lg-2 gx-lg-3 align-items-baseline">
                                        <div className="col">
                                            <p className="smallcaps ff-aux fw-medium fs-aux m-0">Montant Total TTC</p>
                                        </div>
                                        <div className="col text-end">
                                            <p className="my-0">{FORMAT_AMOUNT.format(item?.total?.totalAmountVatIncluded || 0)}</p>
                                        </div>
                                    </div>
                                    <hr className="my-2 o-100 border-2" />
                                    <div className="row row-cols-1 row-cols-lg-2 gx-lg-3 align-items-baseline">
                                        <div className="col">
                                            <p className="smallcaps ff-aux fw-medium fs-md m-0">Montant déjà versé</p>
                                        </div>
                                        <div className="col text-end">
                                            <p className="my-0">{FORMAT_AMOUNT.format(item?.total?.amountPaid || 0)}</p>
                                        </div>
                                        <div className="col">
                                            <p className="smallcaps ff-aux fw-medium fs-md m-0">Reste à payer</p>
                                        </div>
                                        <div className="col text-end">
                                            <p className="my-0">{FORMAT_AMOUNT.format(item?.total?.amountToBePaid || 0)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex-column gap-2">
                        <h5 className="ff-base fs-5 mt-0 ls-n1 text-body"> Complément d'information</h5>
                        <div className="flex-column gap-3">
                            <div className="row g-3 align-items-baseline">
                                <div className="col">
                                    <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">Categorie</p>
                                    {item.cat ?
                                        <p className="my-0">{item.cat}</p>
                                        :
                                        <p className="my-0 o-22 ls-n1">Undefined</p>
                                    }
                                </div>
                                <div className="col-12">
                                    <p className="smallcaps ff-aux fw-demi o-33 fs-sm m-0">Notes</p>
                                    {item.notes ?
                                        <p className="my-0">{item.notes}</p>
                                        :
                                        <p className="my-0 o-22 ls-n1">No notes</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                {!R.isNil(doc) && pathname.endsWith("new") ?
                    <div className="flex-row gap-2 ms-auto">
                        <button type="button" className="btn border-none bg-faded bg-opacity-25 bg-opacity-100-hover link-gray fs-sm fw-bold p-2" onClick={(e) => {
                            navigate(`/${companyId}/inbox` + URLParams.querySearchParams(), "offcanvas"); setDoc(undefined);
                        }}>
                            {/* <span className="d-none d-md-inline-block align-middle ms-2">Voir</span> */}
                            <span className="mx-2 align-middle">Annuler</span>
                        </button>
                        <button type="button" className="btn border-none bg-add bg-success-hover text-success text-white-hover fw-bold p-1 flex-row align-items-center gap-2">
                            <span className="d-none d-md-inline-block align-middle mx-3 fs-sm">Enregistrer modifications</span>
                        </button>
                    </div>
                    :
                    ""
                }
            </React.Fragment>
    );
};

