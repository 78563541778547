import React from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { DeleteMember, UpdateMember } from '../../../../../../actions/member-api';
import * as Loadings from '../../../../../../constants/loadings/member';

export const ItemMember = ({ memberState, member, setSelectedMember }) => {
    const dispatch = useDispatch();
    const MEMER_STATUS = {
        verified: 'Verified',
        disabled: 'Disabled',
        pending: 'Pending'
    };
    member = { ...member, verified: true, active: true };
    return (
        <li className={"card list-group-item flex-column rounded-2 p-2 gap-2 border-body-tertiary" + (member.active === true ? '' : ' bg-body bg-opacity-50')} >
            <div className="row gy-2 gx-3 align-items-center">
                <div className="col-6">
                    <div className="row gx-2 align-items-center">
                        <div className="col-auto">
                            {/* <!-- Avatar --> */}
                            <div className={"alert avatar m-auto text-center p-0 fs-6 fw-demi" + (member.status === MEMER_STATUS.verified ? ' text-info bg-info bg-opacity-10' : member.status === MEMER_STATUS.pending ? ' text-warning bg-warning bg-opacity-10' : ' alert-gray')}>
                                {/* {member.firstName.charAt(0).toUpperCase()}{member.lastName.charAt(0).toUpperCase()} */}
                                {member.name.split(' ')[0].charAt(0).toUpperCase()}{member.name.split(' ')[1].charAt(0).toUpperCase()}
                            </div>
                        </div>
                        <div className="col-7 col-sm">
                            {/* <!-- Heading --> */}
                            <p className="fs-root lh-title text-truncate my-xs">
                                {R.isNil(member.name) ? 'N/A' : `${member.name}`}
                            </p>
                            {/* <!-- Text --> */}
                            <a className="d-block o-44 lh-title fs-md text-truncate text-inherit" href={"mailto:" + member.email}>
                                {member.email}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col text-truncate">
                    {/* <!-- Text --> */}
                    {/* <div className="d-flex align-items-center">
                        <i className={"icns material-symbols-outlined fs-root me-1" + (member.status === MEMER_STATUS.verified ? ' text-info' : (member.verified === false && member.active === false ? ' text-warning' : ' text-gray'))}>
                            {member.verified === true && member.active === true ? "task_alt" : (member.verified === false && member.active === false ? "hourglass_empty" : "visibility_off")}
                        </i>
                        <span className="d-block o-66 lh-title fs-md text-truncate">
                            {member.verified === true && member.active === true ? 'Verified' : (member.verified === false && member.active === false ? 'Pending' : 'Disabled')}
                        </span>
                    </div> */}
                    <div className="d-flex align-items-center">
                        {
                            member.status === MEMER_STATUS.verified ?
                                <>
                                    <i className={"icns material-symbols-outlined fs-root me-1 text-info"}>task_alt</i>
                                    <span className="d-block o-66 lh-title fs-md text-truncate">
                                        Actif
                                    </span>
                                </>
                                :
                                member.status === MEMER_STATUS.pending ?
                                    <>
                                        <i className={"icns material-symbols-outlined fs-root me-1 text-warning"}>hourglass_empty</i>
                                        <span className="d-block o-66 lh-title fs-md text-truncate">
                                            En attente
                                        </span>
                                    </>
                                    :
                                    <>
                                        <i className={"icns material-symbols-outlined fs-root me-1 text-gray"}>visibility_off</i>
                                        <span className="d-block o-66 lh-title fs-md text-truncate">
                                            Désactivé
                                        </span>
                                    </>
                        }
                    </div>

                    {/* <!-- Heading --> */}
                    <p className="fs-aux mb-0">
                        {member.role === 'Administrator' ? 'Administrateur' : 'Consultation'}
                    </p>
                </div>
                <div className="col-auto ms-auto">
                    <div className="dropdown">
                        <button type="button" className="btn border-none bg-stage bg-opacity-10 bg-opacity-100-hover text-gray fw-bold text-uppercase fs-sm p-1" data-bs-toggle="dropdown">
                            <i className="icns material-symbols-rounded align-middle">more_vert</i>
                        </button>
                        <div className="dropdown-menu border-0 shadow p-2">
                            <button className="dropdown-item rounded-2" type="button" data-bs-toggle="modal" data-bs-target="#modalMember"
                                onClick={(e) => {
                                    setSelectedMember(member);
                                }}>
                                Modifier membre
                            </button>
                            {member.status === 'Disabled' ?
                                <button disabled={memberState.isLoading.includes(Loadings.UPDATING_MEMBER)} className="dropdown-item rounded-2" type="button" data-bs-toggle="modal" data-target="#memberDisable" onClick={(event) => { dispatch(UpdateMember({ ...member, status: 'Disabled' })); }}>Activer membre</button>
                                :
                                <button disabled={memberState.isLoading.includes(Loadings.UPDATING_MEMBER)} className="dropdown-item rounded-2" type="button" onClick={(event) => { dispatch(UpdateMember({ ...member, status: 'Verified' })); }}>Désactiver membre</button>
                            }
                            <button disabled={memberState.isLoading.includes(Loadings.DELETING_MEMBER)} className="btn w-100 text-danger bg-danger bg-opacity-10  bg-opacity-100-hover text-white-hover ff-base mt-1 fs-md" type="submit" data-bs-toggle="modal" data-bs-target="#modalDelete"
                                onClick={(e) => {
                                    setSelectedMember(member);
                                }}>
                                Supprimer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
};
