import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import * as Loadings from '../constants/loadings/routingRules';


const initState = {
    isLoading: [],
    routingRules: undefined,
    isSuccess: false
};

const RoutingRulesReducer = createSlice({
    name: 'routingrules',
    initialState: { ...initState },
    reducers: {
        //#region GET CONNECTIONS
        getRoutingRulesRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_ROUTINGRULES)) {
                state.isLoading.push(Loadings.FETCHING_ROUTINGRULES);
            }
        },
        getRoutingRulesSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ROUTINGRULES);
            state.routingRules = payload.items;

        },
        getRoutingRulesFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_ROUTINGRULES);
        },
        //#endregion
    }
});

export const routingRulesActions = RoutingRulesReducer.actions;
export default RoutingRulesReducer.reducer;