import React from 'react';

import { ToolbarSearch } from "./search";
import { ToolbarFilter } from "./filter";

export const Toolbar = () => {
	return (
		<nav className="navbar card flex-sm-row py-3 p-sm-2 gap-3 shadow-xl border-0 sticky-top">
			<h3 className="sr-only visually-hidden">Files: Toolbar</h3>
			<ToolbarFilter />
			<ToolbarSearch />
		</nav>
	)
}