import React from 'react';
import { Routes, Route, Navigate, useParams } from "react-router-dom";


// Components
// ==========================================
// Components: Common
import { Preloader } from '../../../_common/preloader';
import { ModalPPF } from '../_common/modalPPF';
import { Empty } from "./empty";

// Components: Views
import { Overview } from "./overview";
import { Redirection } from "./redirection";
import { Rules } from "./rules";

import { Members } from "./members";
import { Connecte } from "./connecte/index";
import { Directory } from './directory';

export const View = ({ companyState, connectionState, authenticationState, memberState, routingRulesState }) => {
    const { companyId } = useParams();
    const { customerId, siren } = companyState.company || { customerId: '', siren: '' };


    return (
        <section className="view flex-fill align-items-center h-inherit scroll-auto gap-3">
            <h4 className="sr-only visually-hidden">settings: view</h4>
            <Routes>
                <Route path="overview" element={
                    <React.Suspense fallback={<Preloader />}>
                        <Overview companyState={companyState} routingRulesState={routingRulesState} authenticationState={authenticationState} customerId={customerId} siren={siren} companyId={companyId} />
                    </React.Suspense>
                } />
                <Route path="redirection" element={
                    <React.Suspense fallback={<Preloader />}>
                        <Redirection companyState={companyState} routingRulesState={routingRulesState} authenticationState={authenticationState} customerId={customerId} siren={siren} companyId={companyId} />
                    </React.Suspense>
                } />
                <Route path="rules" element={
                    <React.Suspense fallback={<Preloader />}>
                        <Rules companyState={companyState} routingRulesState={routingRulesState} authenticationState={authenticationState} customerId={customerId} siren={siren} companyId={companyId} />
                    </React.Suspense>
                } />
                <Route path="directory" element={
                    <React.Suspense fallback={<Preloader />}>
                        <Directory companyState={companyState} routingRulesState={routingRulesState} authenticationState={authenticationState} customerId={customerId} siren={siren} companyId={companyId} />
                    </React.Suspense>
                } />
                <Route path="connectes" element={
                    <React.Suspense fallback={<Preloader />}>
                        <Connecte connectionState={connectionState} companyState={companyState} routingRulesState={routingRulesState} authenticationState={authenticationState} customerId={customerId} siren={siren} companyId={companyId} />
                    </React.Suspense>
                } />
                <Route path="members" element={
                    <React.Suspense fallback={<Preloader />}>
                        <Members companyState={companyState} routingRulesState={routingRulesState} authenticationState={authenticationState} memberState={memberState} customerId={customerId} siren={siren} companyId={companyId} />
                    </React.Suspense>
                } />
                <Route path="*" element={<Empty companyId={companyId} />} />
                <Route path="/" element={
                    <Navigate replace to={`/c/${companyId}/settings/overview`} />
                } />
            </Routes>
            <ModalPPF connectionState={connectionState} siren={siren} customerId={customerId} />
        </section>
    );
};