import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { GetConnections, GetPpfConnections } from '../../../../actions/connection-api';
import { Loading } from '../../../../_common/loading';
import * as Loadings from '../../../../constants/loadings/connection';
import { MainConnecte } from './main';
import { useParams } from 'react-router';

export const Connecte = ({ connectionState, companyState, siren, customerId, authenticationState }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        // dispatch(GetConnections(customerId, siren))
        //     .then(() => dispatch(GetPpfConnections(customerId, siren)));
        dispatch(GetPpfConnections(customerId, siren));
        // eslint-disable-next-line 
    }, [customerId, siren]);

    if (connectionState.isLoading.includes(Loadings.FETCHING_CONNECTIONS)) {
        return <Loading />;
    }


    return (
        <MainConnecte connectionState={connectionState} companyState={companyState} authenticationState={authenticationState} />
    );
};
