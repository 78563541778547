import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { URLParams } from '../../../constants/utils/urlParams';
import { Today } from '../../../constants/utils/date';
import { ResetInvoice } from '../../../actions/invoice-api';

export const Menu = () => {
    const QUERIES = URLParams.queries();
    const dispatch = useDispatch();

    return (
        <nav className="navbar flex-shrink-0 col-12 col-sm-auto col-lg-3 col-xl-2 p-0 d-lg-block h-lg-inherit scroll-lg-auto pe-2 mb-3 mb-lg-0 order-sm-last">
            <h4 className="sr-only visually-hidden">inbox: menu</h4>
            <div className="flex-row flex-lg-column flex-fit w-100 sticky-top z-2 gap-2 mb-2">
                <Link end={URLParams.set({ source: "ppf" })} onClick={() => dispatch(ResetInvoice())} to="new" className="btn btn-accent me-auto">
                    <span className="icns material-symbols-rounded align-middle">
                        add
                    </span>
                    <span className="align-middle mx-2 fs-aux fw-medium">Ajouter facture</span>
                </Link>
                <button className="btn btn-accent ms-auto d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter">
                    <span className="icns material-symbols-rounded align-middle">
                        discover_tune
                    </span>
                    <span className="align-middle mx-2 lh-lg">Filtre</span>
                </button>
            </div>
            <ul className="navbar-nav filter-nav flex-sm-column mb-auto w-100 mt-4 collapse show-lg" id="collapseFilter" style={{ "--sbx-filter-link-color": "var(--sbx-accent)" }}>

                {/* <hr className="my-4 col-4 mx-auto"/> */}
                <li className="nav-item mb-2">
                    <button className="btn border-0 flex-row align-items-center w-100 nav-link lh-1 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#Etat" aria-expanded="false" aria-controls="Etat">
                        <span className="align-middle small fw-bold">Statut de la facture</span>
                        <span className="icns material-symbols-rounded align-middle ms-auto icn-expand">
                            expand_less
                        </span>
                    </button>
                    <ul className="navbar-nav flex-sm-column mb-auto w-100 mb-4 collapse multi-collapse mt-2 show" id="Etat">
                        <li className="nav-item">
                            <Link to={URLParams.delete(["status"])} className={"nav-link" + (!QUERIES.status ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">
                                    done_all
                                </span>
                                <span className="align-middle mx-2">Tout</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.set({ status: "integre" })} className={"nav-link" + (QUERIES.status === 'integre' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">autopause</span>
                                <span className="align-middle mx-2">Intégré</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.set({ status: "201" })} className={"nav-link" + (QUERIES.status === 'emise' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">done_all</span>
                                <span className="align-middle mx-2">Émise</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.set({ status: "200" })} className={"nav-link" + (QUERIES.status === 'desposee' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">pending_actions</span>
                                <span className="align-middle mx-2">Déposée</span>
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to={URLParams.set({ status: "approuveePartiel" })} className={"nav-link" + (QUERIES.status === 'approuveePartiel' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">bolt</span>
                                <span className="align-middle mx-2">Approuvée partiellement</span>
                            </Link>
                        </li> */}
                        <li className="nav-item">
                            <Link to={URLParams.set({ status: "encaissee" })} className={"nav-link" + (QUERIES.status === 'encaissee' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle fs-lg ms-1">thumb_up</span>
                                <span className="align-middle mx-2">Encaissée</span>
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to={URLParams.set({ status: "litige" })} className={"nav-link" + (QUERIES.status === 'litige' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">approval_delegation</span>
                                <span className="align-middle mx-2">En Litige</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.set({ status: "refusee" })} className={"nav-link" + (QUERIES.status === 'refusee' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">close</span>
                                <span className="align-middle mx-2">Refusée</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.set({ status: "transmis" })} className={"nav-link" + (QUERIES.status === 'transmis' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">send</span>
                                <span className="align-middle mx-2">Paiement transmis</span>
                            </Link>
                        </li> */}
                    </ul>
                </li>
                {/* <hr className="my-4 col-4 mx-auto"/> */}
                <li className="nav-item mb-2">
                    <button className="btn border-0 flex-row align-items-center w-100 nav-link lh-1 p-0 " type="button" data-bs-toggle="collapse" data-bs-target="#Trier" aria-expanded="false" aria-controls="Trier">
                        <span className="align-middle small fw-bold">Trier</span>
                        <span className="icns material-symbols-rounded align-middle ms-auto icn-expand">
                            expand_less
                        </span>
                    </button>
                    <ul className="navbar-nav flex-sm-column mb-auto w-100 mb-4  mt-2" id="Trier">
                        {/* <hr /> */}
                        <li className="nav-item">
                            <Link to={URLParams.reset({ remove: ["date"], inital: { sortBy: "name", sortDesc: "false" } })} className={"nav-link" + (QUERIES.sortBy === 'name' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">
                                    format_size
                                </span>
                                <span className="align-middle mx-2">Ordre alphabetical</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.reset({ remove: ["sortBy", "sortDesc"], inital: { date: Today } })} className={"nav-link" + (!R.isNil(QUERIES.date) ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">
                                    today
                                </span>
                                <span className="align-middle mx-2">Date d'ajout</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.reset({ remove: ["date"], inital: { sortBy: "lastSync" } })} className={"nav-link" + (QUERIES.sortBy === 'lastSync' ? ' active' : '')}>
                                <span className="icns material-symbols-outlined align-middle">
                                    refresh
                                </span>
                                <span className="align-middle mx-2">Dernière synchro</span>
                            </Link>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
}

