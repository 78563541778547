import React from 'react';
import { Link } from "react-router-dom";
import * as R from 'ramda';

export const Empty = ({ companyId, custom, children }) => {
    return (
        <div className={"container-fluid" + (!R.isNil(custom) ? custom : " col-md-6 col-lg-8 col-xxl-5 m-auto")}>
            <span className="icns material-symbols-rounded display-1 text-muted">border_clear</span>
            <h5 className="display-1 fw-bold text-warning lh-1 ff-base">Looks like <br /> it's empty.</h5>
            {!R.isNil(children) ?
                <React.Fragment>
                    {children}
                </React.Fragment>
                :
                <React.Fragment>
                    <div className="lead o-88 mb-4">
                        We can't seem to find what you're looking for. You can change your settings for your OD-One app here.
                    </div>
                    <div className="lead o-88">
                        <span className="me-2 align-middle my-2">This page is not available.</span>
                    </div>
                    <Link to={`/${companyId}/settings`} className="btn p-0 border-0 link-warning" type="submit"><span className="text-body align-middle">Go back</span><i className="icns material-symbols-rounded ms-3 align-middle">arrow_forward</i></Link>
                </React.Fragment>
            }
        </div>
    );
};