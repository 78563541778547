import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as R from 'ramda';

import { CreateUser } from '../../../actions/authentication-api';
import * as Loadings from '../../../constants/loadings/member';
import { Spinner } from '../../../_common/spinner';
import { CreateMember, UpdateMember } from '../../../actions/member-api';
import { useParams } from 'react-router-dom';
import { toastConf } from '../../../constants/utils/toastConfig';


export const ModalMember = ({ authenticationState, memberState, selectedMember, setSelectedMember }) => {
    const dispatch = useDispatch();
    const closeRef = useRef();
    const formRef = useRef();
    const { companyId } = useParams();
    const role = { Administrator: 'Administrateur', ReadOnly: 'Consultation' };

    const submitForm = async (e) => {
        e.preventDefault();
        formRef.current.classList.add('was-validated');
        if (!formRef.current.checkValidity()) return;
        const member = { ...selectedMember, companyId };
        try {
            if (R.isNil(selectedMember.id)) {
                member.status = 'Disabled';
                await dispatch(CreateMember(member));
            } else {
                await dispatch(UpdateMember(member));
            }
            closeRef.current.click();
        } catch (error) {
            toast.error("Tentative échouée, réessayez !", toastConf);
        }
    };


    return (
        <div className="modal fade" id="modalMember" tabIndex={-1} aria-labelledby="modalMemberLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content rounded-2 p-4 text-body">
                    <div className="modal-header pb-0">
                        <h1 className="modal-title fs-4 text-info fw-demi ls-n1" id="modalMemberLabel">Configurer membre</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeRef}></button>
                    </div>
                    <div className="modal-body">
                        <p className="o-77 mb-4 mt-n3">Veuillez sélectionner ci-dessous le rôle a modifier:</p>
                        <form onSubmit={submitForm} noValidate id="memberForm" ref={formRef}>
                            {/* <div className="flex-md-row gap-3 justify-content-between">
                                <div className="flex-fill my-2">
                                    <input readOnly={!R.isNil(selectedMember.id)} type="text" required value={selectedMember.firstName} onChange={(e) => { setSelectedMember(form => ({ ...form, firstName: e.target.value })); }} className={"form-control bg-body-tertiary border-body-tertiary ff-base fw-normal" + (!R.isNil(selectedMember.id) ? " no-event" : " text-body")} placeholder="First Name" />
                                </div>
                                <div className="flex-fill my-2">
                                    <input readOnly={!R.isNil(selectedMember.id)} type="text" required value={selectedMember.lastName} onChange={(e) => { setSelectedMember(form => ({ ...form, lastName: e.target.value })); }} className={"form-control bg-body-tertiary border-body-tertiary ff-base fw-normal" + (!R.isNil(selectedMember.id) ? " no-event" : " text-body")} placeholder="Last Name" />
                                </div>
                            </div> */}
                            <div className="my-2">
                                <input pattern='^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$' readOnly={!R.isNil(selectedMember.id)} type="text" required value={selectedMember.email} onChange={(e) => { setSelectedMember(form => ({ ...form, email: e.target.value })); }} className={"form-control bg-body-tertiary border-body-tertiary ff-base fw-normal" + (!R.isNil(selectedMember.id) ? " no-event" : " text-body")} placeholder="Email" />
                            </div>
                            <div className="form-group col-12">
                                <label className="smallcaps ff-aux fw-demi o-33 fs-sm mb-2" htmlFor="role">Role</label>
                                <div className="form-dropdown">
                                    <select className="form-control form-select bg-body-tertiary border-0 text-body" name="role" id="role" tabIndex={-98} value={selectedMember.role} onChange={(event) => {
                                        setSelectedMember({ ...selectedMember, role: event.target.value });
                                    }}>{Object.entries(role).map(([en, fr], index) => {
                                        return (<option key={index} value={en}>{fr}</option>);
                                    })}
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn text-muted o-66-hover fs-md fw-demi" data-bs-dismiss="modal">Annuler</button>
                        <button disabled={memberState.isLoading.includes(Loadings.CREATING_MEMBER) || memberState.isLoading.includes(Loadings.UPDATING_MEMBER)} type="submit" form='memberForm' className="btn text-info text-white-hover bg-info bg-opacity-10 bg-opacity-100-hover fs-md fw-demi px-4">
                            <span>  Enregistrer </span>
                            {(memberState.isLoading.includes(Loadings.CREATING_MEMBER) || memberState.isLoading.includes(Loadings.UPDATING_MEMBER)) && <Spinner />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

