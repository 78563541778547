import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as R from 'ramda';
import { URLParams } from '../../../../../constants/utils/urlParams';

export const ListSort = () => {
    const QUERIES = URLParams.queries();

    return (
        <nav className="card bg-none rounded-2 p-2 gap-2 d-none d-md-flex">
            <h6 className="sr-only visually-hidden">List: Sort</h6>
            <div className="row gx-2 gx-xxl-3 gx-uhd-4 align-items-center">
                <div className="col col-md-3 col-lg-4 col-xl-3 flex-row align-items-center gap-2 text-truncate">
                    <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                        <i className="icns material-symbols-rounded text-inherit o-44 fs-root">check_box_outline_blank</i>
                        <div className="flex-xl-row flex-fill align-items-center gap-xl-1 text-truncate">
                            <Link to={URLParams.set({ sortBy: "name", sortDesc: QUERIES.sortBy === "name" ? QUERIES.sortDesc === "true" ? "false" : "true" : "false" })} className={"btn btn-item border-0 smallcaps ff-aux fw-demi fs-sm p-0" + (QUERIES.sortBy === "name" ? " link-secondary" : " text-inherit o-33 o-100-hover")} type="role">
                                Fournisseur
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-lg-2 col-xl-3 col-xxl col-hd-3 align-items-center justify-content-between">
                    <button className="btn btn-item border-0 text-inherit smallcaps ff-aux fw-demi o-33 o-100-hover fs-sm p-0 d-xl-none" type="button">Detail</button>
                    <div className="row gx-2 gx-uhd-4 align-items-center justify-content-between">
                        <div className="col col-md-12 col-xl-5 col-xxl-auto col-hd w-xxl-7  align-items-start text-truncate">
                            <Link to={URLParams.set({ sortBy: "emissionDate", sortDesc: QUERIES.sortBy === "emissionDate" ? QUERIES.sortDesc === "true" ? "false" : "true" : "false" })} className="btn btn-item border-0 text-inherit text-start smallcaps ff-aux fw-demi o-33 o-100-hover fs-sm p-0 d-none d-xl-block" type="button">
                                Emission
                            </Link>
                        </div>
                        <div className="col align-items-center">
                            <Link to={URLParams.set({ sortBy: "dueDate", sortDesc: QUERIES.sortBy === "dueDate" ? QUERIES.sortDesc === "true" ? "false" : "true" : "false" })} className="btn btn-item border-0 text-inherit smallcaps text-start ff-aux fw-demi o-33 o-100-hover fs-sm p-0 d-none d-xl-block" type="button">
                                Échéance
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col col-xl-4 col-xxl-5 col-hd align-items-center justify-content-between">
                    <div className="row gx-2 align-items-center">
                        <div className="col col-md-12  col-xl-auto align-items-centertext-truncate w-xl-75 w-uhd-auto">
                            <Link to={URLParams.set({ sortBy: "invoiceIdentifier", sortDesc: QUERIES.sortBy === "number" ? QUERIES.sortDesc === "true" ? "false" : "true" : "false" })} className="btn btn-item border-0 text-inherit text-start smallcaps ff-aux fw-demi o-33 o-100-hover fs-sm p-0 d-none d-xl-block" type="button">
                                Numéro de facture #
                            </Link>
                        </div>
                        <div className="col col-lg-12 col-xl align-items-center text-truncate">
                            <div className="fs-book my-auto fw-demi text-truncate text-end text-sm-start text-xl-end">
                                <Link to={URLParams.set({ sortBy: "totalAmountVatIncluded", sortDesc: QUERIES.sortBy === "totalAmountVatIncluded" ? QUERIES.sortDesc === "true" ? "false" : "true" : "false" })} className="btn btn-item border-0 text-inherit smallcaps text-end ff-aux fw-demi o-33 o-100-hover fs-sm p-0 d-none d-xl-block ms-sm-0 ms-xl-auto" type="button">
                                    Montant TTC
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12  col-xl-2 align-items-center">
                    <button className="btn btn-item border-0 text-inherit smallcaps ff-aux fw-demi o-33 o-100-hover fs-sm p-0 d-none d-xl-block" type="button">Statut</button>
                </div>
            </div>
        </nav>
    );
};