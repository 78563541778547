import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { dataNewInvoice } from '../../../../constants/utils/data';
import { DetailPreview } from './preview/index';
import { DetailItem } from './item';


export const MainDetail = ({ companyState, invoiceState, invoice, company }) => {
    const [selectedInvoice, setSelectedInvoice] = useState(R.isNil(invoice) ? dataNewInvoice : invoice);

    useEffect(() => {
        if (R.isNil(invoice)) {
            setSelectedInvoice(dataNewInvoice);
        } else {
            setSelectedInvoice(invoice);
        }
    }, [invoice]);

    return (
        <React.Fragment>
            <DetailPreview invoiceState={invoiceState} company={company} selectedInvoice={selectedInvoice} setSelectedInvoice={setSelectedInvoice} />
            <DetailItem companyState={companyState} invoiceState={invoiceState} selectedInvoice={selectedInvoice} setSelectedInvoice={setSelectedInvoice} />
        </React.Fragment>
    );
};
