import { createSlice } from '@reduxjs/toolkit';
import * as Loadings from '../constants/loadings/example';
import { dataInvoices } from '../constants/utils/data';


const initState = {
    // isLoading: [],
    dataInvoices: undefined,
    isLoading: false,
    isError: false,
    isSuccess: false
};

const ExampleReducer = createSlice({
    name: 'example',
    initialState: { ...initState },
    reducers: {
        //#region GET EXAMPLE
        getExampleRequest(state, { payload }) {
            // if (!state.isLoading.includes(Loadings.FETCHING_EXAMPLE)) {
            //     state.isLoading.push(Loadings.FETCHING_EXAMPLE);
            // }
            state.isLoading = true;
        },
        getExampleSuccess(state, { payload }) {
            // state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_EXAMPLE);
            state.isLoading = false;
            state.dataInvoices = dataInvoices;
            state.isSuccess = true;
        },
        getExampleFailure(state, { payload }) {
            // state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_EXAMPLE);
            state.isLoading = false;
        },

        //#region GET EXAMPLE REVOKE
        getExampleRevokeRequest(state, { payload }) {
            state.isLoading = false;
        },
        getExampleRevokeSuccess(state, { payload }) {
            state.isLoading = false;
            state.dataInvoices = undefined;
            state.isSuccess = false;
        },
        getExampleRevokeFailure(state, { payload }) {
            state.isLoading = false;
        },

        //#region GET EXAMPLE REVOKE
        updateExampleStatusRequest(state, { payload }) {
            state.isLoading = false;
        },
        updateExampleStatusSuccess(state, { payload }) {
            const { invoiceId, status } = payload;
            state.dataInvoices = state.dataInvoices.map(invoice => {
                if (invoice.id === invoiceId) {
                    invoice.status = status;
                }
                return invoice;
            });
        },
        updateExampleStatusFailure(state, { payload }) {
            state.isLoading = false;
        },
        //#endregion
    }
});

export const exampleActions = ExampleReducer.actions;
export default ExampleReducer.reducer;