import React from 'react';

export const ToolbarSearch = () => {
    return (
        <section className="flex-column my-auto flex-grow-1 align-items-stretch col-12 col-sm order-first order-sm-last">
            <h4 className="sr-only visually-hidden">Search</h4>
            <div className="container-fluid">
                <form action="#" className="form">
                    <div className="input-group">
                        <div className="input-group-text bg-body border-body">
                            <span className="icns material-symbols-rounded text-body align-middle me-n2 lh-1 ms-auto">search</span>
                        </div>
                        <input type="text" className="form-control border-body ps-0" placeholder="Rechercher..." aria-label="Rechercher..." />
                        <div className="input-group-text bg-body border-body">
                            <span className="icns material-symbols-rounded align-middle me-n2 lh-1 ms-auto">expand_more</span>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
};