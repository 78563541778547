import React from 'react';
// import { Link } from 'react-router-dom';
// import * as R from 'ramda';

export const Rules = () => {

    return (
        <React.Fragment>
            <div className="flex-column w-100">
                <div className="container-fluid g-0 g-lg-3 container-copy container-xxl-screen">
					<h5 className="ff-base h5 fw-normal mb-1 mt-2 mt-xxl-3">Statut de la facture</h5>
					<div className="container-lg-wide">
						<p className="fs-aux o-44 mt-auto">Configurez ici le statut que vous souhaitez autoriser</p>
					</div>
					<form className="block mb-4 mt-3" accept-charset="UTF-8" id="RulesForm" method="post">

						<ul className="list-group list-group-flush mb-auto w-100 mb-4 collapse multi-collapse mt-2 show" id="Etat">
                        {/* <li className="card list-group-item flex-row rounded-2 p-2 gap-2">
                            <div className={"nav-link me-auto" + (!QUERIES.status ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">
                                    done_all
                                </span>
                                <span className="align-middle mx-2">Tout</span>
                            </div>
							<div className="form-check form-switch my-auto">
								<input className="form-check-input" style={{"--sbx-form-check-bg": "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-bg-opacity))"}} type="checkbox" role="switch" id="Suspendue" />
								<label className="form-check-label sr-only visually-hidden" htmlFor="Suspendue">Suspendue</label>
							</div>
                        </li> */}
                        <li className="card list-group-item flex-row rounded-2 p-2 gap-2">
                            <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                                <div className="avatar text-center mb-0 flex-shrink-0 bg-body-tertiary rounded-2"><span className="icns material-symbols-rounded align-middle">pending_actions</span></div>
                                <span className="align-middle mx-2">Deposée</span>
                            </div>
							<div className="form-check form-switch my-auto">
								<input className="form-check-input" style={{"--sbx-form-check-bg": "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-bg-opacity))"}} type="checkbox" role="switch" id="Suspendue" checked={true}/>
								<label className="form-check-label sr-only visually-hidden" htmlFor="Suspendue">Suspendue</label>
							</div>
                        </li>
                        <li className="card list-group-item flex-row rounded-2 p-2 gap-2">
                            <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                                <div className="avatar text-center mb-0 flex-shrink-0 bg-body-tertiary rounded-2"><span className="icns material-symbols-rounded align-middle">autopause</span></div>
                                <span className="align-middle mx-2">Suspendue</span>
                            </div>
							<div className="form-check form-switch my-auto">
								<input className="form-check-input" style={{"--sbx-form-check-bg": "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-bg-opacity))"}} type="checkbox" role="switch" id="Suspendue" />
								<label className="form-check-label sr-only visually-hidden" htmlFor="Suspendue">Suspendue</label>
							</div>
                        </li>
                        <li className="card list-group-item flex-row rounded-2 p-2 gap-2">
                            <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                                <div className="avatar text-center mb-0 flex-shrink-0 bg-body-tertiary rounded-2"><span className="icns material-symbols-rounded align-middle">done_all</span></div>
                                <span className="align-middle mx-2">Prise en charge</span>
                            </div>
							<div className="form-check form-switch my-auto">
								<input className="form-check-input" style={{"--sbx-form-check-bg": "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-bg-opacity))"}} type="checkbox" role="switch" id="Suspendue" />
								<label className="form-check-label sr-only visually-hidden" htmlFor="Suspendue">Suspendue</label>
							</div>
                        </li>
                        <li className="card list-group-item flex-row rounded-2 p-2 gap-2">
                            <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                                <div className="avatar text-center mb-0 flex-shrink-0 bg-body-tertiary rounded-2"><span className="icns material-symbols-rounded align-middle">bolt</span></div>
                                <span className="align-middle mx-2">Approuvée partiellement</span>
                            </div>
							<div className="form-check form-switch my-auto">
								<input className="form-check-input" style={{"--sbx-form-check-bg": "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-bg-opacity))"}} type="checkbox" role="switch" id="Suspendue" />
								<label className="form-check-label sr-only visually-hidden" htmlFor="Suspendue">Suspendue</label>
							</div>
                        </li>
                        <li className="card list-group-item flex-row rounded-2 p-2 gap-2">
                            <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                                <div className="avatar text-center mb-0 flex-shrink-0 bg-body-tertiary rounded-2"><span className="icns material-symbols-rounded align-middle fs-lg ms-1">thumb_up</span></div>
                                <span className="align-middle mx-2">Approuvée</span>
                            </div>
							<div className="form-check form-switch my-auto">
								<input className="form-check-input" style={{"--sbx-form-check-bg": "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-bg-opacity))"}} type="checkbox" role="switch" id="Suspendue" />
								<label className="form-check-label sr-only visually-hidden" htmlFor="Suspendue">Suspendue</label>
							</div>
                        </li>
                        <li className="card list-group-item flex-row rounded-2 p-2 gap-2">
                            <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                                <div className="avatar text-center mb-0 flex-shrink-0 bg-body-tertiary rounded-2"><span className="icns material-symbols-rounded align-middle">approval_delegation</span></div>
                                <span className="align-middle mx-2">En Litige</span>
                            </div>
							<div className="form-check form-switch my-auto">
								<input className="form-check-input" style={{"--sbx-form-check-bg": "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-bg-opacity))"}} type="checkbox" role="switch" id="Suspendue" />
								<label className="form-check-label sr-only visually-hidden" htmlFor="Suspendue">Suspendue</label>
							</div>
                        </li>
                        <li className="card list-group-item flex-row rounded-2 p-2 gap-2">
                            <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                                <div className="avatar text-center mb-0 flex-shrink-0 bg-body-tertiary rounded-2"><span className="icns material-symbols-rounded align-middle">close</span></div>
                                <span className="align-middle mx-2">Refusée</span>
                            </div>
							<div className="form-check form-switch my-auto">
								<input className="form-check-input" style={{"--sbx-form-check-bg": "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-bg-opacity))"}} type="checkbox" role="switch" id="Suspendue"  checked={true}/>
								<label className="form-check-label sr-only visually-hidden" htmlFor="Suspendue">Suspendue</label>
							</div>
                        </li>
                        <li className="card list-group-item flex-row rounded-2 p-2 gap-2">
                            <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                                <div className="avatar text-center mb-0 flex-shrink-0 bg-body-tertiary rounded-2"><span className="icns material-symbols-rounded align-middle">send</span></div>
                                <span className="align-middle mx-2">Paiement transmis</span>
                            </div>
							<div className="form-check form-switch my-auto">
								<input className="form-check-input" style={{"--sbx-form-check-bg": "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-bg-opacity))"}} type="checkbox" role="switch" id="Suspendue" />
								<label className="form-check-label sr-only visually-hidden" htmlFor="Suspendue">Suspendue</label>
							</div>
                        </li>
                    </ul>
					</form>
					<div className="flex-column align-items-end">
						<button type="submit" form='RulesForm' className="btn text-info text-white-hover bg-info bg-opacity-10 bg-opacity-100-hover fs-md fw-demi px-4">Enregistrer</button>
						{/* <p className="fs-aux"><b className="text-danger">*</b><span className="o-66 mx-1">Champs obligatoires</span></p> */}
					</div>
                </div>
            </div>
        </React.Fragment>
    );
};