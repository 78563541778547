import React, { useMemo } from 'react';
import * as R from 'ramda';
import { useLocation } from 'react-router-dom';
import { Empty } from './empty';
import { ViewList } from "./list";
import { Loading } from '../../../../_common/loading';
import * as Loadings from '../../../../constants/loadings/invoice';
import { URLParams } from '../../../../constants/utils/urlParams';
import { normalizer } from '../../../../constants/utils/normalizeText';


export const View = ({ invoiceState }) => {
    const QUERIES = URLParams.queries();
    const location = useLocation();

    const invoicesToDisplay = useMemo(() => {
        if (R.isNil(invoiceState.payables)) return;
        let temp = [...invoiceState.payables.map(inv => ({ ...inv, name: inv.seller.legalName }))];
        if (!R.isNil(QUERIES.searchText)) {
            temp = temp.filter(invoice => invoice.seller && normalizer(invoice.seller.legalName).includes(normalizer(QUERIES.searchText)));
        }
        if (!R.isNil(QUERIES.status)) {
            temp = temp.filter(invoice => invoice.statusHistory?.[0]?.statusCode === QUERIES.status);
        }
        if (!R.isNil(QUERIES.source)) {
            temp = temp.filter(invoice => invoice.source?.toLowerCase() === QUERIES.source);
        }
        if (!R.isNil(QUERIES.sirenId)) {
            temp = temp.filter(invoice => invoice.seller?.siren.toString() === QUERIES.sirenId);
        }
        if (!R.isNil(QUERIES.sortBy) && QUERIES.sortBy.includes('Date') && QUERIES.sortDesc === 'true') {
            temp = [...temp].sort((a, b) => a[QUERIES.sortBy] > b[QUERIES.sortBy] ? 1 : -1);
        } else if (!R.isNil(QUERIES.sortBy) && QUERIES.sortBy.includes('Date') && QUERIES.sortDesc === 'false') {
            temp = [...temp].sort((a, b) => a[QUERIES.sortBy] > b[QUERIES.sortBy] ? -1 : 1);

        } else if (!R.isNil(QUERIES.sortBy) && QUERIES.sortDesc === 'true') {
            temp = R.sortWith([R.descend(R.prop(QUERIES.sortBy))])(temp);
        } else if (!R.isNil(QUERIES.sortBy) && QUERIES.sortDesc === 'false') {
            temp = R.sortWith([R.ascend(R.prop(QUERIES.sortBy))])(temp);
        }
        return temp;
        // eslint-disable-next-line 
    }, [location.search, invoiceState.payables]);


    return (
        <section className="view flex-grow-1 gap-0 gap-xl-3 overflow-hidden">
            <h4 className="sr-only visually-hidden">Inbox: View</h4>
            {invoiceState.isLoading.includes(Loadings.FETCHING_INVOICES) ?
                <Loading />
                :
                !R.isNil(invoicesToDisplay) && !R.isEmpty(invoicesToDisplay) ?
                    <ViewList invoices={invoicesToDisplay} />
                    :
                    <Empty />
            }
        </section>
    );
};