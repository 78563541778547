import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import Select from 'react-select';
import { INVOICE_TYPE, STATUS_MAP } from '../../../../../constants/utils/global';
import { toast } from 'react-toastify';
import { toastConf } from '../../../../../constants/utils/toastConfig';
import * as Loadings from '../../../../../constants/loadings/invoice';
import { Spinner } from '../../../../../_common/spinner';
import { UpdateInvoice, UpdateInvoiceStatus } from '../../../../../actions/invoice-api';

export const ModalStatus = ({ invoiceState, item, companyState, setItem }) => {
    const dispatch = useDispatch();
    const closeRef = useRef();
    const { customerId, siren } = companyState.company;
    const LAST_STATUS = 0;
    const statusCode = invoiceState.history?.at(LAST_STATUS)?.statusCode;
    const options = Object.entries(STATUS_MAP).reduce((acc, [key, option]) => [...acc, { value: key, label: option }], []);
    const defaultValue = item ? { value: Object.entries(STATUS_MAP).find(e => e.includes(statusCode))?.at(0), label: STATUS_MAP[statusCode] } : options[0];
    const [select, setSelect] = useState(defaultValue);
    const [deposeReason, setDeposeReason] = useState('');

    useEffect(() => {
        setSelect(defaultValue);
        // eslint-disable-next-line 
    }, [invoiceState.history]);


    const submitForm = async (e) => {
        try {
            const status = {
                statusCode: select.value,
                reason: select.value === '200' && !R.isEmpty(deposeReason) ? deposeReason : select.label,
                date: new Date().toISOString()
            };
            await dispatch(UpdateInvoiceStatus(customerId, siren, item.id, status));
            if (closeRef.current) closeRef.current.click();
        } catch (error) {
            toast.error("Tentative échouée, réessayez !", toastConf);
        }
    };

    console.log(select);
    return (
        <div className="modal fade" id="updateInvoiceX" tabIndex={-1} aria-labelledby="updateInvoiceXLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content rounded-2 p-4">
                    <div className="modal-header pb-0">
                        <h1 className="modal-title fs-4 text-info fw-demi ls-n1" id="updateInvoiceXLabel">Modifier le statut de la facture</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeRef}></button>
                    </div>
                    <div className="modal-body">
                        <p className="o-77 mb-4 mt-n3 text-body">Veuillez sélectionner ci-dessous le statut à affecter à la facture:</p>
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    // borderColor: state.isFocused ? '#f4f4f9' : '#f4f4f9',
                                    borderColor: '#f4f4f9',
                                    background: '#f4f4f9',
                                }),
                            }}
                            className="basic-single"
                            classNamePrefix="select"
                            //defaultValue={options[0]}
                            isDisabled={false}
                            isClearable={false}
                            isSearchable={false}
                            name="color"
                            options={options}
                            value={select}
                            onChange={(e) => { setSelect(e); }}
                        />
                        {select.value === '200' &&
                            <div>
                                <textarea type="text" className="form-control mt-2 bg-body-tertiary border-body-tertiary ff-base fw-normal text-body" placeholder="" value={deposeReason} onChange={(e) => setDeposeReason(e.target.value)} />
                            </div>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn text-muted o-66-hover fs-md fw-demi" data-bs-dismiss="modal">Annuler</button>
                        <button disabled={invoiceState.isLoading.includes(Loadings.UPDATING_INVOICE) || invoiceState.isLoading.includes(Loadings.UPDATING_INVOICE_STATUS)} type="button" onClick={submitForm} className="btn text-info text-white-hover bg-info bg-opacity-10 bg-opacity-100-hover fs-md fw-demi px-4">
                            <span> Enregistrer </span>
                            {(invoiceState.isLoading.includes(Loadings.UPDATING_INVOICE) || invoiceState.isLoading.includes(Loadings.UPDATING_INVOICE_STATUS)) && <Spinner />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

