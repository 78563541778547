import React, { useMemo, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as R from "ramda";
import { GetRoutingRules } from '../../../actions/routingRules-api';

//#region TABLE UTILS
const tableHeader = [
    {
        name: 'Siret',
        selector: row => row.siret,
        sortable: true,
    },
    {
        name: 'Code',
        selector: row => row.code,
        sortable: true,
    },
    {
        name: 'Platform',
        selector: row => row.platform,
        sortable: true,
    },
];
const tableStyle = {
    table: {
        style: {
            color: "var(--sbx-body-color)",
            backgroundColor: "none",
            display: "flex",
        },
    },
    head: {
        style: {
            color: "var(--sbx-body-color)",
            backgroundColor: "none",
        },
    },
    headRow: {
        style: {
            // border: 'none',
            minHeight: "3rem", // override the row height
            color: "inherit",
            borderBottomColor: "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-bg-opacity))",
            backgroundColor: "rgba(var(--sbx-body-bg-rgb), var(--sbx-bg-opacity))",
            '&:last-of-type': {
                borderTopLeftRadius: "var(--sbx-border-radius-sm)",
                borderTopRightRadius: "var(--sbx-border-radius-sm)",
            },
        },
    },
    headCells: {
        style: {
            fontSize: "inherit",
            paddingLeft: "8px", // override the cell padding for head cells
            paddingRight: "8px",
        },
    },
    cells: {
        style: {
            paddingLeft: ".5rem", // override the cell padding for data cells
            paddingRight: ".5rem",
        },
    },
    rows: {
        style: {
            fontSize: "0.875rem", // override the font size
            // minHeight: "72px", // override the row height
            color: "var(--sbx-body-color)",
            backgroundColor: "rgba(var(--sbx-body-bg-rgb), var(--sbx-bg-opacity))",
            "&:not(:last-of-type)": {
                borderBottomColor: "rgba(var(--sbx-tertiary-bg-rgb), var(--sbx-bg-opacity))",
            },
            // '&:first-of-type': {
            // 	borderTopLeftRadius: "var(--sbx-border-radius-sm)",
            // 	borderTopRightRadius: "var(--sbx-border-radius-sm)",
            // },
            "&:last-of-type": {
                borderBottomLeftRadius: "var(--sbx-border-radius-sm)",
                borderBottomRightRadius: "var(--sbx-border-radius-sm)",
            },
        },
        denseStyle: {
            minHeight: "2rem",
        },
        highlightOnHoverStyle: {
            backgroundColor: "rgba(var(--sbx-secondary-bg-rgb), var(--sbx-bg-opacity))",
            borderBottomColor: "rgba(var(--sbx-secondary-bg-rgb), var(--sbx-bg-opacity))",
            borderRadius: "var(--sbx-card-border-radius)",
            outline: ".0625rem solid rgba(var(--sbx-secondary-bg-rgb), var(--sbx-bg-opacity))",
        },
    },
    pagination: {
        style: {
            color: "var(--sbx-body-color)",
            backgroundColor: "none",
            border: "none",
            borderRadius: "var(--sbx-card-border-radius)",
            minHeight: "2rem"
        },
        pageButtonsStyle: {
            fill: "var(--sbx-body-color)",
            color: "var(--sbx-body-color)",
            backgroundColor: "inherit",
            "&:disabled": {
                fill: "rgba(var(--sbx-body-color-rgb), .18)",
                color: "rgba(var(--sbx-body-color-rgb), .18)",
            },
            "&:hover:not(:disabled)": {
                backgroundColor: "rgba(var(--sbx-secondary-bg-rgb), .18)",
            },
            "&:focus": {
                backgroundColor: "rgba(var(--sbx-secondary-bg-rgb), var(--sbx-bg-opacity))",
            },
        },
    },
    progress: {
        style: {
            color: "var(--sbx-body-color)",
            backgroundColor: "var(--sbx-body-bg)",
        },
    },
};
//#endregion


export const Directory = ({ companyState, routingRulesState }) => {
    const dispatch = useDispatch();
    const { companyId } = useParams();
    const { customerId, siren } = companyState.company || { customerId: '', siren: '' };
    const routingRules = routingRulesState?.routingRules;
    console.log(routingRules);
    useEffect(() => {
        if (companyId === 'new') return;
        //dispatch(GetRoutingRules(customerId, siren));
        // eslint-disable-next-line 
    }, [companyId]);

    const data = useMemo(() => {
        if (R.isNil(routingRules) || R.isEmpty(routingRules)) return [];
        return routingRules.map(v => ({
            ...v,
            code: !R.isNil(v.recipientRoutingCode) ? v.recipientRoutingCode : "--",
            siret: !R.isNil(v.recipientSiret) ? v.recipientSiret : "--",
            platform: !R.isNil(v.flagDestinationPdp) ? v.flagDestinationPdp : 'PPF'
        }));
        // return routingRules.flatMap(val => {
        //     console.log(val);
        //     const { recipientSiret, recipientRoutingCode, flagDestinationPdp } = val;
        //     if (R.isNil(recipientSiret) && R.isNil(recipientRoutingCode)) {
        //         return [{
        //             code: "--",
        //             siret: "--",
        //             platform: flagDestinationPdp
        //         }];
        //     } else if (!R.isNil(recipientRoutingCode)) {
        //         return recipientRoutingCode.flatMap(code =>
        //             recipientSiret.map(siret => ({
        //                 code,
        //                 siret: siret.substring(siret.length - 4, siret.length),
        //                 platform: flagDestinationPdp
        //             }))
        //         );
        //     } else {
        //         return recipientSiret.map(siret => ({
        //             code: "--",
        //             siret: siret.substring(siret.length - 4, siret.length),
        //             platform: flagDestinationPdp
        //         }));
        //     }
        // });
    }, [routingRules]);

    return (
        <div className="flex-column w-100">
            <div className="container-fluid g-0 g-lg-3 container-copy container-xxl-screen">
                <h5 className="ff-base h5 fw-normal mb-1 mt-2 mt-xxl-3">Code Routage</h5>
                <div className="container-lg-wide">
                    <div className="block o-44">
                        <i className="icns material-symbols-outlined align-middle icns-fw me-2 fs-inherit">heap_snapshot_multiple</i>
                        <span className="align-middle me-2">Siren: </span>
                        <span className={!R.isNil(siren) && !R.isEmpty(siren) ? ' fw-medium' : 'o-44'}>{!R.isNil(siren) ? siren : ''}</span>
                    </div>
                </div>
                {!R.isNil(data) && !R.isEmpty(data) ?
                    <div className="block mb-4 mt-3" accept-charset="UTF-8" id="DirectoryForm">
                        <DataTable columns={tableHeader} data={data} defaultSortFieldID={1} paginationPerPage={25} paginationRowsPerPageOptions={[25, 50, 75, 100]} fixedHeader customStyles={tableStyle} highlightOnHoverpointerOnHover />
                    </div>
                    :
                    <div className="block py-gutter mb-auto text-center">
                        <div className="avatar avatar-4 ff-base fs-2 fw-normal text-gray border-gray text-center border border-3 border-dashed rounded-3 mb-4 pt-1 mx-auto">'~'</div>
                        <div className="block mw-x16 mx-auto">
                            <h1 className="h5 fw-medium lh-1 ff-base">Aucun Code</h1>
                            <div className="mb-2 o-77">
                                Cette entreprise n'a pas de Code Routage configurées.
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};