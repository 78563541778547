import React from 'react';
import * as R from 'ramda';
import { useLocation } from "react-router-dom";

import { ToolbarSearch } from "./search";
import { ToolbarFilter } from "./filter";

export const Toolbar = ({ invoiceState }) => {
    const dataSiret = !R.isNil(invoiceState.receivables) && !R.isEmpty(invoiceState.receivables) ? invoiceState.receivables.map((item) => item.seller.siren) : null;

    return (
        <nav className="navbar card rounded-2 flex-sm-row py-2 gap-3 shadow-xl border-0 sticky-top mb-3">
            <h3 className="sr-only visually-hidden">inbox: Toolbar</h3>
            <ToolbarSearch />
            <ToolbarFilter dataSiret={dataSiret} />
        </nav>
    );
};