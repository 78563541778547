import React, { useMemo, useState } from 'react';
import * as R from 'ramda';

// Components
// ==========================================
// Components: Common
// import { members } from '../../../../../constants/utils/data';
import { ModalMember } from '../../../_common/modalMember';
import { ModalDelete } from '../../../_common/modalDelete';

// Components: View
// import { Empty } from '../../empty';
// import { MemberItem } from './table/item';
// import { MemberFilter } from './table/filter';
import { EmptyMember } from './empty';
import { TableMember } from './table';
import { URLParams } from '../../../../../constants/utils/urlParams';
import { normalizer } from '../../../../../constants/utils/normalizeText';
import { useLocation } from 'react-router-dom';
import { MEMBER_STATUS, ROLES } from '../../../../../constants/utils/global';

export const MainMembers = ({ memberState, authenticationState, companyId }) => {
    const QUERIES = URLParams.queries();
    const location = useLocation();
    let memberData = { email: '', name: '', status: 'Disabled', role: 'ReadOnly', id: null };
    const [selectedMember, setSelectedMember] = useState({ ...memberData });


    const membersToDisplay = useMemo(() => {
        if (R.isNil(memberState.members)) return [];
        let temp = [...memberState.members];
        if (!R.isNil(QUERIES.searchText)) {
            temp = temp.filter(member => normalizer(member.name).includes(normalizer(QUERIES.searchText)));
        }
        if (!R.isNil(QUERIES.status)) {
            temp = temp.filter(member => member.status.toLowerCase() === MEMBER_STATUS[QUERIES.status]);
        }
        if (!R.isNil(QUERIES.role)) {
            temp = temp.filter(member => member.role.toLowerCase() === ROLES[QUERIES.role]);
        }
        if (!R.isNil(QUERIES.sortBy) && QUERIES.sortDesc === 'true') {
            temp = R.sortWith([R.descend(R.prop(QUERIES.sortBy))])(temp);
        } else if (!R.isNil(QUERIES.sortBy) && QUERIES.sortDesc === 'false') {
            temp = R.sortWith([R.ascend(R.prop(QUERIES.sortBy))])(temp);
        }
        return temp;
        // eslint-disable-next-line 
    }, [location.search, memberState.members]);


    return (
        <div className="container-fluid g-0 g-lg-3 container-copy container-xxl-screen h-inherit">
            <div className="flex-column h-inherit pt-xxl-3 pt-hd-4">
                <div className="row mb-3 mb-sm-0">
                    <div className="col-sm">
                        <h5 className="ff-base fw-normal">Membres</h5>
                        <div className="container-lg-wide">
                            <p className="fs-aux o-44">Les membres sont les personnes de l'équipe. Ils peuvent afficher et généralement télécharger et modifier des fichiers.</p>
                        </div>
                    </div>
                    <div className="col-sm-auto text-right">
                        <button onClick={() => { setSelectedMember({ ...memberData }); }} className="btn btn-outline-info fs-md fw-demi px-4" type="button" data-bs-toggle="modal" data-bs-target="#modalMember" aria-expanded="false" aria-controls="modalMember">Ajouter membre</button>
                    </div>
                    <ModalMember authenticationState={authenticationState} memberState={memberState} selectedMember={selectedMember} setSelectedMember={setSelectedMember} />
                </div>
                <section className="flex-column flex-fill">
                    {R.isEmpty(memberState.members) ?
                        <EmptyMember />
                        :
                        <TableMember memberState={memberState} membersToDisplay={membersToDisplay} setSelectedMember={setSelectedMember} />
                    }
                </section>
                <ModalDelete authenticationState={authenticationState} memberState={memberState} selectedMember={selectedMember} setSelectedMember={setSelectedMember} />
            </div>
        </div>
    );
};