import React, { useState } from 'react';
import * as R from 'ramda';
import { useNavigate, useParams } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { GetReceivablesInvoices, UploadReceivablesInvoice } from '../../../../../actions/invoice-api';
import * as Loadings from '../../../../../constants/loadings/invoice';
import { toastConf } from '../../../../../constants/utils/toastConfig';
import { Spinner } from '../../../../../_common/spinner';


export const PdfDropzone = ({ invoiceState, customerId, siren }) => {
    const [files, setFiles] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { companyId } = useParams();
    const [maxFileExceeded, setMaxFileExceeded] = useState(false);
    const FILE_LIMITE_MB = 4000; //MEGABITE
    const ONE_MEGABYTE = 1024;

    const validateMaxFileSize = (files) => Array.from(files).some(file => (file.size / ONE_MEGABYTE) > FILE_LIMITE_MB);

    const addFiles = (acceptedFiles) => {
        if (validateMaxFileSize(acceptedFiles)) {
            setMaxFileExceeded(true);
            setTimeout(() => { setMaxFileExceeded(false); }, 5000);
            return;
        }
        if (!R.isEmpty(acceptedFiles))
            setFiles(acceptedFiles);
    };

    const submitInvoice = async () => {
        try {
            const form = new FormData();
            form.set('Files', files[0]);
            const invoice = await dispatch(UploadReceivablesInvoice(customerId, siren, form));
            dispatch(GetReceivablesInvoices(customerId, siren));
            navigate(`/c/${companyId}/accountReceivables`);
        } catch (error) {
            toast.error("Tentative échouée, réessayez !", toastConf);
        }
    };

    return (
        <React.Fragment>
            {!R.isNil(files) ?
                <div className="flex-column w-100 flex-fill m-auto d-none d-lg-inherit  border border-2 border-dashed border-gray border-opacity-25 rounded-2" >
                    <div className="card flex-row rounded-2 p-1 gap-2 align-items-center m-auto mb-2">
                        <div className="flex-row gap-2 align-items-center me-3 text-truncate">
                            <div className="border-none bg-stage fw-bold text-uppercase p-2">
                                <i className="icns material-symbols-rounded align-middle">picture_as_pdf</i>
                            </div>
                            <span className="mw-x16 text-truncate">{Array.from(files)[0].name}</span>
                        </div>
                        <button onClick={() => { setFiles(undefined); }} type="button" className="btn border-none link-danger bg-danger bg-opacity-10 fw-bold text-uppercase p-2">
                            <i className="icns material-symbols-rounded align-middle text-danger-hover" role="button">close</i>
                            <span className="d-none mx-2 align-middle">Remove</span>
                        </button>
                    </div>
                    <button disabled={invoiceState.isLoading.includes(Loadings.UPLOADING_INVOICE)} onClick={submitInvoice} type="button" className="btn border-none btn-secondary fw-bold p-1 gap-1 flex-row align-items-center m-auto mt-1">
                        <i className="icns material-symbols-rounded align-middle text-secondary-hover lh-0 avatar-2 bg-body rounded-1 text-secondary" role="button">document_scanner</i>
                        <span className="mx-2 align-middle fs-sm ff-base ls-3 text-uppercase lh-1">Parse Files</span>
                        {(invoiceState.isLoading.includes(Loadings.UPLOADING_INVOICE)) && <Spinner />}
                    </button>
                </div>
                :
                <Dropzone maxFiles={1} onDrop={addFiles} accept={{ 'application/pdf': ['.pdf'] }} >
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="flex-column w-100 flex-fill m-auto d-none d-lg-inherit  border border-2 border-dashed border-gray border-opacity-25 rounded-2" >
                            <input {...getInputProps()} />
                            <p className="text-muted my-auto" role="button">Drag 'n' drop some files here, or click to select files </p>
                        </div>
                    )}
                </Dropzone>
            }
        </React.Fragment>
    );
}

