import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { GetCompany, UpdateFormMode } from '../../../actions/company-api';


export const Overview = ({ companyState, authenticationState }) => {
    const dispatch = useDispatch();
    const selectedCompany = companyState.company || {
        id: null,
        name: '',
        vatNumber: '',
        addressCountry: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        addressPostalCode: '',
        addressCity: '',
    };



    return (
        <React.Fragment>
            <div className="flex-column w-100 mb-4">
                <div className="container-fluid g-0 g-lg-3 container-copy container-xxl-screen">
                    <article className="text-block mb-5" id="donnes">
                        <header className="row mb-3 mb-sm-0 mt-xxl-3 mt-hd-4">
                            <div className="col-sm">
                                <h5 className="ff-base fw-normal">Données</h5>
                                {/* <div className="container-lg-wide">
									<p className="fs-aux o-44">Le contenu stocké est contrôlé par vous, même si vous décidez de supprimer complètement votre compte.</p>
								</div> */}
                            </div>
                            <div className="col-sm-auto text-right">
                                <button className="btn btn-outline-info fs-md fw-demi px-4" type="button" onClick={() => dispatch(UpdateFormMode(false))} data-bs-toggle="modal" data-bs-target="#accountSociete" aria-expanded="false" aria-controls="accountSociete">Modifier</button>
                                {/* <button className="btn btn-outline-info fs-md fw-demi px-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#personalDetails" aria-expanded="false" aria-controls="personalDetails">Modifier</button> */}
                            </div>
                        </header>
                        <section className="block mt-3">
                            <div className="card py-5 px-4 justify-content-center rounded-3">
                                <div className="block w-100 mw-wide mx-auto">
                                    <div className="row align-items-start">
                                        <div className="col-12">
                                            <div className="avatar avatar-7 bg-body-tertiary mb-4 mx-auto rounded-3 text-center">
                                                {R.isNil(selectedCompany.picture) ?
                                                    <span className="icns fw-medium align-middle icns-fw m-2 fs-1">{selectedCompany.name[0]?.charAt(0)}{selectedCompany.name.split(' ')[1]?.charAt(0)}</span>
                                                    :
                                                    <div className="avatar-inherit img-fluid mx-auto rounded-inherit" style={{ backgroundSize: "cover", backgroundImage: "url(" + URL.createObjectURL(selectedCompany.picture) + ")" }}></div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col">
                                            <p className="o-77 my-auto">Raison Sociale</p>
                                        </div>
                                        <div className="col-9">
                                            <h6 className="ff-base fw-normal fw-medium fs-4 mb-0">{!R.isNil(selectedCompany.name) && selectedCompany.name}</h6>
                                            <p className="ff-base fw-normal fst-italic text-info fw-medium fs-aux">{selectedCompany.permissions?.role || 'Administrateur'}</p>
                                        </div>
                                        <div className="col-12"></div>
                                        <div className="col">
                                            <p className="o-77 my-auto">N° TVA</p>
                                        </div>
                                        <div className="col-9">
                                            <p className={"ff-base fw-normal my-auto" + (!R.isNil(selectedCompany.vatNumber) && !R.isEmpty(selectedCompany.vatNumber) ? '' : ' o-44')}>{selectedCompany.vatNumber || 'FR - - - - - - - - - - -'}</p>
                                        </div>
                                        <div className="col-12"><hr /></div>
                                        <div className="col">
                                            <p className="o-77 my-auto">Email contact</p>
                                        </div>
                                        <div className="col-9">
                                            <p className={"ff-base fw-normal my-auto" + (!R.isNil(selectedCompany.email) && !R.isEmpty(selectedCompany.email) ? '' : ' o-44')}>{!R.isNil(selectedCompany.email) && selectedCompany.email || 'N/D'}</p>
                                        </div>
                                        <div className="col-12"><hr /></div>
                                    </div>
                                    <div className="row align-items-start">
                                        <div className="col">
                                            <p className="o-77 my-auto">Addresse</p>
                                        </div>
                                        <div className="col-9">
                                            <p className={"my-1" + (!R.isNil(selectedCompany.addressLine1) && !R.isEmpty(selectedCompany.addressLine1) ? '' : ' o-44')}>
                                                {selectedCompany.addressLine1 || 'Ligne 1'}
                                            </p>
                                            {!R.isNil(selectedCompany.addressLine2) && !R.isEmpty(selectedCompany.addressLine2) ? <p className="my-1">
                                                {selectedCompany.addressLine2 || 'Ligne 2'}
                                            </p> : ''}
                                            {!R.isNil(selectedCompany.addressLine3) && !R.isEmpty(selectedCompany.addressLine3) ? <p className="my-1">
                                                {selectedCompany.addressLine3 || 'Ligne 3'}
                                            </p> : ''}
                                            {/* <hr className=""/> */}
                                            <div className="flex-row gap-2">
                                                <p className={"my-0" + (!R.isNil(selectedCompany.postalCode) && !R.isEmpty(selectedCompany.postalCode) ? '' : ' o-44')}>
                                                    {selectedCompany.postalCode || 'Code Postal'}
                                                </p>
                                                <p className={"my-0" + (!R.isNil(selectedCompany.city) && !R.isEmpty(selectedCompany.city) ? '' : ' o-44')}>
                                                    {selectedCompany.city || 'City'}
                                                </p>
                                            </div>
                                            {/* <hr className=""/> */}

                                            <p className={"ff-base fw-normal" + (!R.isNil(selectedCompany.country) && !R.isEmpty(selectedCompany.country) ? '' : ' o-44')}>
                                                {selectedCompany.country || 'N/D'}
                                            </p>
                                        </div>
                                        {/* <div className="col-12"></div>
                                        <div className="col">
                                            <p className="o-77 my-auto">Pays</p>
                                        </div>
                                        <div className="col-9">
                                            <p className={"ff-base fw-normal" + (!R.isNil(selectedCompany.country) && !R.isEmpty(selectedCompany.country) ? '' : ' o-44')}>
                                                {selectedCompany.country || 'N/D'}
                                                </p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </article>
                </div>
            </div>
            <div className="flex-column w-100">
                <div className="container-fluid g-0 g-lg-3 container-copy container-xxl-screen">
                    <div className="text-block" id="section_supprimer">
                        <div className="row">
                            <div className="col">
                                <h3 className="ff-base h5 fw-normal mb-1 mt-2">Suppression</h3>
                                <div className="container-lg-wide">
                                    <p className="fs-aux o-44 mt-auto">Votre compte et les données associées ne pourront plus être réactivés.</p>
                                </div>
                            </div>
                            <div className="col-auto text-right">
                                <button className="btn btn-link text-info" type="button" data-toggle="tooltip" data-placement="top" title="This action cannot be undone">
                                    <i className="icns material-symbols-outlined align-middle fs-4">info</i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <section className="flex-column align-items-start">
                        <h1 className="sr-only">Disclaimer</h1>
                        <ul className="text-muted ps-4 mb-4">
                            <p className="text-body ms-n4 mb-2">Si vous supprimez votre profile:</p>
                            <li>Toutes les informations dans les services (CloudAgents, ParseXtract, Cube, RH) associés à ce profile seront définitivement supprimées et ne pourront plus être récupérées.</li>
                            <li>Les données stockées dans des espaces cloud externes (Google Drive, OneDrive, Box) qui ont été associés à ce profile Securibox, ou à un des services associés, ne seront bien sûr pas supprimées.</li>
                            <li>Exportez antérieurement toutes vos informations à partir des différents services associés à ce profile.</li>
                        </ul>
                        <button className="btn fs-md fw-demi py-2 bg-danger bg-opacity-10 bg-opacity-100-hover text-danger text-white-hover" data-toggle="modal" data-target="#authenticationStateRemove"><i className="icns mdi mdi-trash-can-outline h5 align-middle ms-n1 me-2"></i>Je veux supprimer mon donnes</button>
                    </section>
                </div>
            </div>
        </React.Fragment>
    );
};