import React, { useState, useRef } from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';

import { createConnection } from '../../../actions/connection-api';
import * as Loadings from '../../../constants/loadings/connection';
import { Spinner } from '../../../_common/spinner';
import { useNavigate, useParams } from 'react-router';


export const ModalPPF = ({ connectionState, siren, customerId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { companyId } = useParams();
    const closeRef = useRef();
    const formRef = useRef();
    const [form, setform] = useState({ secret: "", clientId: "" });



    const submitForm = async (e) => {
        e.preventDefault();
        formRef.current.classList.add('was-validated');
        if (formRef.current.checkValidity()) {
            try {
                await dispatch(createConnection(customerId, siren, {
                    login: form.clientId,
                    password: form.secret,
                    connectionType: "PPF"
                }));
                formRef.current.classList.remove('was-validated');
                if (closeRef.current) closeRef.current.click();
                setform({ secret: "", clientId: "" });
            } catch (error) {

            }
        }
    };

    const handleRedirect = () => {
        if (!R.isNil(closeRef.current)) {
            closeRef.current.click();
            navigate(`/c/${companyId}/settings/connectes?q=technical`);
        }
    };
    const handleCancel = () => {
        if (!R.isNil(closeRef.current)) {
            closeRef.current.click();
            navigate(`/c/${companyId}/settings/connectes`);
        }
    };

    return (
        <div className="modal fade" id="ajouterPPF" tabIndex={-1} aria-labelledby="ajouterPPFLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content rounded-2 p-4 text-body">
                    <div className="modal-header pb-0">
                        <h1 className="modal-title fs-4 text-info fw-demi ls-n1" id="ajouterPPFLabel">Portal public de facturation</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeRef}></button>
                    </div>
                    <div className="modal-body">
                        <p className="o-77 mb-4 mt-n3">Veuillez sélectionner ci-dessous le statut que vous souhaitez modifier:</p>
                        <form onSubmit={submitForm} noValidate id="ppfForm" ref={formRef}>
                            <div className="my-2">
                                <input type="text" required value={form.clientId} onChange={(e) => { setform(form => ({ ...form, clientId: e.target.value })); }} className="form-control bg-body-tertiary border-body-tertiary ff-base fw-normal" placeholder="Client ID" aria-label="Rechercher..." />
                            </div>
                            <div className="my-2">
                                <input type="text" required value={form.secret} onChange={(e) => { setform(form => ({ ...form, secret: e.target.value })); }} className="form-control bg-body-tertiary border-body-tertiary ff-base fw-normal" placeholder="Client Secret" aria-label="Rechercher..." />
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn link-accent fs-md fw-demi me-auto p-0" onClick={handleRedirect}>Besoin d'aide?</button>
                        <button type="button" className="btn text-muted o-66-hover fs-md fw-demi" onClick={handleCancel}>Annuler</button>
                        <button disabled={connectionState.isLoading.includes(Loadings.CREATING_CONNECTION)} type="submit" form='ppfForm' className="btn text-info text-white-hover bg-info bg-opacity-10 bg-opacity-100-hover fs-md fw-demi px-4">
                            <span>  Enregistrer </span>
                            {(connectionState.isLoading.includes(Loadings.CREATING_CONNECTION)) && <Spinner />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

