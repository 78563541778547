import React from 'react';
import { Link, useParams } from 'react-router-dom';

export const Empty = () => {
    const { companyId } = useParams();
    return (
        <section className="view flex-grow-1 justify-content-center scroll-sm-auto show">
            <div className="container-fluid col-lg-9 col-xxl-7 col-hd-5">
                <span className="icns material-symbols-rounded o-66" style={{fontSize: '8rem'}}>border_clear</span>
                {/* <span className="icns material-symbols-rounded display-1 text-muted">border_clear</span> */}
                {/* <h3 className="display-1 fw-bold text-secondary lh-1 ff-base">Looks like <br className="d-none d-xxl-block" /> it's empty.</h3> */}
                {/* <h3 className="display-1 fw-bold text-secondary lh-1 ff-base">Pas de factures<br className="d-none d-xxl-block" /> fournisseurs.</h3> */}
                <h3 className="display-1 fw-bold text-secondary lh-1 ff-base">Pas de factures<br className="d-none d-hd-block" /> fournisseurs.</h3>
                <div className="fs-5 o-88 mb-4 mw-hd-85">
                    {/* We can't seem to find what you're looking for. Please review or configure your account for the Portail Publique de Facturation (PPF) in settings. */}
                    Nous ne parvenons pas à trouver ce que vous cherchez. Veuillez réviser ou configurer votre compte pour le Portail Publique de Facturation (PPF) dans les paramètres.
                </div>
                <div className="fs-lg o-88 mb-4">
                    {/* There are no invoices available for now. 
					<Link to="/settings/connectes" className="btn p-0 border-0 link-primary ms-xl-5" type="submit"><span className="text-body align-middle">Get Started</span><i className="icns material-symbols-rounded ms-3 align-middle">arrow_forward</i></Link> */}
                    Il n'y a pas de factures disponibles.
                    <Link to={`/c/${companyId}/settings/connectes`} className="btn p-0 border-0 link-primary ms-xl-5" ><span className="text-body align-middle">Commencer</span><i className="icns material-symbols-rounded ms-3 align-middle">arrow_forward</i></Link>
                </div>

            </div>
        </section>
    );
};