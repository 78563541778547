import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import * as R from 'ramda';

import step01 from '../../assets/images/placeholders/help/step1.png';
import step02 from '../../assets/images/placeholders/help/step2.png';
import step03 from '../../assets/images/placeholders/help/step3.png';
import step04 from '../../assets/images/placeholders/help/step4.png';
import step05 from '../../assets/images/placeholders/help/step5.png';
import step06 from '../../assets/images/placeholders/help/step6.png';
import { useRef } from 'react';

const ModalHelp = () => {
    let location = useLocation();
    const navigate = useNavigate();
    const closeRef = useRef(null);
    const companyId = location.pathname.includes("/c") ? location.pathname.split("/")[2] : '';
    const [currentStep, setCurrentStep] = useState(0);

    const helpGuide = [
        {
            image: step01,
            text: `Accéder au Portail Chorus Pro <a href="https://portail.chorus-pro.gouv.fr/aife_csm" target="_blank" rel="noreferrer" role="button" className="text-info fw-medium z-1">https://portail.chorus-pro.gouv.fr/aife_csm</a>,<br/> puis cliquez sur la section <strong>“Se connecter”</strong>`,
        },
        {
            image: step02,
            text: 'S’authentifier à l’aide de vos identifiants Chorus-Pro',
        },
        {
            image: step03,
            text: 'Cliquer sur  <strong>“Accéder au domaine”</strong> dans la section  <strong>“Raccordements”</strong>',
        },
        {
            image: step04,
            text: 'Cliquer sur le bouton  <strong>“Accéder”</strong> de la section <strong>“Compte technique” </strong>',
        },
        {
            image: step05,
            text: 'Choisir le type de demande  <strong>“Création d’un compte technique”</strong> et sélectionner la structure associée:',
        },
        {
            image: step06,
            text: 'Prendre note du  <strong>“login”</strong> et du <strong>"mot de passe"</strong> puis cliquer sur <strong>“Soumettre”</strong>',
        },
    ];

    const handleNext = () => {
        if (currentStep < helpGuide.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleRedirect = () => {
        if (!R.isNil(closeRef.current)) {
            setCurrentStep(0);
            closeRef.current.click();
            navigate(`/c/${companyId}/settings/connectes?q=technicalForm`);
        }
    };

    return (
        <div className="modal fade" id="helpGuide" tabIndex={-1} aria-labelledby="helpGuideLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog modal-dialog-scrollable-centered" role="document">
                <div className="modal-content rounded">
                    {/* <div className="modal-header p-5 align-items-start">
						<div className="block">
							<h2 className="fw-bold my-0 ff-base text-secondary">Commencer</h2>
							<p className="o-66">Steps to activate Technical Account</p>
						</div>
						<button type="button" className="btn-close p-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => setCurrentStep(0)}></button>
					</div> */}
                    <button type="button" className="btn-close p-2 position-absolute z-max end-0" ref={closeRef} data-bs-dismiss="modal" aria-label="Close" onClick={() => setCurrentStep(0)}></button>
                    <div className="modal-body rounded-inherit p-0">
                        <img src={helpGuide[currentStep].image} alt={`Step ${currentStep + 1}`} className="img-fluid rounded-top-inherit" data-bs-dismiss="modal" aria-label="Close" onClick={() => setCurrentStep(0)} /><br />
                        <div className="card-body p-5 mt-n3">
                            <div className="mw-narrow text-body px-3 mx-auto text-center" dangerouslySetInnerHTML={{ __html: helpGuide[currentStep].text }}>
                            </div>
                            <div className="flex-row align-items-center mt-5 gap-2">
                                <p className="fs-md my-auto me-auto text-muted"><strong>{currentStep + 1}</strong> of {helpGuide.length}</p>
                                {currentStep === 0 && (
                                    <button type="button" className="btn link-gray px-4 fw-medium fs-aux" onClick={() => setCurrentStep(helpGuide.length - 1)}>
                                        Skip
                                    </button>
                                )}
                                {currentStep > 0 && (
                                    <button type="button" className="btn link-gray fw-medium fs-aux" onClick={handlePrevious} >
                                        Previous
                                    </button>
                                )}
                                {currentStep < helpGuide.length - 1 && (
                                    <button type="button" className="btn btn-info px-4 fw-medium fs-aux" onClick={handleNext} >
                                        Next
                                    </button>
                                )}
                                {currentStep === helpGuide.length - 1 && (
                                    <button className="btn btn-success px-4 fw-medium fs-aux" data-bs-dismiss="modal" aria-label="Close" onClick={handleRedirect}>
                                        Configurer
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalHelp;
