import { createSlice } from '@reduxjs/toolkit';
import * as Loadings from '../constants/loadings/invoice';
import * as R from 'ramda';

const initState = {
    isLoading: [Loadings.FETCHING_INVOICES],
    receivables: undefined,
    payables: undefined,
    invoice: undefined,
    history: undefined,
    isSuccess: false
};

const InvoiceReducer = createSlice({
    name: 'invoice',
    initialState: { ...initState },
    reducers: {
        //#region GET INVOICES
        getInvoicesRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_INVOICES)) {
                state.isLoading.push(Loadings.FETCHING_INVOICES);
            }
        },
        getInvoicesSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_INVOICES);
            state[payload.type] = payload.items;
        },
        getInvoicesFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_INVOICES);
            state.receivables = undefined;
            state.payables = undefined;
        },
        //#endregion

        //#region GET INVOICE
        getInvoiceRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_INVOICE)) {
                state.isLoading.push(Loadings.FETCHING_INVOICE);
            }
        },
        getInvoiceSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_INVOICE);
            state.invoice = payload;
        },
        getInvoiceFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_INVOICE);
        },

        getInvoiceHistoryRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_INVOICE_HISTORY_STATUS)) {
                state.isLoading.push(Loadings.FETCHING_INVOICE_HISTORY_STATUS);
            }
        },
        getInvoiceHistorySuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_INVOICE_HISTORY_STATUS);
            if (!R.isNil(payload)) {
                state.history = payload
                    .map(h => ({ ...h, convertedDate: new Date(h.date) }))
                    .sort((a, b) => a.convertedDate >= b.convertedDate ? -1 : 1);
            }
        },
        getInvoiceHistoryFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_INVOICE_HISTORY_STATUS);
        },
        updateInvoiceHistoryRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_INVOICE_STATUS)) {
                state.isLoading.push(Loadings.UPDATING_INVOICE_STATUS);
            }
        },
        updateInvoiceHistorySuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_INVOICE_STATUS);
            if (!R.isNil(state.history)) {
                state.history.unshift(payload);
            }
            if (!R.isNil(state.payables)) {
                state.payables = state.payables.map(p => p.id === payload.invoiceId ? ({ ...p, status: { ...payload } }) : p);
            }
            if (!R.isNil(state.receivables)) {
                state.receivables = state.receivables.map(p => p.id === payload.invoiceId ? ({ ...p, status: { ...payload } }) : p);
            }
        },
        updateInvoiceHistoryFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_INVOICE_STATUS);
        },
        //#endregion

        //#region UPDATE INVOICE
        updateInvoiceRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_INVOICE)) {
                state.isLoading.push(Loadings.UPDATING_INVOICE);
            }
        },
        updateInvoiceSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_INVOICE);
            if (R.isNil(state.invoice)) {
                state.invoice = {};
            }
            state.invoice.statusHistory.unshift(payload);
            state[payload.type] = state[payload.type].map(invoice => {
                if (invoice.id === payload.id) {
                    invoice.statusHistory = [payload, ...invoice.statusHistory];
                }
                return invoice;
            });
        },
        updateInvoiceFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_INVOICE);
        },
        //#endregion

        //#region UPDATE INVOICE
        uploadInvoiceRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPLOADING_INVOICE)) {
                state.isLoading.push(Loadings.UPLOADING_INVOICE);
            }
        },
        uploadInvoiceSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPLOADING_INVOICE);
            state.invoice = payload;
        },
        uploadInvoiceFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPLOADING_INVOICE);
        },
        //#endregion
        resetInvoiceSuccess(state, { payload }) {
            state.invoice = undefined;
            state.history = undefined;
        },
        resetInvoice(state, { payload }) {
            state.invoice = undefined;
            state.receivables = undefined;
            state.payables = undefined;
            state.isLoading = [];
        },
    }
});


export const invoiceActions = InvoiceReducer.actions;
export default InvoiceReducer.reducer;