import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

// Components
// ==========================================
// Components: Utils
import "./../assets/stylesheets/app.scss";
import { URLParams } from '../constants/utils/urlParams';

// Components: Common
import { NoMatch } from './../_common/no-match';
import { Preloader } from './../_common/preloader';

import { Topbar } from './_common/topbar';
import { Navbar } from './_common/navbar';
import { Notifications } from './_common/notifications';
import { ModalAuth } from './_common/modalAuth';
import { ModalAccount } from './_common/modalAccount';

// Components: Views
import { Inbox } from './inbox';
import { Files } from './files';
import { Settings } from './settings';
import * as LoadingsCompany from '../constants/loadings/company';
import * as LoadingsRountingRules from '../constants/loadings/routingRules';
import { Loading } from '../_common/loading';
import { GetConnections, GetPpfConnections } from '../actions/connection-api';
import { GetCompany } from '../actions/company-api';
import { GetRoutingRules } from '../actions/routingRules-api';
import { Receivables } from './receivables';
import ModalHelp from './_common/modalHelp';



export const Main = ({ authenticationState, connectionState, invoiceState, companyState, memberState, routingRulesState, theme, setTheme }) => {
    const dispatch = useDispatch();
    const { companyId } = useParams();

    useEffect(() => {
        const company = companyState.companies.find(c => c.id === companyId);
        if (R.isNil(company)) return;
        if (R.isNil(companyId) || companyId === 'new') return;
        const { customerId, siren } = company;
        dispatch(GetCompany(companyId));
        //dispatch(GetConnections(customerId, siren));
        //dispatch(GetPpfConnections(customerId, siren));
        dispatch(GetRoutingRules(customerId, siren));
        //eslint-disable-next-line
    }, [companyId]);

    if (
        companyState.isLoading.includes(LoadingsCompany.FETCHING_COMPANY) ||
        routingRulesState.isLoading.includes(LoadingsRountingRules.FETCHING_ROUTINGRULES)
    ) {
        return <Loading />;
    }

    if ((R.isNil(companyState.company) && companyId !== 'new')) {
        return null;
    };


    return (
        <div className="theme  p-3 gap-3" id="aApp">
            <h1 className="visually-hidden sr-only">OD-One</h1>
            <Topbar connectionState={connectionState} />
            <Notifications />
            <main className="card bg-body-tertiary p-3 wrapper rounded-2">
                <Preloader />
                <section className="layout flex-column flex-sm-row gap-3">
                    <h2 className="sr-only visually-hidden">OD-One: App</h2>
                    <Navbar theme={theme} setTheme={setTheme} companyState={companyState} connectionState={connectionState} />
                    <Routes>
                        <Route path="inbox/*" element={
                            <React.Suspense fallback={<Preloader />}>
                                <Inbox invoiceState={invoiceState} authenticationState={authenticationState} companyState={companyState} connectionState={connectionState} />
                            </React.Suspense>
                        } />
                        <Route path="files/*" element={
                            <React.Suspense fallback={<Preloader />}>
                                <Files />
                            </React.Suspense>
                        } />
                        <Route path="accountReceivables/*" element={
                            <React.Suspense fallback={<Preloader />}>
                                <Receivables invoiceState={invoiceState} authenticationState={authenticationState} companyState={companyState} connectionState={connectionState} />
                            </React.Suspense>
                        } />
                        <Route path="settings/*" element={
                            <React.Suspense fallback={<Preloader />}>
                                <Settings connectionState={connectionState} companyState={companyState} authenticationState={authenticationState} memberState={memberState} routingRulesState={routingRulesState} />
                            </React.Suspense>
                        } />
                        <Route path="*" element={<NoMatch redirect={`/c/${!R.isNil(companyState.companies[0]) ? companyState.companies[0].id : 'new'}/inbox${URLParams.querySearchParams()}`} />} />
                        <Route path="/" element={<Navigate replace to={`/c/${!R.isNil(companyState.companies[0]) ? companyState.companies[0].id : 'new'}/inbox${URLParams.querySearchParams()}`} />} />
                    </Routes>
                </section>
                <ModalAuth companyState={companyState} authenticationState={authenticationState} />
                <ModalAccount companyState={companyState} />
                <ModalHelp />
            </main>
        </div>
    );
};