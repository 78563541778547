import React, { useState, useEffect } from 'react';
import * as R from "ramda";

const isBrowser = typeof window !== "undefined";
const isDark = isBrowser && window.matchMedia("(prefers-color-scheme: dark)");

export const useTheme = () => {
    const [theme, setTheme] = useState(isDark ? "dark" : null);


    useEffect(() => {
        const updateTheme = () => {
            document.querySelector("html").setAttribute("data-bs-theme", isDark.matches ? "dark" : "light");
            localStorage.setItem("theme", isDark.matches ? "dark" : "light");
            setTheme(isDark.matches ? "dark" : "light");
        };
        isDark.addEventListener('change', updateTheme);
        // Update theme when the preferred scheme changes
        if (!R.isNil(isBrowser && localStorage.getItem("theme"))) {
            if (isBrowser && localStorage.getItem("theme") === 'dark') {
                document.querySelector("html").setAttribute('data-bs-theme', 'dark');
                setTheme('dark');
            } else if (isBrowser && localStorage.getItem("theme") === 'light') {
                document.querySelector("html").setAttribute('data-bs-theme', 'light');
                setTheme('light');
            }
        } else {
            document.querySelector("html").setAttribute("data-bs-theme", isDark.matches ? "dark" : "light");
            localStorage.setItem("theme", isDark.matches ? "dark" : "light");
            setTheme(isDark.matches ? "dark" : "light");
        }
        return () => isDark.removeEventListener('change', updateTheme);
    }, [theme]);


    return (
        {
            theme,
            setTheme
        }
    );
};
