import React from 'react';

// Components
// ==========================================
// Components: Views
import { Menu } from './menu';
import { View } from './view';


export const Settings = ({ connectionState, companyState, authenticationState, memberState, routingRulesState }) => {
    return (
        <section className="view flex-fill flex-lg-row gap-3 overflow-x-hidden" id="Settings">
            <h3 className="sr-only visually-hidden">App: Settings</h3>
            <Menu />
            <View companyState={companyState} memberState={memberState} connectionState={connectionState} authenticationState={authenticationState} routingRulesState={routingRulesState} />
        </section>
    );
};

