import React, { useEffect } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { NoMatch } from "../../../_common/no-match";
import { MainDetail } from './main';
import { GetInvoice, GetInvoiceHistory, GetReceivablesInvoices } from '../../../actions/invoice-api';
import * as Loadings from '../../../constants/loadings/invoice';
import { Loading } from '../../../_common/loading';


export const ReceivablesDetail = ({ invoiceState, companyState, customerId, siren }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { companyId, invoiceId } = useParams();
    const company = companyState.companies.find(c => c.id === companyId);

    useEffect(() => {
        if (!R.isNil(invoiceId) && invoiceId !== 'new') {
            dispatch(GetInvoice(customerId, siren, invoiceId));
            dispatch(GetInvoiceHistory(customerId, siren, invoiceId));
        }
        // eslint-disable-next-line
    }, [customerId, siren, invoiceId]);


    return (
        <section className={"offcanvas offcanvas-bottom offcanvas-all bg-body-tertiary p-4 gap-3" + (!pathname.endsWith("accountReceivables") ? " show" : "")} tabIndex={-1} id="selectedInvoiceX" aria-labelledby="selectedInvoiceXLabel">
            <h3 className="sr-only visually-hidden">Inbox: Detail</h3>
            <div className="row g-3 h-100">
                {invoiceState.isLoading.includes(Loadings.FETCHING_INVOICE) ?
                    <Loading />
                    :
                    !R.isNil(invoiceState.invoice) || invoiceId === 'new' ?
                        <MainDetail invoiceState={invoiceState} company={company} companyState={companyState} invoice={invoiceState.invoice} />
                        :
                        <NoMatch redirect={`/c/${companyId}/accountReceivables`} dismiss="offcanvas" />
                }
            </div>
        </section>
    );
};

