import React from 'react';
import { Toolbar } from './toolbar';
import { Menu } from './menu';
import { View } from './view';

export const ReceivablesMain = ({ invoiceState }) => {

    return (
        <section className="block flex-column flex-grow-1 scroll-auto" id="Inbox">
            <h3 className="sr-only visually-hidden">App: Inbox</h3>
            <Toolbar invoiceState={invoiceState} />
            <div className="flex-lg-row gap-4 h-inherit scroll-auto pe-2 pe-lg-0">
                <Menu invoiceState={invoiceState} />
                <View invoiceState={invoiceState} />
            </div>
        </section>
    );
};
