import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { format, differenceInDays, parseISO } from 'date-fns';
import * as R from 'ramda';

import { SOURCE_TYPE, SOURCE_COLOR, FORMAT_AMOUNT, STATUS_MAP } from "../../../../../constants/utils/global";

export const ListItem = ({ item }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const todayParse = parseISO(format(new Date(), 'yyyy-MM-dd'), 'dd-MM-yyyy', new Date());
    const emissionDateParse = parseISO(item.emissionDate, 'dd-MM-yyyy', new Date());
    const dueDateParse = parseISO(item.invoiceDueDate, 'dd-MM-yyyy', new Date());
    const dueDateDifference = differenceInDays(dueDateParse, todayParse);


    return (
        // <li className="card list-group-item rounded-0 rounded-2-hover px-0 px-2-hover gap-2 bg-none border-common bg-body-hover" role="button">
        <li className="card list-group-item rounded-2 p-2 gap-2">
            {/* <div className="container-fluid g-1"> */}
            <div className="row gy-1 gx-2 gx-xxl-3 gx-uhd-4 align-items-center">
                <div className="col col-md-3 col-lg-4 col-xl-3 col-xxl col-hd-3 flex-row align-items-center gap-2 text-truncate">
                    {/* <i className="icns material-symbols-rounded align-middle o-22">check_box_outline_blank</i> */}
                    <div className="flex-row flex-fill align-items-center gap-2 text-truncate">
                        <div className="avatar mb-0 flex-shrink-0 bg-body-tertiary rounded-2" style={{ backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundBlendMode: "difference", backgroundImage: "url('https://az859819.vo.msecnd.net/agents/logos/medium/" + (item.supplier?.logo || "placeholder") + ".png')" }}></div>
                        {/* <div className="avatar mb-0 flex-shrink-0 bg-body-tertiary rounded-2" style={{ backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundImage: "url('https://az859819.vo.msecnd.net/agents/logos/medium/" + (item.supplier?.logo || "placeholder") + ".png')" }}></div> */}
                        {/* <img className="card border-stage rounded-2 flex-shrink-0 avatar of-contain" src={"https://az859819.vo.msecnd.net/agents/logos/small/" + (item.providerLogo || "placeholder") + ".png"} alt={item.providerLogo} width="32" /> */}
                        <div className="flex-xl-row flex-fill align-items-center gap-xl-1 text-truncate">
                            <div className="fw-medium my-auto text-truncate lh-1 mt-1 my-xl-0">{!R.isNil(item.buyer.legalName) ? item.buyer.legalName : (!R.isNil(item.buyer.commercialName) ? item.buyer.commercialName : "Unknown")}</div>
                            {/* <div className="badge rounded-2 bg-opacity-10 fs-xs text-bg-source" style={{ "--sbx-source": !R.isNil(item.source) ? SOURCE_COLOR[item.source] : "var(--sbx-dark-rgb)" }}>{SOURCE_TYPE[item.source]}</div> */}
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-lg-2 col-xl-3 col-xxl col-hd-3 align-items-center justify-content-between">
                    <div className="row gx-2 gx-uhd-4 align-items-center justify-content-between">
                        <div className="col col-md-12 col-xl-5  col-xxl-auto col-hd w-xxl-7 align-items-center text-truncate">
                            <div className="fs-book my-auto text-truncate o-66">{format(new Date(item.emissionDate), 'dd-MM-yyyy')}</div>
                        </div>
                        <div className="col align-items-center">
                            {!R.isNil(item.invoiceDueDate) && !R.isEmpty(item.invoiceDueDate) ?
                                <div className="row gx-1 align-items-baseline justify-content-between
									">
                                    <div className="col-auto align-items-center text-truncate">
                                        <div className="fs-book my-auto text-truncate o-44">{format(new Date(item.invoiceDueDate), 'dd-MM-yyyy')}</div>
                                    </div>
                                    {/* {(item.status !== 'Paiement transmis') && ((!R.isNil(dueDateDifference) && !R.isEmpty(dueDateDifference) && (dueDateDifference < 30)) || ((!R.isNil(item.paymentDate) && item.paymentDate !== "0001-01-01T00:00:00"))) ?
                                        <div className="col align-items-center text-truncate">
                                            <div className="fs-sm text-truncate"><span className="o-44 d-xl-none d-hd-inline-block fw-medium">due:</span> <span className="fw-bold text-danger">{Math.abs(dueDateDifference)} jour{dueDateDifference === 1 ? "" : "s"} {dueDateDifference < 0 ? "ago" : ""}</span></div>
                                        </div> : ""
                                    } */}
                                </div>
                                :
                                <div className="fs-book my-auto text-truncate o-22">--</div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col col-xl-4 col-xxl-4 col-hd align-items-center justify-content-between">
                    <div className="row gx-2 align-items-center">
                        <div className="col col-md-12  col-xl-auto align-items-centertext-truncate w-xl-75 w-uhd-auto">
                            {item.invoiceNumber ?
                                <div className="fs-aux my-0 o-44 text-truncate">{item.invoiceNumber}</div>
                                :
                                <div className="fs-aux my-0 o-22 ls-n1">####</div>
                            }
                        </div>
                        <div className="col col-lg-12 col-xl align-items-center text-truncate text-end text-sm-start text-xl-end">
                            <div className="fs-book my-auto fw-demi text-truncate">
                                {item.taxDistributions ? FORMAT_AMOUNT.format(item?.taxDistributions?.reduce((acc, v) => (acc + v.vatAmountPerVatType + v.taxBase), 0) || 0) : FORMAT_AMOUNT.format(0)}
                            </div>
                        </div>
                        {/* <div className="col col-md-12 col-xl align-items-center">
                                <div className="row align-items-center">
                                    <div className="col align-items-center text-truncate">
                                        <div className="fs-book my-auto text-truncate o-88">{item.vatTable ? FORMAT_AMOUNT.format(item.vatTable[0].baseAmount || 0) : FORMAT_AMOUNT.format(0)}</div>
                                    </div>
                                    <div className="col-auto align-items-center text-truncate">
                                        <div className="fs-book my-auto text-truncate o-88">{item.vatTable ? FORMAT_AMOUNT.format(item.vatTable[0].amountVat || 0) : FORMAT_AMOUNT.format(0)}</div>
                                    </div>
                                    <div className="col align-items-center text-truncate">
                                        <div className="fs-book my-auto fw-demi text-truncate">
                                            {item.vatTable ? FORMAT_AMOUNT.format(item.totalAmount || 0) : FORMAT_AMOUNT.format(0)}
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                    </div>
                </div>
                <div className="col-md-12  col-xl-2 align-items-center">
                    <hr className="border-faded o-50 my-2 mx-n2 d-xl-none" />
                    <div className="row g-2 align-items-center justify-content-between">
                        <div className="col w-uhd-x8 text-truncate">
                            {!R.isNil(item.status) ?
                                <p className="fs-book my-0 o-66 text-truncate">{STATUS_MAP[item.status.statusCode]}</p>
                                :
                                <p className="fs-book my-0 o-22 ls-n1">Inconnu</p>
                            }
                            {/* {item.status ?
                                <div className="fs-book my-0 o-66 text-truncate">{STATUS_MAP[item.status.toLowerCase()]}</div>
                                :
                                <div className="fs-book my-0 o-22 ls-n1">--</div>
                            } */}
                        </div>
                        <div className="col-auto flex-row justify-content-between align-items-center gap-2">
                            {/* <div className="flex-row flex-fill align-items-center gap-1">
									<button type="button" className="btn border-none text-gray bg-stage-hover m-0 py-1 px-2">
										<i className="icns material-symbols-rounded fs-5 lh-0 align-middle">download</i>
									</button>
								</div>
								<span className="text-light">|</span> */}
                            <button type="button" className="btn border-none bg-stage bg-opacity-10 bg-opacity-100-hover text-gray fw-bold text-uppercase fs-sm p-1" onClick={(e) => {
                                e.preventDefault();
                                // setSelectedInvoice(item);
                                navigate(location.pathname + "/" + item.id + location.search, "offcanvas");
                            }}>
                                <span className="d-sm-none align-middle ms-2">Voir</span>
                                <i className=" icns material-symbols-rounded align-middle">chevron_right</i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </li>
    );
};