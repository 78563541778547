let endpoints = {
    // backendHost: window.env['APP_DOMAIN'],
    backendHost: 'https://localhost:44319',
    environment: 'development'
};

const hostname = window && window.location && window.location.hostname;
if (hostname === 'od-one.securibox.eu') {
    endpoints.backendHost = 'https://od-one-api.azurewebsites.net';
    endpoints.environment = 'production';
} else if (hostname === 'px-dev.securibox.eu' || hostname === 'px-dev.azureedge.net') {
    endpoints.backendHost = 'https://px-webcontent-dev.azureedge.net';
    endpoints.environment = 'production';
}
else {
    // endpoints.backendHost = 'https://px-webcontent.azureedge.net';
    endpoints.backendHost = 'https://od-one-api.azurewebsites.net';
    endpoints.environment = 'development';
}
endpoints.backendHost = 'https://od-one-api.azurewebsites.net';
// endpoints.backendHost = 'http://localhost:7189';
export const EndPoints = endpoints;

