import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import * as Loadings from '../../../constants/loadings/member';
import { Spinner } from '../../../_common/spinner';
import { DeleteMember } from '../../../actions/member-api';
import { useParams } from 'react-router-dom';


export const ModalDelete = ({ authenticationState, memberState, selectedMember }) => {
    const closeRef = useRef();
    const dispatch = useDispatch();
    const { companyId } = useParams();

    const handleDeleteMember = async (e) => {
        e.preventDefault();
        try {
            await dispatch(DeleteMember(companyId, selectedMember.id));
            closeRef.current.click();
        } catch (error) {

        }
    };

    return (
        <div className="modal fade" id="modalDelete" tabIndex={-1} aria-labelledby="modalDeleteLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content rounded-2 p-4 text-body">
                    <div className="modal-header pb-0 z-1">
                        <h1 className="sr-only visually-hidden modal-title fs-4 text-danger fw-demi ls-n1" id="modalDeleteLabel">Remove membre</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeRef}></button>
                    </div>
                    <div className="modal-body pt-0 mt-n4">
                        <form noValidate id="deleteForm">
                            <div className="flex-column justify-content-center align-items-center mb-3 text-danger p-2 bg-danger bg-opacity-10 rounded-2 avatar-5">
                                <i className="icns material-symbols-outlined align-middle fs-1 lh-1">delete</i>
                            </div>
                            <h2 className="fw-semi ff-base ls-n1">Supprimer <u>{selectedMember.name}</u>?</h2>
                            <p className="o-77">Attention : L'utilisateur sera définitivement supprimé sans aucune possibilité de récupération. Es-tu sur de vouloir continuer?</p>
                        </form>
                    </div>
                    <div className="modal-footer justify-content-start pt-0">
                        <button disabled={memberState.isLoading.includes(Loadings.DELETING_MEMBER)} type="submit" form='deleteForm' className="btn text-danger text-white-hover bg-danger bg-opacity-10 bg-opacity-100-hover fs-md fw-demi px-4"
                            onClick={handleDeleteMember}>
                            <span> Oui, je veux supprimer </span>
                            {(memberState.isLoading.includes(Loadings.DELETING_MEMBER)) && <Spinner />}
                        </button>
                        <button type="button" className="btn text-muted o-66-hover fs-md fw-demi" data-bs-dismiss="modal">Annuler</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

