import React from 'react';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import { URLParams } from '../../../../constants/utils/urlParams';

export const ToolbarFilter = ({ dataSiret }) => {
    const QUERIES = URLParams.queries();

    return (
        <section className="flex-column align-items-stretch flex-shrink-0 col-12 col-sm-auto col-lg-3 col-xl-2">
            <h4 className="sr-only visually-hidden">Filter</h4>
            <div className="container-fluid">
                <button type="button" className="btn flex-fill border-0 p-0 text-start dropdown-toggle text-truncate" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="ff-base align-middle lh-1 my-auto me-3 small text-muted">Voir:</span><br />
                    <span className="text-truncate align-middle my-auto fs-aux fw-medium">{R.isNil(QUERIES.sirenId) ? "Tout les sirens" : QUERIES.sirenId}</span>
                </button>
                <ul className="dropdown-menu border-0 shadow p-2" aria-labelledby="dropdownMenuButton">
                    <li><Link className={"dropdown-item rounded-1" + (R.isNil(QUERIES.sirenId) ? " active" : "")} to={URLParams.delete(["sirenId"])}>Tout les sirens</Link></li>
                    {!R.isNil(dataSiret) && dataSiret.filter((data, index, arr) => !R.isNil(data) && !R.isEmpty(data) && arr.indexOf(data) === index).map((item, index) => {
                        return (
                            <li key={index}><Link className={"dropdown-item rounded-1" + ((QUERIES.sirenId === item.toString()) ? " active" : "")} end={URLParams.set({ sirenId: item })} to={URLParams.set({ sirenId: item })}>{item}</Link></li>
                        );
                    })}
                </ul>
            </div>
        </section>
    );
};