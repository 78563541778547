import React from 'react';
import * as R from 'ramda';
import { Link, useSearchParams } from 'react-router-dom';
import { URLParams } from '../../../../../../constants/utils/urlParams';
import { debounce } from '../../../../../../constants/utils/debounce';

export const FilterMember = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const QUERIES = URLParams.queries();
    const DEBOUNCE_TIME = 1000; //milliseconds
    const setSearchText = debounce(searchParams, setSearchParams, DEBOUNCE_TIME);

    return (
        <div className="col-md-12 col-xl-3 ms-lg-auto order-xl-last mt-xl-5">
            <h3 className="visually-hidden sr-only"><i className="icns material-symbols-outlined align-middle me-2">filter_list</i></h3>
            <div className="text-block mt-n1 mb-5" id="section_membersTools">
                <form className="form-group mb-2" onSubmit={(e) => { e.preventDefault(); }}>
                    <div className="input-group gap-1 bg-body rounded-2 aling-items-center py-1">
                        <div className="input-group-text bg-body border-body p-0">
                            <button className="btn bg-none border-0">
                                <i className="icns material-symbols-rounded text-body align-middle lh-1">search</i>
                            </button>
                        </div>
                        <input type="text" defaultValue={QUERIES.searchText} onChange={(e) => { setSearchText(["searchText", e.target.value]); }} className="form-control bg-none border-none text-body" placeholder="Rechercher..." aria-label="Search" />
                    </div>
                </form>
                <div className="form-group mb-4">
                    <label className="smallcaps ff-aux fw-demi o-33 fs-sm mb-2" htmlFor="datepickerFrom">Filtre:</label>
                    <div className="d-block">
                        <Link to={URLParams.delete(["role", "status", "searchText"])} className={"btn fs-sm py-1 px-2 fw-demi bg-body-secondary text-bg-info-hover me-2 mb-2" + (R.isNil(QUERIES.role) && R.isNil(QUERIES.status) ? ' text-bg-info-hover' : '')}> Tous</Link>
                        <Link to={URLParams.reset({ remove: ["status"], inital: { role: "administrator" } })} className={"btn fs-sm py-1 px-2 fw-demi bg-body-secondary text-bg-info-hover me-2 mb-2" + (QUERIES.role === 'administrator' ? ' text-bg-info-hover' : '')}> Administrateur</Link>
                        <Link to={URLParams.reset({ remove: ["status"], inital: { role: "readonly" } })} className={"btn fs-sm py-1 px-2 fw-demi bg-body-secondary text-bg-info-hover me-2 mb-2" + (QUERIES.role === 'readonly' ? ' text-bg-info-hover' : '')}> Consultation</Link>
                        {/* <Link to={URLParams.reset({ remove: ["role"], inital: { status: "verified" } })} className={"btn fs-sm py-1 px-2 fw-demi bg-body-secondary text-bg-info-hover me-2 mb-2" + (QUERIES.status === 'verified' ? ' text-bg-info-hover' : '')}> Verified</Link> */}
                        <Link to={URLParams.reset({ remove: ["role"], inital: { status: "pending" } })} className={"btn fs-sm py-1 px-2 fw-demi bg-body-secondary text-bg-info-hover me-2 mb-2" + (QUERIES.status === 'pending' ? ' text-bg-info-hover' : '')}> En attente</Link>
                        <Link to={URLParams.reset({ remove: ["role"], inital: { status: "disabled" } })} className={"btn fs-sm py-1 px-2 fw-demi bg-body-secondary text-bg-info-hover me-2 mb-2" + (QUERIES.status === 'disabled' ? ' text-bg-info-hover' : '')}> Désactivé</Link>
                    </div>
                </div>
            </div>
        </div >
    );
};
