import React, { useEffect } from 'react';
import { Routes, Route, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Preloader } from '../../_common/preloader';
import { InboxMain } from "./main";
import { InboxDetail } from "./detail";
import { GetPayablesInvoices } from '../../actions/invoice-api';

export const Inbox = ({ invoiceState, companyState, authenticationState }) => {
    const dispatch = useDispatch();
    const { companyId } = useParams();
    const { customerId, siren } = companyState.company || { customerId: '', siren: '' };

    useEffect(() => {
        if (companyId === 'new') return;
        dispatch(GetPayablesInvoices(customerId, siren));
        // eslint-disable-next-line 
    }, [customerId, siren]);


    return (
        <React.Fragment>
            <InboxMain invoiceState={invoiceState} />
            <Routes>
                {["/:invoiceId/*", "*"].map((path, index) =>
                    <Route key={index} path={path} element={
                        <React.Suspense fallback={<Preloader />}>
                            <InboxDetail customerId={customerId} siren={siren} authenticationState={authenticationState} invoiceState={invoiceState} companyState={companyState} />
                        </React.Suspense>
                    } />
                )}
            </Routes>
        </React.Fragment>
    );
};