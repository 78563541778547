import React from 'react';
import { NavLink } from 'react-router-dom';

export const Menu = () => {

    return (
        <nav className="navbar flex-shrink-0 col-12 col-sm-auto p-0 d-lg-block h-lg-inherit scroll-lg-auto pe-1">
            <h4 className="sr-only visually-hidden">Files: menu</h4>
            <button className="btn btn-dark me-auto" type="button">
                <span className="icns material-symbols-rounded align-middle">
                    add
                </span>
                <span className="align-middle mx-2 lh-lg fs-aux fw-medium">Ajouter nouveau</span>
            </button>
            <button className="btn btn-secondary ms-auto d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter">
                <span className="icns material-symbols-rounded align-middle">
                    discover_tune
                </span>
                <span className="align-middle mx-2 lh-lg">Filtre</span>
            </button>
            <ul className="directory-nav navbar-nav mb-auto w-100 mt-4 collapse show-lg" id="collapseFilter" style={{ "--sbx-filter-link-color": "var(--sbx-success)" }}>
                <li className="nav-item">
                    <NavLink end to="filesef" type="button" className="nav-link" data-bs-toggle="collapse" data-bs-target="#collapseSBX" aria-expanded="false" aria-controls="collapseSBX">
                        <div className="icns material-symbols-rounded fs-4 lh-0 align-middle ms-n1 me-2">arrow_right</div>
                        <div className="icns material-symbols-outlined fs-lg align-middle me-2 fs-4 lh-1">cloud_sync</div> <span className="align-middle">SBX Drive</span>
                    </NavLink>
                    {/* <!-- Look, the closing </li> tag is not placed here! --> */}
                    <ul className="navbar-nav flex-sm-column show" id="collapseSBX">
                        <li className="nav-item">
                            <NavLink end to="rex" type="button" className="nav-link collapsed">
                                <div className="icns material-symbols-rounded fs-4 lh-0 align-middle ms-n1 me-2">arrow_right</div>
                                <div className="icns material-symbols-outlined fs-lg align-middle me-2">folder</div> <span className="align-middle">Dossier</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink end to="filesfilesef" className="nav-link" data-bs-toggle="collapse" data-bs-target="#collapseSBXDossier" aria-expanded="false" aria-controls="collapseSBXDossier">
                                <div className="icns material-symbols-rounded fs-4 lh-0 align-middle ms-n1 me-2">arrow_right</div>
                                <div className="icns material-symbols-outlined fs-lg align-middle me-2">folder</div> <span className="align-middle">Dossier</span>
                            </NavLink>
                            {/* <!-- Same for the second nested unordered list! --> */}
                            <ul className="navbar-nav flex-sm-column show" id="collapseSBXDossier">
                                <li className="nav-item">
                                    <NavLink end to="files" className="nav-link collapsed">
                                        <div className="icns material-symbols-rounded fs-4 lh-0 align-middle ms-n1 me-2">arrow_right</div>
                                        <div className="icns material-symbols-outlined fs-lg align-middle me-2">folder</div> <span className="align-middle">Images</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink end to="" className="nav-link collapsed">
                                        <div className="icns material-symbols-rounded fs-4 lh-0 align-middle ms-n1 me-2">arrow_right</div>
                                        <div className="icns material-symbols-outlined fs-lg align-middle me-2">folder</div> <span className="align-middle">Docs</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink end to="button" className="nav-link collapsed">
                                        <div className="icns material-symbols-rounded fs-4 lh-0 align-middle ms-n1 me-2">arrow_right</div>
                                        <div className="icns material-symbols-outlined fs-lg align-middle me-2">folder</div> <span className="align-middle">Dossier</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <NavLink end to="Dossier" className="nav-link collapsed">
                                <div className="icns material-symbols-rounded fs-4 lh-0 align-middle ms-n1 me-2">arrow_right</div>
                                <div className="icns material-symbols-outlined fs-lg align-middle me-2">folder</div> <span className="align-middle">Dossier</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink end to="asd" className="nav-link collapsed">
                                <div className="icns material-symbols-rounded fs-4 lh-0 align-middle ms-n1 me-2">arrow_right</div>
                                <div className="icns material-symbols-outlined fs-lg align-middle me-2">folder</div> <span className="align-middle">Dossier</span>
                            </NavLink>
                        </li>
                    </ul>
                    {/* <!-- Here is the closing </li> tag --> */}
                </li>
                <li className="nav-item">
                    <button type="button" className="nav-link" data-bs-toggle="collapse" data-bs-target="#collapseDrive" aria-expanded="false" aria-controls="collapseDrive">
                        <div className="icns material-symbols-rounded fs-4 lh-0 align-middle ms-n1 me-2">arrow_right</div>
                        <div className="icns material-symbols-outlined fs-lg align-middle me-2">add_to_drive</div> <span className="align-middle">Google Drive</span>
                    </button>
                    {/* <!-- Look, the closing </li> tag is not placed here! -- > */}
                    <ul className="navbar-nav flex-sm-column show" id="collapseDrive">
                        <li className="nav-item">
                            <NavLink end to="case" type="button" className="nav-link">
                                <div className="icns material-symbols-rounded fs-4 lh-0 align-middle ms-n1 me-2">arrow_right</div>
                                <div className="icns material-symbols-outlined fs-lg align-middle me-2">folder</div> <span className="align-middle">Dossier</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink end to="hjhyh" className="nav-link">
                                <div className="icns material-symbols-rounded fs-4 lh-0 align-middle ms-n1 me-2">arrow_right</div>
                                <div className="icns material-symbols-outlined fs-lg align-middle me-2">folder</div> <span className="align-middle">Dossier</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink end to="mm" className="nav-link">
                                <div className="icns material-symbols-rounded fs-4 lh-0 align-middle ms-n1 me-2">arrow_right</div>
                                <div className="icns material-symbols-outlined fs-lg align-middle me-2">folder</div> <span className="align-middle">Dossier</span>
                            </NavLink>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
}

