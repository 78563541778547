import React from 'react';
import { NavLink, Link } from 'react-router-dom';

// import { NoMatch } from "./../../_common/no-match";
import { Empty } from "./empty";

export const View = () => {

	const hasFilesConfig = false;
	if (!hasFilesConfig) return <Empty />

	return (
		<section className="flex-column flex-grow-1 col-12 col-sm">
			<div className="container-fluid">
				<h4 className="sr-only visually-hidden">Files: List</h4>
			</div>
		</section>
	)
}