import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import * as R from 'ramda';
import Dropzone from 'react-dropzone';

import { PdfDropzone } from './pdfDropzone';
import { PdfViewer } from './pdfviewer';

export const DetailPreview = ({ invoiceState, company }) => {
    const { invoiceId } = useParams();
    const { customerId, siren } = company;

    return (
        <div className="col-12 col-lg-6 col-xl-5 h-lg-100 text-center align-items-center order-lg-last">
            <div className="flex-column flex-fill p-1 ps-3 p-lg-3 h-lg-100 scroll-lg-auto bg-body-secondary bg-opacity-50 rounded-2 flex-column text-center align-items-center order-lg-last">
                {
                    !R.isNil(invoiceId) && <PdfViewer documentId={invoiceId} customerId={customerId} siren={siren} />
                }
            </div>
        </div>
    );
}

