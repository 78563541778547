import React from 'react';
import { ListSort } from './sort';
import { ListItem } from './item';

export const ViewList = ({ invoices }) => {
    return (
        <article className="flex-column flex-grow-1 col-12 col-sm h-lg-inherit scroll-lg-auto">
            <div className="container-fluid g-0 gy-3 pe-lg-3 gy-xl-0">
                <h5 className="sr-only visually-hidden">Inbox: List</h5>
                <ListSort />
                <ul className="list-group list-group-flush">
                    <h6 className="sr-only visually-hidden">List: items</h6>
                    {invoices.map((invoice, index) => {
                        return (
                            <ListItem item={invoice} key={index} />
                        );
                    })}
                </ul>
            </div>
        </article>
    );
};