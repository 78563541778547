import React from 'react';

export const Spinner = () => {
    return (
        <React.Fragment>
            <span className='mr-1'></span>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span className="visually-hidden">Loading...</span>
        </React.Fragment>
    );
};