import React from 'react';
import { Link } from 'react-router-dom';

export const Notifications = () => {
	return (
		<div className="offcanvas offcanvas-end rounded me-3 my-3" data-bs-backdrop="false" tabIndex={-1} id="offcanvasNotifications" aria-labelledby="offcanvasNotificationsLabel">
			<div className="offcanvas-header align-items-center">
				<h5 className="offcanvas-title fs-6 my-0 lh-1" id="offcanvasNotificationsLabel">Notifications</h5>
				<button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasNotifications" aria-label="Close"></button>
			</div>
			<div className="offcanvas-body bg-stage m-1 rounded-2 p-0">
				<ul className="notification nav flex-column">
					<li className="nav-item border-top border-light flex-row align-items-stretch current">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover">
							<div className="flex-row gap-3 align-items-center my-auto">
								<div className="nav-item-icon fs-root flex-row gap-1 align-items-center">
									<div className="icns material-symbols-outlined align-middle fs-inherit">link</div>
									{/* <div className="spinner-border spinner-border-sm fs-inherit"></div> */}
								</div>
								<div className="flex-colum">
									<div className="fs-root my-auto lh-title"><strong>Fnac</strong> is syncing</div>
									<div className="fs-md o-66"></div>
								</div>
							</div>
						</Link>
					</li>
					<li className="nav-item border-top border-light flex-row align-items-stretch current">
						<Link to="#" className="nav-link flex-fill align-items-center sync">
							<div className="flex-row gap-3 align-items-center my-auto">
								<div className="nav-item-icon fs-root flex-row gap-1 align-items-center">
									{/* <div className="icns material-symbols-outlined align-middle fs-inherit">link</div> */}
									<div className="spinner-border spinner-border-sm fs-inherit"></div>
								</div>
								<div className="flex-colum">
									<div className="fs-root my-auto lh-title"><strong>Fnac</strong> is syncing</div>
									<div className="fs-md o-66"></div>
								</div>
							</div>
						</Link>
					</li>

					<li className="nav-item border-top border-light flex-row align-items-stretch current">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover upload">
							<div className="flex-row gap-3 align-items-center my-auto">
								<div className="nav-item-icon fs-root flex-row gap-1 align-items-center">
									{/* <div className="icns material-symbols-outlined align-middle fs-inherit">article</div> */}
									<div className="spinner-border spinner-border-sm fs-inherit"></div>
								</div>
								<div className="flex-colum">
									<div className="fs-root my-auto lh-title"><strong>File X</strong> is uploading</div>
									<div className="fs-md o-66 progress-state">75%</div>
								</div>
							</div>
							<div className="progress-bar" aria-hidden="true"></div>
						</Link>
					</li>

					<li className="nav-item border-top border-light flex-row align-items-stretch current">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover upload">
							<div className="flex-row gap-3 align-items-center my-auto">
								<div className="nav-item-icon fs-root flex-row gap-1 align-items-center">
									{/* <div className="icns material-symbols-outlined align-middle fs-inherit">article</div> */}
									<div className="spinner-border spinner-border-sm fs-inherit"></div>
								</div>
								<div className="flex-colum">
									<div className="fs-root my-auto lh-title"><strong>File X</strong> is uploading</div>
									<div className="fs-md o-66 progress-state">100%</div>
								</div>
							</div>
							<div className="progress-bar" aria-hidden="true"></div>
						</Link>
					</li>

					<li className="nav-item border-top border-light flex-row align-items-stretch">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover error-connection">
							<div className="nav-item-icon fs-root flex-row gap-1 align-items-center"></div>
							<div className="fs-root my-auto lh-title"><strong>Amazon</strong> is unable to connect</div>
							<div className="fs-md o-66">1h</div>
						</Link>
					</li>

					<li className="nav-item border-top border-light flex-row align-items-stretch">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover app">
							<div className="nav-item-icon fs-root flex-row gap-1 align-items-center"></div>
							<div className="fs-root my-auto lh-title"><strong>Spie Nucleaire</strong> published a new file</div>
							<div className="fs-md o-66">1h</div>
						</Link>
						<button className="btn o-33 o-100-hover border-0" aria-hidden="true" type="button" rel="tooltip" data-original-title="read">
							<div className="icns material-symbols-rounded fs-root lg-1">markunread_mailbox</div>
						</button>
					</li>

					<li className="nav-item border-top border-light flex-row align-items-stretch">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover dropbox">
							<div className="nav-item-icon fs-root flex-row gap-1 align-items-center"></div>
							<div className="fs-root my-auto lh-title"><strong>Dropbox</strong> is unable to connect</div>
							<div className="fs-md o-66">2h</div>
						</Link>
						<button className="btn o-66-hover border-0" aria-hidden="true" type="button" rel="tooltip" data-original-title="clear">
							<div className="icns material-symbols-rounded fs-root lg-1">close</div></button>
					</li>

					<li className="nav-item border-top border-light flex-row align-items-stretch">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover email">
							<div className="nav-item-icon fs-root flex-row gap-1 align-items-center"></div>
							<div className="fs-root my-auto lh-title">Please verify your Email</div>
							<div className="fs-md o-66">2h</div>
						</Link>
					</li>

					<li className="nav-item border-top border-light flex-row align-items-stretch">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover files">
							<div className="nav-item-icon fs-root flex-row gap-1 align-items-center"></div>
							<div className="fs-root my-auto lh-title">2 files added from <strong>Amazon</strong></div>
							<div className="fs-md o-66">9 Sept</div>
						</Link>
					</li>

					<li className="nav-item border-top border-light flex-row align-items-stretch">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover updated">
							<div className="nav-item-icon fs-root flex-row gap-1 align-items-center"></div>
							<div className="fs-root my-auto lh-title"><strong>Amaguiz Jennifer Willis’s account</strong> is up to date</div>
							<div className="fs-md o-66">31 Ago</div>
						</Link>
					</li>

					<li className="nav-itemmw-100  align-items-center">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover copy">
							<div className="nav-item-icon fs-root flex-row gap-1 align-items-center"></div>
							<div className="fs-root my-auto lh-title"><strong>Dossier XPTO</strong> copied to SBX Drive successfully</div>
							<div className="fs-md o-66">20 Ago</div>
						</Link>
					</li>

					<li className="nav-item border-top border-light flex-row  align-items-center">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover move">
							<div className="nav-item-icon fs-root flex-row gap-1 align-items-center"></div>
							<div className="fs-root my-auto lh-title"><strong>Dossier XPTO</strong> moved to SBX Drive successfully</div>
							<div className="fs-md o-66">15 Jul</div>
						</Link>
					</li>

					<li className="nav-item border-top border-light flex-row  align-items-center">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover files">
							<div className="nav-item-icon fs-root flex-row gap-1 align-items-center"></div>
							<div className="fs-root my-auto lh-title">6 files added from <strong>Amazon</strong></div>
							<div className="fs-md o-66">4 Jul</div>
						</Link>
					</li>

					<li className="nav-item border-top border-light flex-row  align-items-center">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover files">
							<div className="nav-item-icon fs-root flex-row gap-1 align-items-center"></div>
							<div className="fs-root my-auto lh-title">6 files added from <strong>American Express</strong></div>
							<div className="fs-md o-66">5 Jun</div>
						</Link>
					</li>

					<li className="nav-item border-top border-light flex-row  align-items-center">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover files">
							<div className="nav-item-icon fs-root flex-row gap-1 align-items-center"></div>
							<div className="fs-root my-auto lh-title">3 files added from <strong>Ebay</strong></div>
							<div className="fs-md o-66">2 Jun</div>
						</Link>
					</li>

					<li className="nav-item border-top border-light flex-row  align-items-center">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover files">
							<div className="nav-item-icon fs-root flex-row gap-1 align-items-center"></div>
							<div className="fs-root my-auto lh-title">3 files added from <strong>Mr. Good Deal</strong></div>
							<div className="fs-md o-66">25 May</div>
						</Link>
					</li>

					<li className="nav-item border-top border-light flex-row  align-items-center">
						<Link to="#" className="nav-link flex-fill align-items-center sync bg-light-hover files">
							<div className="nav-item-icon fs-root flex-row gap-1 align-items-center"></div>
							<div className="fs-root my-auto lh-title">4 files added from <strong>orange</strong></div>
							<div className="fs-md o-66">21 May</div>
						</Link>
					</li>
				</ul>
			</div>
		</div>
	)
}

