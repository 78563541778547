import React, { useState }  from 'react';
import * as R from 'ramda';

export const Redirection = () => {
	const [debut, setDebut] = useState(false);

    return (
        <React.Fragment>
            <div className="flex-column w-100 mb-4">
                <div className="container-fluid g-0 g-lg-3 container-copy container-xxl-screen">
					<section className="card card-body mb-5 mt-xxl-3 mt-hd-4" id="section_personalDetails">
						<div className="row mb-3 mb-sm-0">
							<div className="col-sm">
								<h5 className="ff-base fw-normal">Reception</h5>
								<p className="fs-aux o-44 mb-0">Réacheminer tous les factures vers l'application.</p>
							</div>
							<div className="col-sm col-xl-9">
								<label for="Reception" className="form-label smallcaps ff-aux fw-demi o-33 fs-sm">Email</label>
								<div className="row align-items-center gx-2">
									<div className="col-sm">
										<input type="email" className="form-control border-none bg-faded bg-opacity-75" readOnly id="Reception" placeholder="name@example.com" />
									</div>
									<div className="col-sm-auto">
										<btn className="btn btn-outline-info fs-md fw-demi px-4 collapsed" type="btn" aria-controls="copy">Copy</btn>
									</div>
								</div>
							</div>
						</div>
					</section>
					<form className="block" accept-charset="UTF-8" id="redirectionForm" method="post">
						<article className="block mb-lg" id="setting--facturation-main">
							<div className="row">
								<div className="col-12 col-xl-3 flex-column gap-3">
									<h3 className="fs-5 ff-base fw-normal"><i className="icns mdi-import fs-lg mr-sm"></i>Collecte</h3>
									<p className="fs-aux o-44">Vous pouvez spécifier la date à partir de laquelle les factures seront traitées.</p>
								</div>
								<div className="col-12 col-xl-9 flex-column gap-3">
									<div className="row gx-2">
										<label className="form-label smallcaps ff-aux fw-demi o-33 fs-sm my-1" for="account-Nom">Debut</label>
										<div className="flex-column gap-2">
											<div className="form-check form-switch">
												<input className="form-check-input" type="checkbox" role="switch" id="debutSwitch" checked={debut} onChange={() => { setDebut(!debut) }}/>
												<label className="form-check-label" for="debutSwitch">Activate filter</label>
											</div>
											{!R.isNil(debut) && debut ? 
												<div className="row g-1">
													<div className="col-4">
														<select className="form-control bg-faded border-none form-select text-body" id="salarie_country" name="salarie[country]">
															<option value="MM" selected="selected">MM</option>
															<option value="01">01</option>
															<option value="02">02</option>
															<option value="03">03</option>
															<option value="04">04</option>
															<option value="05">05</option>
															<option value="06">06</option>
															<option value="07">07</option>
															<option value="08">08</option>
															<option value="09">09</option>
															<option value="10">10</option>
															<option value="11">11</option>
															<option value="12">12</option>
														</select>
													</div>
													<div className="col-4">
														<select className="form-control bg-faded border-none form-select text-body" id="salarie_country" name="salarie[country]">
															<option value="YYYY" selected="selected">YYYY</option>
															<option value="2018">2018</option>
															<option value="2017">2017</option>
															<option value="2016">2016</option>
															<option value="2015">2015</option>
														</select>
													</div>
												</div>
												:
												""
											}
										</div>
									</div>
								</div>
							</div>
						</article>
						<article className="block mb-lg" id="setting--facturation-id">
							<div className="row">
								<div className="col-12 col-xl-3 my-3">
									<h3 className="fs-5 ff-base fw-normal"><i className="icns mdi-export fs-lg mr-sm"></i>Envoi</h3>
									<p className="fs-aux o-44">Vous pouvez renvoyer l’ensemble des factures traités à une adresse email de votre choix. Celle-ci pourra vous être communiqué par votre expert comptable.</p>
									<p className="fs-aux o-44"><b className="text-body">*</b> Champs obligatoires</p>
								</div>
								<div className="col-12 col-xl-9 my-3">
									<div className="row g-2 my-3">
										<label className="form-label smallcaps ff-aux fw-demi o-33 fs-sm my-1" for="account-Nom">Emails</label>
										<div className="flex-column gap-2">
											<div className="input-group">
												<input type="email" className="form-control bg-faded border-faded text-body" id="account-email-input" aria-describedby="account-email-input" value="email@securibox.eu" />
												<span className="input-group-text bg-faded border-faded" id="account-email-input" role="button">
													<i className="icns material-symbols-outlined align-middle text-danger-hover fs-inherit">close</i>
												</span>
											</div>
											<div className="input-group">
												<input type="email" className="form-control bg-faded border-faded" id="account-email-add" aria-describedby="account-email-add" placeholder="Type here..." />
												<span className="input-group-text bg-faded border-faded" id="account-email-add" role="button">
													<i className="icns material-symbols-outlined align-middle text-body-hover fs-inherit">question_mark</i>
												</span>
											</div>
											<button type="button" className="btn p-0 flex-row gap-2 border-none align-items-center o-66 o-100-hover" id="address_add">
												<i className="icns material-symbols-outlined align-middle lh-1 text-success avatar-2 bg-body rounded-inherit">add</i><span className="fs-aux fw-semi mx-2">Ajouter un champ</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</article>
						<div className="flex-column align-items-end">
							<button type="submit" form='redirectionForm' className="btn text-info text-white-hover bg-info bg-opacity-10 bg-opacity-100-hover fs-md fw-demi px-4">Enregistrer</button>
							{/* <p className="fs-aux"><b className="text-danger">*</b><span className="o-66 mx-1">Champs obligatoires</span></p> */}
						</div>
					</form>
                </div>
            </div>
        </React.Fragment>
    );
};