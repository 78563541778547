import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import * as Loadings from '../../constants/loadings/company';
import { Spinner } from '../../_common/spinner';
import { INITAL_COMPANY_DATA } from '../../constants/utils/global';
import { CreateCompany, UpdateCompany, GetCompany } from '../../actions/company-api';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { toastConf } from '../../constants/utils/toastConfig';


export const ModalAccount = ({ companyState }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formRef = useRef();
    const closeRef = useRef();
    const [fileKey, setFileKey] = useState(Date.now());
    const [picture, setPicture] = useState(undefined);
    const [company, setCompany] = useState({ ...INITAL_COMPANY_DATA });


    useEffect(() => {
        if ((R.isNil(companyState.company) || R.isNil(companyState.company.id)) || companyState.isFormEmpty) {
            setCompany({ ...INITAL_COMPANY_DATA });
        } else {
            setCompany({
                id: companyState.company.id,
                name: R.isNil(companyState.company.name) ? '' : companyState.company.name,
                vatNumber: R.isNil(companyState.company.vatNumber) ? '' : companyState.company.vatNumber,
                addressCountry: R.isNil(companyState.company.addressCountry) ? '' : companyState.company.addressCountry,
                addressLine1: R.isNil(companyState.company.addressLine1) ? '' : companyState.company.addressLine1,
                addressLine2: R.isNil(companyState.company.addressLine2) ? '' : companyState.company.addressLine2,
                addressLine3: R.isNil(companyState.company.addressLine3) ? '' : companyState.company.addressLine3,
                addressPostalCode: R.isNil(companyState.company.addressPostalCode) ? '' : companyState.company.addressPostalCode,
                addressCity: R.isNil(companyState.company.addressCity) ? '' : companyState.company.addressCity,
            });
        }
    }, [companyState.company, companyState.isFormEmpty]);


    const submitForm = async (e) => {
        e.preventDefault();
        formRef.current.classList.add('was-validated');
        if (!formRef.current.checkValidity()) return;
        try {
            if (R.isNil(company.id)) {
                const { id, ...rest } = company;
                const response = await dispatch(CreateCompany(rest));
                navigate(`/c/${response.id}/inbox`);
            } else {
                await dispatch(UpdateCompany(company));
            }
        } catch (error) {
            toast.error("Tentative échouée, réessayez !", toastConf);
        } finally {
            closeRef.current.click();
        }
    };



    return (
        <div className="modal fade" id="accountSociete" tabIndex={-1} aria-labelledby="accountSocieteLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-dialog modal-dialog-scrollable-centered">
                <div className="modal-content rounded-2 p-4 text-body">
                    <div className="modal-header pb-0">
                        <h1 className="modal-title fs-4 text-info fw-demi ls-n1 lh-title" id="modalMemberLabel">Configurer société</h1>
                        {/* {R.isNil(company.id) || R.isEmpty(company.id) ?
                            "" : <button type="button" className="btn-close p-1" data-bs-dismiss="modal" aria-label="Close" ref={closeRef}></button>
                        } */}
                        <button type="button" className="btn-close p-1" data-bs-dismiss="modal" aria-label="Close" ref={closeRef}></button>
                    </div>
                    <div className="modal-body">
                        <p className="o-77 mb-3 mt-n3">Veuillez remplir le formulaire ci-dessous:</p>
                        <form onSubmit={submitForm} noValidate id="companyForm" ref={formRef}>
                            <div className="form-group" id="basic">
                                <label className="smallcaps ff-aux fw-demi o-33 fs-sm mb-2" htmlFor="basic">Societe</label>
                                <div className="form-group mb-2">
                                    <input required type="text" value={company.name} onChange={(e) => { setCompany(company => ({ ...company, name: e.target.value })); }} className={"form-control bg-body-tertiary border-body-tertiary ff-base fw-normal" + (R.isNil(company.name) && R.isEmpty(company.name) ? "" : " text-body")} placeholder="Raison Sociale" />
                                </div>
                                <div className="form-group mb-2">
                                    <input type="text" pattern='(FR)?[0-9A-Z]{2}[0-9]{9}' required value={company.vatNumber} onChange={(e) => { setCompany(company => ({ ...company, vatNumber: e.target.value })); }} className={"form-control bg-body-tertiary border-body-tertiary ff-base fw-normal" + (R.isNil(company.vatNumber) && R.isEmpty(company.vatNumber) ? " no-event" : " text-body")} placeholder="Numéro de TVA" />
                                </div>
                                <div className="form-group mb-2">
                                    <input type="email" pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$" required value={company.email} onChange={(e) => { setCompany(company => ({ ...company, email: e.target.value })); }} className={"form-control bg-body-tertiary border-body-tertiary ff-base fw-normal" + (R.isNil(company.email) && R.isEmpty(company.email) ? " no-event" : " text-body")} placeholder="Email" />
                                </div>
                            </div>
                            <hr className="border-body-tertiary o-100" />
                            <div className="form-group" id="advanced">
                                <label className="smallcaps ff-aux fw-demi o-33 fs-sm mb-2" htmlFor="advanced">Address</label>
                                <div className="form-group mb-2">
                                    <input type="text" required value={company.addressLine1} onChange={(e) => { setCompany(company => ({ ...company, addressLine1: e.target.value })); }} className={"form-control bg-body-tertiary border-body-tertiary ff-base fw-normal" + (!R.isNil(company.addressLine1) && !R.isEmpty(company.addressLine1) ? " text-body" : "")} placeholder="Addresse ligne 1" />
                                </div>
                                <div className="form-group mb-2">
                                    <input type="text" value={company.addressLine2} onChange={(e) => { setCompany(company => ({ ...company, addressLine2: e.target.value })); }} className={"form-control bg-body-tertiary border-body-tertiary ff-base fw-normal" + (!R.isNil(company.addressLine2) && !R.isEmpty(company.addressLine2) ? " text-body" : "")} placeholder="Addresse ligne 2" />
                                </div>
                                <div className="form-group mb-2">
                                    <input type="text" value={company.addressLine3} onChange={(e) => { setCompany(company => ({ ...company, addressLine3: e.target.value })); }} className={"form-control bg-body-tertiary border-body-tertiary ff-base fw-normal" + (!R.isNil(company.addressLine3) && !R.isEmpty(company.addressLine3) ? " text-body" : "")} placeholder="Addresse ligne 3" />
                                </div>
                                <div className="flex-md-row gap-2 justify-content-between">
                                    <div className="flex-fill mb-2">
                                        <input type="text" pattern='(?:0[1-9]|[13-8][0-9]|2[ab1-9]|9[0-5])(?:[0-9]{3})?|9[78][1-9](?:[0-9]{2})' required value={company.addressPostalCode} onChange={(e) => { setCompany(company => ({ ...company, addressPostalCode: e.target.value })); }} className={"form-control bg-body-tertiary border-body-tertiary ff-base fw-normal" + (R.isNil(company.addressPostalCode) && R.isEmpty(company.addressPostalCode) ? "" : " text-body")} placeholder="Code Postal" />
                                    </div>
                                    <div className="flex-fill mb-2">
                                        <input type="text" required value={company.addressCity} onChange={(e) => { setCompany(company => ({ ...company, addressCity: e.target.value })); }} className={"form-control bg-body-tertiary border-body-tertiary ff-base fw-normal" + (R.isNil(company.addressCity) && R.isEmpty(company.addressCity) ? "" : " text-body")} placeholder="Ville" />
                                    </div>
                                </div>
                                <div className="form-group mb-2">
                                    <input type="text" required defaultValue={company.addressCountry} readOnly className={"form-control bg-body-tertiary border-body-tertiary ff-base fw-normal" + (R.isNil(company.addressCountry) && R.isEmpty(company.addressCountry) ? "" : " text-body")} placeholder="Pays" />
                                </div>
                            </div>
                            <hr className="border-body-tertiary o-100" />
                            <div className="form-group col-12">
                                <label className="smallcaps ff-aux fw-demi o-33 fs-sm mb-2">Avatar</label>
                                <div className="block text-center bg-body-tertiary rounded-2 p-4">
                                    <div className="avatar avatar-4 bg-body mb-2 mx-auto rounded-inherit">
                                        {R.isNil(picture) ?
                                            <i className="icns text-muted material-symbols-outlined align-middle icns-fw m-2 fs-1">photo_camera</i>
                                            :
                                            <div className="avatar-inherit img-fluid mx-auto rounded-inherit" style={{ backgroundSize: "cover", backgroundImage: "url(" + URL.createObjectURL(picture) + ")" }}></div>
                                        }
                                    </div>
                                    <input id="upload-photo" className="d-none" type="file" accept="image/x-png,image/gif,image/jpeg" key={fileKey} onInput={(event) => {
                                        const files = [...event.target.files];
                                        const file = files[0];
                                        setPicture(new File([file], file.name));
                                    }} />
                                    <p className="small text-muted mb-0">Veuillez charger un fichier jpg, gif ou png.<br />Taille maximale: 4Mo</p>
                                    {(picture !== null && picture !== undefined) || (picture === undefined && !R.isNil(companyState.user) && !R.isNil(companyState.user.pictureUrl) && companyState.user.pictureUrl !== null && companyState.user.pictureUrl !== '') ?
                                        <button className="btn bg-white  bg-opacity-50-hover fs-md fw-demi mt-3 lh-1" type="button" onClick={(e) => {
                                            setPicture(null);
                                            setFileKey(Date.now());
                                        }}><i className="icns material-symbols-outlined align-middle fs-inherit me-2 text-danger">close</i>Supprimer</button>
                                        :
                                        <label className="d-block stretched-link" htmlFor="upload-photo" role="button"></label>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        {R.isNil(company.id) || R.isEmpty(company.id) ?
                            <button type="button" className="btn link-body o-66-hover fs-md fw-demi ms-auto" data-bs-target="#sbxAuth" data-bs-toggle="modal">Annuler</button>
                            :
                            <button type="button" className="btn text-muted o-66-hover fs-md fw-demi" data-bs-dismiss="modal">Fermer</button>
                        }
                        <button form="companyForm" disabled={companyState.isLoading.includes(Loadings.CREATING_COMPANY) || companyState.isLoading.includes(Loadings.UPDATING_COMPANY)} type="submit" className="btn text-info text-white-hover bg-info bg-opacity-10 bg-opacity-100-hover fs-md fw-demi px-4">
                            <span>  Enregistrer </span>
                            {(companyState.isLoading.includes(Loadings.CREATING_COMPANY) || companyState.isLoading.includes(Loadings.UPDATING_COMPANY)) && <Spinner />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

