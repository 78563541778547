import React from 'react';
import * as R from 'ramda';
import { FilterMember, MemberFilter } from './filter';
import { ItemMember, MemberItem } from './item';

export const TableMember = ({ memberState, membersToDisplay, setSelectedMember }) => {

    return (
        <div className="row g-3 h-inherit">
            <FilterMember />
            <div className="col-lg h-inherit flex-column" id="section_membersUsers">
                <nav className="block mb-1 py-border border-bottom border-body-tertiary">
                    <div className="row align-items-center small text-muted o-88">
                        <div className="col-6">
                            <span className="form-label smallcaps ff-aux fw-demi o-33 fs-sm">Info</span>
                        </div>
                        <div className="col-auto text-truncate">
                            <span className="form-label smallcaps ff-aux fw-demi o-33 fs-sm">Role</span>
                        </div>
                    </div>
                </nav>
                <ul className="list-group list-group-flush flex-fill">
                    {R.isEmpty(membersToDisplay) ?
                        <h3>Aucun membre trouvé</h3> :
                        membersToDisplay.map((member, index) => {
                            return (
                                <ItemMember key={member.id} memberState={memberState} member={member} setSelectedMember={setSelectedMember} />
                            );
                        })}
                </ul>
            </div>
        </div>
    );
};
