// import * as R from 'ramda';
import { EndPoints } from '../api-config';
export const SERVER_ADDR_V1 = EndPoints.backendHost;
export const SERVER_ADDR_V2 = 'http://localhost:7189';


// export const getCompaniesUrl = () => `${SERVER_ADDR_V2}/api/v1`;
// export const getCompanyByIdUrl = (companyId) => `${SERVER_ADDR_V2}/api/v1/${companyId}`;
// export const getCompanyBySirenUrl = (siren) => `${SERVER_ADDR_V2}/api/v1/${siren}/siren`;

export const getCompaniesUrl = () => `${SERVER_ADDR_V1}/api/company`;
export const getCompanyByIdUrl = (companyId) => `${SERVER_ADDR_V1}/api/company/${companyId}`;
export const getCompanyBySirenUrl = (siren) => `${SERVER_ADDR_V1}/api/company/${siren}/siren`;
