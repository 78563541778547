import React from 'react';
import { Empty } from '../../empty';

export const EmptyMember = ({ companyId }) => {

    return (
        <Empty companyId={companyId} custom=" container-base m-auto" >
            <div className="lead fw-normal mb-2">
                Aucun membre trouvé correspondant à vos critères. Creér ou sélectionner un membre pour commencer.
            </div>
            <p className="o-88">
                <span className="me-2 align-middle my-2">Pour afficher les détails d'un membre et afin de réduire la consommation de données, assurez-vous d'en sélectionner un dans la liste.</span>
            </p>
            <button type="button" className="btn p-0 border-0 link-warning" data-bs-toggle="modal" data-bs-target="#modalMember"><span className="text-body align-middle">Commencer</span><i className="icns material-symbols-rounded ms-3 align-middle">arrow_forward</i></button>
        </Empty>
    );
};
