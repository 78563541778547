import { useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export const useModalToggle = (queryValue) => {
    const [searchParams] = useSearchParams();
    const openRef = useRef(null);
    const { pathname, search } = useLocation();
    const query = new URLSearchParams(search).get('q');

    useEffect(() => {
        if (query === queryValue && openRef.current) {
            openRef.current.click();
        }
        // eslint-disable-next-line 
    }, [query, search, pathname, searchParams]);

    return (
        {
            openRef
        }
    );
};
