import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { URLParams } from '../../../../constants/utils/urlParams';
import { debounce } from '../../../../constants/utils/debounce';

export const ToolbarSearch = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const QUERIES = URLParams.queries();
    const DEBOUNCE_TIME = 1000; //milliseconds
    const setSearchText = debounce(searchParams, setSearchParams, DEBOUNCE_TIME);


    return (
        <section className="flex-column my-auto flex-grow-1 align-items-stretch col-12 col-sm order-last order-sm-first">
            <h4 className="sr-only visually-hidden">Search</h4>
            <div className="container-fluid">
                <form action="#" onSubmit={(e) => { e.preventDefault(); }} className="form gap-1">
                    <div className="input-group gap-1">
                        <div className="input-group-text bg-body border-body p-0">
                            <span className="icns material-symbols-rounded text-body align-middle lh-1">search</span>
                        </div>
                        <input type="text" defaultValue={QUERIES.searchText} onChange={(e) => { setSearchText(["searchText", e.target.value]); }} className="form-control border-body" placeholder="Rechercher..." aria-label="Rechercher..." />
                        <div className="input-group-text bg-body border-body p-0">
                            <span className="icns material-symbols-rounded align-middle lh-1">expand_more</span>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
};