import React from 'react';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import { URLParams } from '../../../constants/utils/urlParams';
import { Today } from '../../../constants/utils/date';

export const Menu = ({ invoiceState }) => {
    const dataInvoices = !R.isNil(invoiceState.invoices) && !R.isEmpty(invoiceState.invoices) ? invoiceState.invoices.map((item) => item.source) : null;
    const QUERIES = URLParams.queries();

    return (
        <nav className="navbar flex-shrink-0 col-12 col-sm-auto col-lg-3 col-xl-2 p-0 d-lg-block h-lg-inherit scroll-lg-auto pe-2 mb-3 mb-lg-0 order-sm-last">
            <h4 className="sr-only visually-hidden">inbox: menu</h4>
            <div className="flex-row flex-lg-column flex-fit w-100 sticky-top z-2 gap-2 mb-2">
                <Link to="" className="btn btn-secondary me-auto">
                    <span className="icns material-symbols-rounded align-middle">
                        add
                    </span>
                    <span className="align-middle mx-2 fs-aux fw-medium">Ajouter facture</span>
                </Link>
                <button className="btn btn-secondary ms-auto d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter">
                    <span className="icns material-symbols-rounded align-middle">
                        discover_tune
                    </span>
                    <span className="align-middle mx-2 lh-lg">Filtre</span>
                </button>
            </div>
            <ul className="navbar-nav filter-nav flex-sm-column mb-auto w-100 mt-4 collapse show-lg" id="collapseFilter" style={{ "--sbx-filter-link-color": "var(--sbx-secondary)" }}>
                <li className="nav-item mb-2">
                    <button className="btn border-0 flex-row align-items-center w-100 nav-link lh-1 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#Source" aria-expanded="false" aria-controls="Source">
                        <span className="align-middle small fw-bold">Source</span>
                        <span className="icns material-symbols-rounded align-middle ms-auto icn-expand">
                            expand_less
                        </span>
                    </button>
                    <ul className="navbar-nav flex-sm-column mb-auto w-100 mb-4 collapse multi-collapse mt-2 show" id="Source">
                        {/* <hr /> */}
                        <li className="nav-item">
                            <Link to={URLParams.delete(["source"])} className={"nav-link" + (R.isNil(QUERIES.source) ? ' active' : '')}>
                                <span className={"icns fw-semibold avatar-2 d-inline-block text-center p-0 align-middle m-auto text-truncate" + (!dataInvoices || dataInvoices?.length === 0 ? " o-33" : "")}>
                                    {!R.isEmpty(invoiceState.invoices) ? (dataInvoices?.length || 0) : '--'}
                                </span>
                                <span className="align-middle mx-2">PPF</span>
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to={URLParams.delete(["source"])} className={"nav-link" + (R.isNil(QUERIES.source) ? ' active' : '')}>
                                <span className={"icns fw-semibold avatar-2 d-inline-block text-center p-0 align-middle m-auto text-truncate" + (!dataInvoices || dataInvoices?.length === 0 ? " o-33" : "")}>
                                    {!R.isEmpty(invoiceState.invoices) ? (dataInvoices?.length || 0) : '--'}
                                </span>
                                <span className="align-middle mx-2">Tout</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link end={URLParams.set({ source: "ppf" })} to={URLParams.set({ source: "ppf" })} className={"nav-link" + (QUERIES.source === 'ppf' ? ' active' : '')}>
                                <span className={"icns fw-semibold avatar-2 d-inline-block text-center p-0 align-middle m-auto text-truncate"  + (!dataInvoices || dataInvoices?.filter(word => word === "PPF").length === 0 ? " o-33" : "")}>
                                    {!R.isEmpty(invoiceState.invoices) ? dataInvoices?.filter(word => word === "PPF").length || 0 : '--'}
                                </span>
                                <span className="align-middle mx-2">PPF</span>
                            </Link>
                        </li> */}
                        <li className="nav-item">
                            <Link end={URLParams.set({ source: "pdp" })} to={URLParams.set({ source: "pdp" })} className={"nav-link" + (QUERIES.source === 'pdp' ? ' active' : '')}>
                                {/* <span className="icns material-symbols-outlined align-middle">
                                    sync
                                </span> */}
                                <span className={"icns fw-semibold avatar-2 d-inline-block text-center p-0 align-middle m-auto text-truncate" + (!dataInvoices || dataInvoices?.filter(word => word === "PDP").length === 0 ? " o-33" : "")}>
                                    {!R.isEmpty(invoiceState.invoices) ? dataInvoices?.filter(word => word === "PDP").length || 0 : '--'}
                                </span>
                                <span className="align-middle mx-2">PDP</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link end={URLParams.set({ source: "ppf" })} to={URLParams.set({ source: "manual" })} className={"nav-link" + (QUERIES.source === 'manual' ? ' active' : '')}>
                                {/* <span className="icns material-symbols-rounded align-middle">
                                    touch_app
                                </span> */}
                                <span className={"icns fw-semibold avatar-2 d-inline-block text-center p-0 align-middle m-auto text-truncate" + (!dataInvoices || dataInvoices?.filter(word => word === "Manual").length === 0 ? " o-33" : "")}>
                                    {!R.isEmpty(invoiceState.invoices) ? dataInvoices?.filter(word => word === "Manual").length || 0 : '--'}
                                </span>
                                <span className="align-middle mx-2">Manual</span>
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link end={URLParams.set({ source: "ppf" })} to={URLParams.set({ source: "collecte" })} className={"nav-link" + (QUERIES.source === 'collecte' ? ' active' : '')}>
                                <span className="icns material-symbols-outlined align-middle">
                                    link
                                </span>
                                <span className="align-middle mx-2">Collecte</span>
                            </Link>
                        </li> */}
                        <li className="nav-item">
                            <Link end={URLParams.set({ source: "ppf" })} to={URLParams.set({ source: "email" })} className={"nav-link" + (QUERIES.source === 'email' ? ' active' : '')}>
                                {/* <span className="icns material-symbols-outlined align-middle">
                                    attachment
                                </span> */}
                                <span className={"icns fw-semibold avatar-2 d-inline-block text-center p-0 align-middle m-auto text-truncate" + (!dataInvoices || dataInvoices?.filter(word => word === "Email").length === 0 ? " o-33" : "")}>
                                    {!R.isEmpty(invoiceState.invoices) ? dataInvoices?.filter(word => word === "Email").length || 0 : '--'}
                                </span>
                                <span className="align-middle mx-2">Email</span>
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to={URLParams.set({ source: "null" })} className={"nav-link" + (QUERIES.source === 'null' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">question_mark</span>
                                <span className="align-middle mx-2">Undefined</span>
                            </Link>
                        </li> */}
                    </ul>
                </li>
                {/* <hr className="my-4 col-4 mx-auto"/> */}
                <li className="nav-item mb-2">
                    <button className="btn border-0 flex-row align-items-center w-100 nav-link lh-1 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#Etat" aria-expanded="false" aria-controls="Etat">
                        <span className="align-middle small fw-bold">Statut de la facture</span>
                        <span className="icns material-symbols-rounded align-middle ms-auto icn-expand">
                            expand_less
                        </span>
                    </button>
                    <ul className="navbar-nav flex-sm-column mb-auto w-100 mb-4 collapse multi-collapse mt-2 show" id="Etat">
                        <li className="nav-item">
                            <Link to={URLParams.delete(["status"])} className={"nav-link" + (!QUERIES.status ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">
                                    done_all
                                </span>
                                <span className="align-middle mx-2">Tout</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.set({ status: "200" })} className={"nav-link" + (QUERIES.status === 'deposee' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">pending_actions</span>
                                <span className="align-middle mx-2">Deposée</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.set({ status: "208" })} className={"nav-link" + (QUERIES.status === 'suspendue' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">autopause</span>
                                <span className="align-middle mx-2">Suspendue</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.set({ status: "204" })} className={"nav-link" + (QUERIES.status === 'communication' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">done_all</span>
                                <span className="align-middle mx-2">Prise en charge</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.set({ status: "206" })} className={"nav-link" + (QUERIES.status === 'approuveePartiel' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">bolt</span>
                                <span className="align-middle mx-2">Approuvée partiellement</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.set({ status: "205" })} className={"nav-link" + (QUERIES.status === 'approuvee' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle fs-lg ms-1">thumb_up</span>
                                <span className="align-middle mx-2">Approuvée</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.set({ status: "207" })} className={"nav-link" + (QUERIES.status === 'litige' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">approval_delegation</span>
                                <span className="align-middle mx-2">En Litige</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.set({ status: "210" })} className={"nav-link" + (QUERIES.status === 'refusee' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">close</span>
                                <span className="align-middle mx-2">Refusée</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.set({ status: "211" })} className={"nav-link" + (QUERIES.status === 'transmis' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">send</span>
                                <span className="align-middle mx-2">Paiement transmis</span>
                            </Link>
                        </li>
                    </ul>
                </li>
                {/* <hr className="my-4 col-4 mx-auto"/> */}
                <li className="nav-item mb-2">
                    <button className="btn border-0 flex-row align-items-center w-100 nav-link lh-1 p-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Trier" aria-expanded="false" aria-controls="Trier">
                        <span className="align-middle small fw-bold">Trier</span>
                        <span className="icns material-symbols-rounded align-middle ms-auto icn-expand">
                            expand_less
                        </span>
                    </button>
                    <ul className="navbar-nav flex-sm-column mb-auto w-100 mb-4 collapse mt-2" id="Trier">
                        {/* <hr /> */}
                        <li className="nav-item">
                            <Link to={URLParams.reset({ remove: ["date"], inital: { sortBy: "name", sortDesc: "false" } })} className={"nav-link" + (QUERIES.sortBy === 'name' ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">
                                    format_size
                                </span>
                                <span className="align-middle mx-2">Ordre alphabetical</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.reset({ remove: ["sortBy", "sortDesc"], inital: { date: Today } })} className={"nav-link" + (!R.isNil(QUERIES.date) ? ' active' : '')}>
                                <span className="icns material-symbols-rounded align-middle">
                                    today
                                </span>
                                <span className="align-middle mx-2">Date d'ajout</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={URLParams.reset({ remove: ["date"], inital: { sortBy: "lastSync" } })} className={"nav-link" + (QUERIES.sortBy === 'lastSync' ? ' active' : '')}>
                                <span className="icns material-symbols-outlined align-middle">
                                    refresh
                                </span>
                                <span className="align-middle mx-2">Dernière synchro</span>
                            </Link>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
}

