import { createSlice } from '@reduxjs/toolkit';
import * as R from 'ramda';
import * as Loadings from '../constants/loadings/member';

const initState = {
    isLoading: [Loadings.FETCHING_MEMBERS],
    member: undefined,
    members: undefined,
};

const MemberReducer = createSlice({
    name: 'member',
    initialState: { ...initState },
    reducers: {
        //#region GET MEMBERS
        getMembersRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_MEMBERS)) {
                state.isLoading.push(Loadings.FETCHING_MEMBERS);
            }
        },
        getMembersSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_MEMBERS);
            state.members = payload;
        },
        getMembersFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_MEMBERS);
        },
        //#endregion

        //#region GET MEMBER
        getMemberRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.FETCHING_MEMBER)) {
                state.isLoading.push(Loadings.FETCHING_MEMBER);
            }
        },
        getMemberSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_MEMBER);
            state.member = payload;
        },
        getMemberFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.FETCHING_MEMBER);
        },
        //#endregion

        //#region CREATE MEMBER
        createMemberRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.CREATING_MEMBER)) {
                state.isLoading.push(Loadings.CREATING_MEMBER);
            }
        },
        createMemberSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_MEMBER);
            if (R.isNil(state.members)) {
                state.members = [];
            }
            state.members.push(payload);
        },
        createMemberFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.CREATING_MEMBER);
        },
        //#endregion

        //#region CREATE MEMBER
        updateMemberRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.UPDATING_MEMBER)) {
                state.isLoading.push(Loadings.UPDATING_MEMBER);
            }
        },
        updateMemberSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_MEMBER);
            state.members = state.members.map(member => {
                if (member.id === payload.id) return payload;
                return member;
            });
        },
        updateMemberFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.UPDATING_MEMBER);
        },
        //#endregion

        //#region CREATE MEMBER
        deleteMemberRequest(state, { payload }) {
            if (!state.isLoading.includes(Loadings.DELETING_MEMBER)) {
                state.isLoading.push(Loadings.DELETING_MEMBER);
            }
        },
        deleteMemberSuccess(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_MEMBER);
            state.members = state.members.filter(member => member.id !== payload.id);
        },
        deleteMemberFailure(state, { payload }) {
            state.isLoading = state.isLoading.filter(loading => loading !== Loadings.DELETING_MEMBER);
        },
        //#endregion
        resetMember(state, { payload }) {
            state.member = undefined;
            state.members = undefined;
            state.isLoading = [Loadings.FETCHING_MEMBERS];
        },
    }
});


export const memberActions = MemberReducer.actions;
export default MemberReducer.reducer;