import { configureStore } from '@reduxjs/toolkit';
import authentication from './authentication';
import connection from './connection';
import invoice from './invoice';
import company from './company';
import member from './member';
import example from './example';
import routingrules from './routingRules';
import logger from 'redux-logger';

export const store = configureStore({
    reducer: {
        authentication,
        connection,
        invoice,
        company,
        member,
        example,
        routingrules
    },
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) => {
        let options = { serializableCheck: false };
        if (process.env.NODE_ENV !== 'production')
            return getDefaultMiddleware(options).concat(logger);

        return getDefaultMiddleware(options);
    }
});