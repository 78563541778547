import * as R from 'ramda';
import * as qs from 'query-string';
const ACCESS_TOKEN = 'access_token';
const ID_TOKEN = 'id_token';

export const storageService = () => {
    let query = qs.parse(window.location.hash);

    if (!R.isNil(query.access_token)) {
        sessionStorage.setItem(ACCESS_TOKEN, query.access_token);
    }
    if (!R.isNil(query.id_token)) {
        sessionStorage.setItem(ID_TOKEN, query.id_token);
    }
    let accessToken = sessionStorage.getItem(ACCESS_TOKEN);
    let idToken = sessionStorage.getItem(ID_TOKEN);

    return {
        accessToken,
        idToken
    };
};

