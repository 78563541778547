import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import * as R from 'ramda';
import { SOURCE_COLOR, STATUS_MAP } from "../../../../../constants/utils/global";
import { URLParams } from '../../../../../constants/utils/urlParams';

export const Header = ({ invoiceState, item, setItem }) => {
    const { companyId } = useParams();
    const navigate = useNavigate();

    return (
        <div className="offcanvas-header card rounded-2 p-1">
            <div className="container-fluid g-1">
                <div className="row align-items-center g-2">
                    <div className="col-auto">
                        <button type="button" className="btn border-none bg-body-secondary bg-opacity-10 bg-body-tertiary-hover bg-opacity-10-hover link-gray fw-bold text-uppercase p-2" onClick={(e) => {
                            navigate(`/c/${companyId}/accountReceivables` + URLParams.querySearchParams(), "offcanvas");
                        }}>
                            {/* <span className="d-none d-md-inline-block align-middle ms-2">Voir</span> */}
                            <i className="icns material-symbols-rounded align-middle">chevron_left</i>
                            <span className="d-none mx-2 align-middle">Go back</span>
                        </button>
                    </div>
                    <div className="col-8 flex-row gap-3 align-items-center">
                        <div className="avatar mb-0 flex-shrink-0 bg-body-tertiary rounded-2" style={{ backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundImage: "Url('https://az859819.vo.msecnd.net/agents/logos/medium/" + (item.supplier?.logo || "placeholder") + ".png')" }}></div>
                        <div className="flex-column text-truncate">
                            <h6 className="ff-aux text-truncate mt-2 mb-0 mt-md-1">
                                <span className="container-wide text-truncate">{item.buyer?.legalName || "Inconnu"}</span>
                                {/* <span className="badge mx-1 rounded-2 bg-opacity-10 fs-xs text-bg-source" style={{ "--sbx-source": (R.isNil(item.source) || R.isEmpty(item.source) ? "var(--sbx-dark-rgb)" : SOURCE_COLOR[item.source]) }}>{item.source}</span> */}
                            </h6>
                            <small className="text-muted text-truncate">{!R.isNil(invoiceState.history) && !R.isEmpty(invoiceState.history) && STATUS_MAP[invoiceState.history[0]?.statusCode]}</small>
                        </div>
                    </div>
                    {!R.isNil(item.id) ?
                        <div className="col-auto ms-auto">
                            <button type="button" className="btn border-none bg-info bg-opacity-10 bg-opacity-100-hover text-info text-white-hover fw-bold p-1 flex-row align-items-center" data-bs-toggle="modal" data-bs-target="#updateInvoiceX" aria-controls="updateInvoiceX">
                                <span className="d-none d-md-inline-block align-middle mx-3 fw-medium">Modifier le statut</span>
                                <i className="icns material-symbols-outlined align-middle bg-body text-info p-1 rounded-1">sync_alt</i>
                            </button>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    );
}

